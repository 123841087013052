/*eslint-disable*/
//renderer.js

import {
    Attributes,
    Note,
    Harmony,
    Backup,
    Forward,
    Direction,
    Font,
} from "./class.js";

export class Renderer {
    constructor(scorePartwise) {
        this.scorePartwise = scorePartwise;
        this.currentKeySignature = null;
        this.currentTimeSignature = null;
    }

    // MusicXML의 type을 VexFlow의 duration으로 변환하는 헬퍼 함수
    mapDuration(type) {
        const durationMap = {
            maxima: "w",
            long: "w",
            breve: "w",
            whole: "w",
            half: "h",
            quarter: "q",
            eighth: "8",
            "16th": "16",
            "32nd": "32",
            "64th": "64",
            "128th": "128",
            "256th": "256",
        };
        return durationMap[type] || "q"; // 기본값은 'quarter'로 설정
    }

    // print-object 속성을 확인하는 헬퍼 함수
    shouldPrint(element) {
        if (!element) return true;
        return element.printObject !== false;
    }

    // print-spacing 속성을 확인하는 헬퍼 함수
    shouldPrintSpacing(element) {
        if (!element) return true;
        return element.printSpacing !== false;
    }

    // 속성을 병합하는 함수
    mergeAttributes(prevAttr, currAttr) {
        if (!prevAttr) return currAttr || {};
        if (!currAttr) return prevAttr;

        const mergedAttr = { ...prevAttr };

        // 현재 마디에 새로운 속성이 있으면 업데이트
        if (currAttr.divisions) mergedAttr.divisions = currAttr.divisions;
        if (currAttr.key) mergedAttr.key = currAttr.key;
        if (currAttr.time) mergedAttr.time = currAttr.time;
        if (currAttr.clef) mergedAttr.clef = currAttr.clef;
        if (currAttr.transpose) mergedAttr.transpose = currAttr.transpose;

        // 추가로 필요한 속성이 있다면 여기에 추가

        return mergedAttr;
    }

    // 속성 변경 여부를 확인하는 함수
    compareAttributes(prevAttr, currAttr) {
        const changes = {
            clefChanged: false,
            keyChanged: false,
            timeChanged: false,
        };

        if (!prevAttr && currAttr) {
            // 모든 속성이 새로 추가됨
            changes.clefChanged = !!currAttr.clef;
            changes.keyChanged = !!currAttr.key;
            changes.timeChanged = !!currAttr.time;
            return changes;
        }
        if (!currAttr && prevAttr) {
            // 모든 속성이 제거됨
            changes.clefChanged = !!prevAttr.clef;
            changes.keyChanged = !!prevAttr.key;
            changes.timeChanged = !!prevAttr.time;
            return changes;
        }
        if (!prevAttr && !currAttr) {
            return changes;
        }

        // 클레프 변경 여부
        if (currAttr.clef && prevAttr.clef) {
            changes.clefChanged =
                JSON.stringify(prevAttr.clef) !== JSON.stringify(currAttr.clef);
        } else if (currAttr.clef || prevAttr.clef) {
            changes.clefChanged = true;
        }

        // 조표 변경 여부
        if (currAttr.key) {
            if (!prevAttr.key) {
                changes.keyChanged = true;
            } else {
                const prevKey = prevAttr.key.fifths + prevAttr.key.mode;
                const currKey = currAttr.key.fifths + currAttr.key.mode;
                changes.keyChanged = prevKey !== currKey;
            }
        }

        // 박자표 변경 여부
        if (currAttr.time) {
            if (!prevAttr.time) {
                changes.timeChanged = true;
            } else {
                changes.timeChanged =
                    JSON.stringify(prevAttr.time) !== JSON.stringify(currAttr.time);
            }
        }

        return changes;
    }

    renderSelectedParts(
        scoreInfo,
        selectedParts,
        measuresToDraw,
        elementId,
        Vex,
        containerWidth,
        containerHeight,
        staveHeight,
        staveColor,
        customYOffsetMap = {},
        attributeWidth
    ) {
        const container = document.getElementById(elementId);
        if (!container) {
            console.error(`Element with id ${elementId} not found`);
            return;
        }

        container.innerHTML = "";

        const measuresPerLine = 4;
        const lines = Math.ceil(measuresToDraw.length / measuresPerLine);

        let totalHeight = 0;
        const spacingBetweenLinesPx = 10;

        selectedParts.forEach((partIndex) => {
            totalHeight +=
                this.calculatePartHeight(
                    this.scorePartwise.parts[partIndex],
                    spacingBetweenLinesPx
                ) * lines;
        });

        totalHeight += selectedParts.length * 50;

        const scaleFactor = Math.min(
            containerWidth / 1600,
            (containerHeight * 0.4) / totalHeight
        );

        const scaledWidth = containerWidth / scaleFactor;
        const scaledHeight = totalHeight;

        const factory = new Vex.Flow.Factory({
            renderer: {
                elementId: elementId,
                width: containerWidth,
                height: containerHeight,
            },
        });

        const context = factory.getContext();
        context.scale(scaleFactor, scaleFactor);

        // 수직 중앙 정렬 오프셋 계산
        const yOffset = Math.max(0, (containerHeight / scaleFactor - scaledHeight) / 2);

        let currentYOffset = yOffset;

        selectedParts.forEach((partIndex, index) => {
            const part = this.scorePartwise.parts[partIndex];

            // 이전 페이지의 조옮김 검사
            this.updateCurrentKeySignature(part, measuresToDraw[0]);

            // 초기 속성 추출
            const initialAttributes = part.measures[0].elements.find(
                (element) => element instanceof Attributes
            );
            const { isDoubleStave, isTabStave, staveLines } =
                this.extractInitialAttributes(initialAttributes);

            let partYOffset = currentYOffset;

            if (customYOffsetMap.hasOwnProperty(partIndex)) {
                partYOffset = customYOffsetMap[partIndex];
            }

            // 현재 속성 상태를 추적하기 위한 변수 추가
            let currentAttributes = {};
            if (initialAttributes) {
                currentAttributes = { ...initialAttributes };
            }

            // renderPart에 초기 속성 전달 및 현재 속성 상태 초기화
            this.renderPart(
                part,
                factory,
                context,
                partYOffset,
                measuresToDraw,
                Vex,
                scaleFactor,
                scaledWidth,
                staveHeight,
                staveColor,
                attributeWidth,
                spacingBetweenLinesPx,
                isDoubleStave,
                isTabStave,
                staveLines,
                currentAttributes
            );
            currentYOffset +=
                this.calculatePartHeight(part, spacingBetweenLinesPx) * lines + 50;
        });
    }

    // 초기 속성을 추출하는 헬퍼 함수 추가
    extractInitialAttributes(attributes) {
        if (!attributes) {
            return {
                isDoubleStave: false,
                isTabStave: false,
                staveLines: 5,
            };
        }

        let isDoubleStave = false;
        let isTabStave = false;
        let staveLines = 5;

        if (attributes.staves === "2") {
            isDoubleStave = true;
        }

        if (attributes.clef && attributes.clef.length > 0) {
            isTabStave = attributes.clef.some((clef) => clef.sign === "TAB");
        }

        // staff-details에서 줄 수를 가져옴
        if (attributes.staffDetails && attributes.staffDetails.length > 0) {
            const staffDetail = attributes.staffDetails[0];
            if (staffDetail.staffLines) {
                staveLines = parseInt(staffDetail.staffLines, 10);
            }
        } else if (isTabStave) {
            staveLines = 6; // TAB 스태프의 기본 줄 수
        }

        return {
            isDoubleStave,
            isTabStave,
            staveLines,
        };
    }

    calculatePartHeight(part, spacingBetweenLinesPx) {
        let { isDoubleStave, isTabStave, staveLines } = this.extractInitialAttributes(
            part.measures[0].elements.find((element) => element instanceof Attributes)
        );

        // spacingBetweenLinesPx를 사용하여 staveHeight 계산
        let staveHeight = (staveLines - 1) * spacingBetweenLinesPx;

        let additionalSpacing = 10;
        if (isDoubleStave) {
            additionalSpacing += 30;
        }
        if (isTabStave) {
            additionalSpacing += 30;
        }

        return staveHeight + additionalSpacing;
    }

    updateCurrentKeySignature(part, firstMeasureIndex) {
        for (let i = 0; i < firstMeasureIndex; i++) {
            const measure = part.measures[i];
            const attributes = measure.elements.find((el) => el instanceof Attributes);
            if (attributes) {
                if (attributes.key) {
                    this.currentKeySignature = attributes.key;
                }
                if (attributes.time) {
                    this.currentTimeSignature = attributes.time;
                }
            }
        }
    }

    renderPart(
        part,
        factory,
        context,
        yOffset,
        measuresToDraw,
        Vex,
        scaleFactor,
        adjustedContainerWidth,
        staveHeight,
        staveColor,
        attributeWidth,
        spacingBetweenLinesPx,
        isDoubleStave,
        isTabStave,
        staveLines,
        currentAttributes
    ) {
        const { Formatter, Beam, Tuplet, Curve, GraceNote, GraceNoteGroup } = Vex.Flow;

        const directionCounts = {};

        const staves = [];
        const stavesPerLine = 4;
        const totalLines = Math.ceil(measuresToDraw.length / stavesPerLine);

        let previousAttributes = null;

        const initialAttributes = part.measures[0].elements.find(
            (element) => element instanceof Attributes
        );
        if (initialAttributes) {
            previousAttributes = initialAttributes;
        }

        for (let line = 0; line < totalLines; line++) {
            // 슬러와 타이를 각 라인(line)마다 선언하여 스코프 문제 해결
            const openSlurs = {};
            const slursToDraw = [];

            const openTies = {};
            const tiesToDraw = [];

            // 시스템의 첫 번째 마디와 마지막 마디 인덱스 계산
            const firstMeasureIndex = measuresToDraw[line * stavesPerLine];
            const lastMeasureIndex = measuresToDraw[
                Math.min((line + 1) * stavesPerLine - 1, measuresToDraw.length - 1)
            ];

            for (let staveIndex = 0; staveIndex < stavesPerLine; staveIndex++) {
                const measureIndex = measuresToDraw[line * stavesPerLine + staveIndex];
                if (measureIndex === undefined || measureIndex >= part.measures.length)
                    break;

                const measure = part.measures[measureIndex];
                let attributesElement = measure.elements.find(
                    (element) => element instanceof Attributes
                );

                // 이전 속성과 현재 속성을 병합
                const mergedAttributes = this.mergeAttributes(
                    previousAttributes,
                    attributesElement
                );

                // 속성 변경 여부 확인
                const attributesChanges = this.compareAttributes(
                    previousAttributes,
                    mergedAttributes
                );
                const attributesChanged =
                    attributesChanges.clefChanged ||
                    attributesChanges.keyChanged ||
                    attributesChanges.timeChanged;

                // 이전 속성 업데이트
                previousAttributes = mergedAttributes;

                // 현재 속성 상태 업데이트
                if (attributesChanged) {
                    currentAttributes = { ...mergedAttributes };
                }
                // 스태프 관련 설정은 초기 값으로 고정
                let isPercussionStave = this.isPercussionStave(mergedAttributes);
                let currentStaveLines = staveLines;

                if (mergedAttributes && mergedAttributes.clef && mergedAttributes.clef.length > 0) {
                    isPercussionStave =
                        mergedAttributes.clef.some((clef) => clef.sign === "percussion") ||
                        isPercussionStave;
                }

                // 스태프 생성 및 위치 계산
                const spacing = isDoubleStave ? staveHeight * 0.2 : 0;
                const staveY =
                    yOffset + line * spacingBetweenLinesPx * (staveLines - 1) + line * 50;

                let xPosition;
                let currentStaveWidth;

                const staveWidth = (adjustedContainerWidth - 178) / stavesPerLine + 3.5;

                if (staveIndex === 0) {
                    xPosition = 0;
                    currentStaveWidth = staveWidth + 178;
                } else {
                    xPosition = 178 + staveWidth * staveIndex;
                    currentStaveWidth = staveWidth;
                }

                // 마지막 마디인 경우 폭을 줄여서 여백을 만듭니다.
                if (staveIndex === stavesPerLine - 1) {
                    currentStaveWidth -= 15; // 15px 만큼 폭을 줄입니다.
                }

                let currentStave, currentStave2;
                if (isDoubleStave) {
                    const doubleStaveYOffset = 40;
                    currentStave = factory.Stave({
                        x: xPosition,
                        y: staveY - doubleStaveYOffset,
                        width: currentStaveWidth,
                        options: {
                            num_lines: staveLines,
                            spacing_between_lines_px: spacingBetweenLinesPx,
                        },
                    });
                    currentStave2 = factory.Stave({
                        x: xPosition,
                        y: staveY + staveHeight + spacing - doubleStaveYOffset,
                        width: currentStaveWidth,
                        options: {
                            num_lines: staveLines,
                            spacing_between_lines_px: spacingBetweenLinesPx,
                        },
                    });
                } else if (isTabStave) {
                    currentStave = factory.TabStave({
                        x: xPosition,
                        y: staveY,
                        width: currentStaveWidth,
                        options: {
                            num_lines: staveLines,
                            spacing_between_lines_px: spacingBetweenLinesPx + 2,
                        },
                    });
                } else {
                    currentStave = factory.Stave({
                        x: xPosition,
                        y: staveY,
                        width: currentStaveWidth,
                        options: {
                            num_lines: staveLines,
                            spacing_between_lines_px: spacingBetweenLinesPx,
                        },
                    });
                }

                currentStave.setStyle({
                    fillStyle: staveColor,
                    strokeStyle: staveColor,
                });

                if (isDoubleStave) {
                    currentStave2.setStyle({
                        fillStyle: staveColor,
                        strokeStyle: staveColor,
                    });
                }

                if (part === this.scorePartwise.parts[0]) {
                    context.save();
                    context.setFont("NanumSquare", 7 / scaleFactor, "");
                    const measureNumber = "" + (measureIndex + 1);
                    const x = currentStave.getX() + 3;
                    const y = currentStave.getYForTopText(0) - 1 / scaleFactor;
                    context.fillText(measureNumber, x, y);
                    context.restore();
                }

                const isFirstMeasureOnSystem = staveIndex === 0;

                // 음표 그룹을 속성 처리 전에 선언
                const voiceGroups = isDoubleStave ? [{}, {}] : [{}];

                // 속성 렌더링
                if (isFirstMeasureOnSystem) {
                    // 시스템의 첫 번째 마디인 경우 현재 속성을 모두 렌더링
                    if (attributesChanged) {
                        this.renderCurrentAttributes(
                            currentAttributes,
                            currentStave,
                            currentStave2,
                            isDoubleStave,
                            isTabStave,
                            isPercussionStave
                        );
                    } else {
                        this.renderCurrentAttributes(
                            currentAttributes,
                            currentStave,
                            currentStave2,
                            isDoubleStave,
                            isTabStave,
                            isPercussionStave
                        );
                    }
                } else if (attributesChanged) {
                    // 첫 번째 마디가 아니지만 속성이 변경된 경우
                    this.renderChangedAttributes(
                        attributesChanges,
                        mergedAttributes,
                        currentAttributes,
                        currentStave,
                        currentStave2,
                        isDoubleStave,
                        isTabStave,
                        isPercussionStave,
                        voiceGroups
                    );
                }

                // 첫 번째 마디인 경우, 음표 시작 위치를 attributeWidth로 설정
                if (isFirstMeasureOnSystem) {
                    currentStave.setNoteStartX(currentStave.getX() + 178);
                    if (isDoubleStave && currentStave2) {
                        currentStave2.setNoteStartX(currentStave2.getX() + 178);
                    }
                }

                currentStave.setContext(context).draw();
                if (isDoubleStave && currentStave2) {
                    currentStave2.setContext(context).draw();
                    staves.push([currentStave, currentStave2]);
                } else {
                    staves.push(currentStave);
                }

                const beams = [];
                const tuplets = [];

                const harmonies = [];
                const lyrics = [];

                let currentBeamGroup = [];
                let isInBeamGroup = false;
                let currentTuplet = null;

                // 시간 추적을 위한 tick 변수
                let tick = 0;

                let graceNotes = [];
                let graceTabNotes = [];

                const directionsToRender = [];

                measure.elements.forEach((element, index) => {
                    switch (true) {
                        case element instanceof Attributes:
                            // 이미 속성 처리는 위에서 했으므로 여기서는 무시하거나 필요한 경우 추가 처리를 합니다.
                            break;

                        case element instanceof Direction:
                            // 렌더링을 위해 방향 지시 요소를 수집
                            directionsToRender.push(element);

                            break;

                        case element instanceof Note:
                            // <note print-object="no">인지 확인
                            if (!this.shouldPrint(element)) {
                                // 해당 음표를 렌더링하지 않음
                                if (this.shouldPrintSpacing(element)) {
                                    // 공간은 차지하므로 GhostNote 추가
                                    const duration = this.mapDuration(element.type);
                                    const ghostNote = new Vex.Flow.GhostNote({
                                        duration: duration,
                                    });
                                    const staveIdx = isDoubleStave
                                        ? parseInt(element.staff || "1") - 1
                                        : 0;
                                    const voiceNumber = element.voice || "1";

                                    // 스태프 설정
                                    const currentStaveToUse = isDoubleStave
                                        ? staveIdx === 0
                                            ? currentStave
                                            : currentStave2
                                        : currentStave;

                                    if (!voiceGroups[staveIdx][voiceNumber]) {
                                        voiceGroups[staveIdx][voiceNumber] = [];
                                    }
                                    voiceGroups[staveIdx][voiceNumber].push(ghostNote);
                                }
                                return;
                            }

                            const staveIdxNote = isDoubleStave
                                ? parseInt(element.staff || "1") - 1
                                : 0;
                            const voiceNumberNote = element.voice || "1";

                            if (!voiceGroups[staveIdxNote][voiceNumberNote]) {
                                voiceGroups[staveIdxNote][voiceNumberNote] = [];
                            }

                            // 스태프 설정
                            const currentStaveToUseNote = isDoubleStave
                                ? staveIdxNote === 0
                                    ? currentStave
                                    : currentStave2
                                : currentStave;

                            if (element.grace) {
                                // 장식음 처리
                                const graceNote = this.createGraceNote(
                                    element,
                                    isTabStave,
                                    isPercussionStave
                                );
                                if (graceNote) {
                                    graceNote.setStave(currentStaveToUseNote);

                                    if (
                                        graceNote instanceof Vex.Flow.TabNote ||
                                        graceNote instanceof Vex.Flow.StaveNote
                                    ) {
                                        voiceGroups[staveIdxNote][voiceNumberNote].push(graceNote);
                                    } else {
                                        if (isTabStave) {
                                            graceTabNotes.push(graceNote);
                                        } else if (isPercussionStave) {
                                            graceNotes.push(graceNote);
                                        } else {
                                            graceNotes.push(graceNote);
                                        }
                                    }
                                }
                            } else {
                                // 일반 음표 처리
                                let vexNote;
                                if (isTabStave) {
                                    vexNote = element.getVexFlowTabNote(factory);
                                    vexNote.setStem(false);

                                    // 여기서 컨텍스트의 폰트를 시스템 폰트로 설정
                                    context.setFont('', 10, '');
                                } else if (element.unpitched) {
                                    vexNote = element.getVexFlowPercussionNote(factory);
                                } else {
                                    vexNote = element.getVexFlowStaveNote(factory);
                                }

                                vexNote.setStave(currentStaveToUseNote);

                                // GraceNoteGroup 추가
                                if (isTabStave && graceTabNotes.length > 0) {
                                    try {
                                        const graceNoteGroup = new Vex.Flow.GraceNoteGroup(
                                            graceTabNotes,
                                            true
                                        );
                                        vexNote.addModifier(graceNoteGroup, 0);
                                        graceTabNotes = [];
                                    } catch (error) {
                                        console.error("GraceNoteGroup 생성 중 오류:", error);
                                    }
                                } else if (isPercussionStave && graceNotes.length > 0) {
                                    try {
                                        const graceNoteGroup = new Vex.Flow.GraceNoteGroup(
                                            graceNotes,
                                            true
                                        );
                                        vexNote.addModifier(graceNoteGroup, 0);
                                        graceNotes = [];
                                    } catch (error) {
                                        console.error("GraceNoteGroup 생성 중 오류:", error);
                                    }
                                } else if (!isTabStave && graceNotes.length > 0) {
                                    try {
                                        const graceNoteGroup = new Vex.Flow.GraceNoteGroup(
                                            graceNotes,
                                            true
                                        );
                                        vexNote.addModifier(graceNoteGroup, 0);
                                        graceNotes = [];
                                    } catch (error) {
                                        console.error("GraceNoteGroup 생성 중 오류:", error);
                                    }
                                }

                                element.applyTechnical(vexNote, isTabStave);

                                voiceGroups[staveIdxNote][voiceNumberNote].push(vexNote);

                                // 하모니 처리
                                if (harmonies.length > 0 && harmonies[harmonies.length - 1].note === null) {
                                    if (vexNote.duration !== "w") {
                                        harmonies[harmonies.length - 1].note = vexNote;
                                    }
                                }

                                // 가사 처리
                                if (element.lyrics && element.lyrics.length > 0) {
                                    element.lyrics.forEach((lyric, lyricIndex) => {
                                        if (lyric.text) {
                                            lyrics.push({
                                                text: lyric.text,
                                                note: vexNote,
                                                index: lyricIndex,
                                            });
                                        }
                                    });
                                }

                                // 빔 처리
                                if (!isTabStave) {
                                    // TAB 악보가 아닐 때만 beam을 처리
                                    if (element.beams && element.beams.length > 0) {
                                        const beam = element.beams.find((b) => b.number === "1");
                                        if (beam) {
                                            switch (beam.value) {
                                                case "begin":
                                                    isInBeamGroup = true;
                                                    currentBeamGroup = [vexNote];
                                                    break;
                                                case "end":
                                                    if (isInBeamGroup) {
                                                        currentBeamGroup.push(vexNote);
                                                        if (currentBeamGroup.length >= 2) {
                                                            beams.push(new Beam(currentBeamGroup));
                                                        }
                                                        isInBeamGroup = false;
                                                        currentBeamGroup = [];
                                                    }
                                                    break;
                                                case "continue":
                                                    if (isInBeamGroup) {
                                                        currentBeamGroup.push(vexNote);
                                                    }
                                                    break;
                                            }
                                        }
                                    } else {
                                        if (isInBeamGroup) {
                                            currentBeamGroup.push(vexNote);
                                        }
                                    }
                                }

                                // 투플렛 처리
                                if (element.timeModification) {
                                    const actualNotes = parseInt(element.timeModification.actualNotes);
                                    const normalNotes = parseInt(element.timeModification.normalNotes);

                                    if (!currentTuplet) {
                                        currentTuplet = {
                                            notes: [],
                                            actualNotes: actualNotes,
                                            normalNotes: normalNotes,
                                        };
                                    }

                                    currentTuplet.notes.push(vexNote);

                                    if (currentTuplet.notes.length === currentTuplet.actualNotes) {
                                        const tupletOptions = {
                                            num_notes: currentTuplet.actualNotes,
                                            notes_occupied: currentTuplet.normalNotes,
                                        };
                                        tuplets.push(new Tuplet(currentTuplet.notes, tupletOptions));
                                        currentTuplet = null;
                                    }
                                } else if (currentTuplet) {
                                    console.log(
                                        `Warning: Incomplete tuplet found (${currentTuplet.notes.length}/${currentTuplet.actualNotes})`
                                    );
                                    currentTuplet = null;
                                }

                                // 타이 처리
                                if (element.ties && element.ties.length > 0) {
                                    element.ties.forEach((tie) => {
                                        const tieNumber = tie.number || 1;
                                        const tieType = tie.type;

                                        if (tieType === "start") {
                                            if (!openTies[staveIdxNote]) {
                                                openTies[staveIdxNote] = {};
                                            }
                                            openTies[staveIdxNote][tieNumber] = {
                                                startNote: vexNote,
                                                staveIdx: staveIdxNote,
                                                startIndex: index,
                                            };
                                        } else if (tieType === "stop") {
                                            if (
                                                openTies[staveIdxNote] &&
                                                openTies[staveIdxNote][tieNumber]
                                            ) {
                                                const tieInfo = openTies[staveIdxNote][tieNumber];
                                                tiesToDraw.push({
                                                    startNote: tieInfo.startNote,
                                                    endNote: vexNote,
                                                    staveIdx: staveIdxNote,
                                                    startIndex: tieInfo.startIndex,
                                                    endIndex: index,
                                                });
                                                delete openTies[staveIdxNote][tieNumber];
                                            } else {
                                                tiesToDraw.push({
                                                    startNote: null,
                                                    endNote: vexNote,
                                                    staveIdx: staveIdxNote,
                                                    startIndex: null,
                                                    endIndex: index,
                                                });
                                            }
                                        }
                                    });
                                }

                                // 슬러 처리
                                if (element.notations && element.notations.slur) {
                                    element.notations.slur.forEach((slur) => {
                                        const slurNumber = slur.number || 1;
                                        const slurType = slur.type;

                                        if (slurType === "start") {
                                            openSlurs[slurNumber] = {
                                                startNote: vexNote,
                                                staveIdx: staveIdxNote,
                                                startSlurData: slur,
                                                startIndex: index,
                                            };
                                        } else if (slurType === "stop") {
                                            if (openSlurs[slurNumber]) {
                                                const slurInfo = openSlurs[slurNumber];
                                                const slurData = {
                                                    startNote: slurInfo.startNote,
                                                    endNote: vexNote,
                                                    first_indices: [0],
                                                    last_indices: [0],
                                                    staveIdx: slurInfo.staveIdx,
                                                    startSlurData: slurInfo.startSlurData,
                                                    stopSlurData: slur,
                                                    startIndex: slurInfo.startIndex,
                                                    endIndex: index,
                                                };
                                                slursToDraw.push(slurData);
                                                delete openSlurs[slurNumber];
                                            } else {
                                                slursToDraw.push({
                                                    startNote: null,
                                                    endNote: vexNote,
                                                    staveIdx: staveIdxNote,
                                                    startSlurData: null,
                                                    stopSlurData: slur,
                                                    startIndex: null,
                                                    endIndex: index,
                                                });
                                            }
                                        }
                                    });
                                }
                            }
                            break;

                        case element instanceof Harmony:
                            harmonies.push({
                                note: null,
                                harmony: element,
                                offset: element.offset,
                            });
                            break;

                        case element instanceof Backup:
                            tick -= parseInt(element.duration || 0);
                            break;

                        case element instanceof Forward:
                            tick += parseInt(element.duration || 0);
                            break;
                    }
                });

                // 열린 빔 그룹 처리
                if (isInBeamGroup && currentBeamGroup.length >= 2) {
                    beams.push(new Beam(currentBeamGroup));
                    isInBeamGroup = false;
                    currentBeamGroup = [];
                }

                // GraceNote 처리되지 않은 경우
                if (graceNotes.length > 0 || graceTabNotes.length > 0) {
                    console.warn("마지막 음표 이후에 처리되지 않은 grace note가 있습니다.");
                    graceNotes = [];
                    graceTabNotes = [];
                }

                // 열린 슬러와 타이 처리
                if (measureIndex === lastMeasureIndex) {
                    Object.keys(openTies).forEach((staveIdx) => {
                        Object.keys(openTies[staveIdx]).forEach((tieNumber) => {
                            const tieInfo = openTies[staveIdx][tieNumber];
                            tiesToDraw.push({
                                startNote: tieInfo.startNote,
                                endNote: null,
                                staveIdx: staveIdx,
                            });
                            delete openTies[staveIdx][tieNumber];
                        });
                    });

                    Object.keys(openSlurs).forEach((slurNumber) => {
                        const slurInfo = openSlurs[slurNumber];
                        slursToDraw.push({
                            startNote: slurInfo.startNote,
                            endNote: null,
                            staveIdx: slurInfo.staveIdx,
                            startSlurData: slurInfo.startSlurData,
                            stopSlurData: null,
                            startIndex: slurInfo.startIndex,
                            endIndex: null,
                        });
                        delete openSlurs[slurNumber];
                    });
                }

                const formatter = new Formatter({
                    align_rests: true,
                    softmaxFactor: 100
                });

                voiceGroups.forEach((staveVoices, staveIdx) => {
                    const voiceCount = Object.keys(staveVoices).length;
                    if (voiceCount > 0) {
                        const vexVoices = Object.values(staveVoices).map((notes) => {
                            const voice = factory.Voice({ num_beats: 4, beat_value: 4 })
                                .setStrict(false);

                            const currentStaveToUseVoice = isDoubleStave
                                ? staveIdx === 0
                                    ? currentStave
                                    : currentStave2
                                : currentStave;
                            voice.setStave(currentStaveToUseVoice);

                            voice.addTickables(notes);
                            return voice;
                        });

                        const currentStaveToUseVoice = isDoubleStave
                            ? staveIdx === 0
                                ? currentStave
                                : currentStave2
                            : currentStave;

                        const modifierXShift = typeof currentStaveToUseVoice.getModifierXShift === "function"
                            ? currentStaveToUseVoice.getModifierXShift()
                            : 0;

                        // 기본 포맷 너비 계산에 여유 공간 추가
                        let formatWidth = currentStaveWidth - modifierXShift - 30;

                        if (staveIndex === stavesPerLine - 1) {
                            formatWidth -= 15;
                        }

                        if (isTabStave) {
                            // 1. 모든 음표에 대해 preFormat 실행
                            vexVoices.forEach(voice => {
                                voice.getTickables().forEach(note => {
                                    if (note.preFormat) {
                                        note.preFormat();
                                    }
                                });
                            });

                            // 2. 음표 간격 계산
                            const totalTicks = vexVoices.reduce(
                                (acc, voice) => Math.max(acc, voice.getTotalTicks().value()),
                                0
                            );

                            const minNoteSpacing = 25; // 최소 음표 간격
                            const availableWidth = formatWidth - (2 * minNoteSpacing); // 시작과 끝의 여백
                            const noteSpacing = Math.max(minNoteSpacing, availableWidth / (totalTicks || 1));

                            // 3. 음표 너비 조정
                            vexVoices.forEach(voice => {
                                voice.getTickables().forEach(note => {
                                    if (note.getWidth && note.setWidth) {
                                        const minWidth = 20;
                                        const currentWidth = note.getWidth();
                                        if (currentWidth < minWidth) {
                                            note.setWidth(minWidth);
                                        }
                                    }
                                });
                            });

                            // 4. 포맷터 설정
                            try {
                                formatter.joinVoices(vexVoices).format(vexVoices, formatWidth, {
                                    align_rests: true,
                                    context: context,
                                    stave: currentStaveToUseVoice,
                                    minNoteSpacing: minNoteSpacing
                                });

                                // 5. 마지막 음표 위치 확인
                                const lastVoice = vexVoices[0];
                                if (lastVoice) {
                                    const tickables = lastVoice.getTickables();
                                    const lastNote = tickables[tickables.length - 1];

                                    if (lastNote) {
                                        const lastNoteRight = lastNote.getX() + lastNote.getWidth();
                                        const staveRight = currentStaveToUseVoice.getWidth();

                                        if (lastNoteRight > staveRight - 15) {
                                            // 전체 음표들의 간격을 조정
                                            const totalAdjustment = (staveRight - 15) - lastNoteRight;
                                            const adjustmentPerNote = totalAdjustment / (tickables.length - 1);

                                            vexVoices.forEach(voice => {
                                                const notes = voice.getTickables();
                                                const positions = formatter.getTicksUsed().map(tick => tick.position);

                                                notes.forEach((note, i) => {
                                                    if (i > 0 && positions[i]) {
                                                        const newPosition = positions[i] + (adjustmentPerNote * i);
                                                        formatter.getTicksUsed()[i].position = newPosition;
                                                    }
                                                });
                                            });

                                            // 포맷터 재적용
                                            formatter.format(vexVoices, formatWidth);
                                        }
                                    }
                                }
                            } catch (error) {
                                console.error("Error during formatting:", error);
                            }
                        } else {
                            // 일반 스태프 포맷팅
                            formatter.joinVoices(vexVoices).format(vexVoices, formatWidth);
                        }

                        // 음표 렌더링
                        vexVoices.forEach((voice) => voice.draw(context, currentStaveToUseVoice));

                        // 빔 렌더링
                        beams.forEach((beam) => {
                            try {
                                beam.setContext(context).draw();
                            } catch (error) {
                                console.error(`Beam 그리는 중 오류 발생: ${error.message}`);
                            }
                        });

                        // 방향 지시어 렌더링 (기존 코드와 동일)
                        const directionIndices = {};

                        directionsToRender.forEach((direction) => {
                            // 노테이션 타입 키 생성 (예: 'above_words', 'below_metronome' 등)
                            const notationKey = `${direction.placement}_${this.getDirectionTypeKey(
                                direction.directionType
                            )}`;

                            if (!directionIndices[notationKey]) {
                                directionIndices[notationKey] = 0;
                            }

                            const text = this.getDirectionText(direction.directionType);
                            if (!text) return;

                            context.setFont("NanumSquare", 12, "bold");
                            const textMetrics = context.measureText(text);
                            const textWidth = textMetrics.width;

                            const x = currentStaveToUseVoice.getNoteStartX() + 10;
                            const directionXStart = x;
                            const directionXEnd = x + textWidth;

                            let maxNoteY = -Infinity;
                            let minNoteY = Infinity;

                            vexVoices.forEach((voice) => {
                                voice.getTickables().forEach((tickable) => {
                                    const bb = tickable.getBoundingBox();
                                    if (bb) {
                                        const noteXStart = bb.getX();
                                        const noteXEnd = bb.getX() + bb.getW();
                                        if (
                                            (noteXStart <= directionXEnd && noteXEnd >= directionXStart) ||
                                            (noteXEnd >= directionXStart && noteXStart <= directionXEnd)
                                        ) {
                                            const yTop = bb.getY();
                                            const yBottom = bb.getY() + bb.getH();
                                            if (yBottom > maxNoteY) maxNoteY = yBottom;
                                            if (yTop < minNoteY) minNoteY = yTop;
                                        }
                                    }
                                });
                            });

                            if (maxNoteY === -Infinity || minNoteY === Infinity) {
                                maxNoteY = currentStaveToUseVoice.getYForLine(
                                    currentStaveToUseVoice.getNumLines() - 1
                                );
                                minNoteY = currentStaveToUseVoice.getYForLine(0);
                            }

                            const staveTopY = currentStaveToUseVoice.getYForLine(0);
                            const minYPosition = staveTopY - 5;
                            minNoteY = Math.min(minNoteY, minYPosition);

                            directionIndices[notationKey] = this.renderDirection(
                                direction.directionType,
                                direction.placement,
                                context,
                                currentStaveToUseVoice,
                                minNoteY,
                                maxNoteY,
                                directionIndices[notationKey]
                            );
                        });
                    }
                });

                // 노트가 있는 화음 처리
                const mainHarmonies = harmonies.filter((h) => h.note);
                const harmoniesWithoutNotes = harmonies.filter((h) => {
                    // 현재 화음이 note가 없고
                    if (!h.note) {
                        // offset이 있다면, 이전 화음 중에 note가 있는지 확인
                        if (h.offset) {
                            const prevHarmony = harmonies[harmonies.indexOf(h) - 1];
                            return !prevHarmony || !prevHarmony.note; // note가 있는 화음 다음의 offset 화음이 아닌 경우만 포함
                        }
                        return true; // offset이 없는 경우는 포함
                    }
                    return false; // note가 있는 경우는 제외
                });

                const harmonyTextY = currentStave.getYForTopText(0) - 40;

                // 노트가 있는 화음과 그에 연관된 offset 화음 처리
                harmonies.forEach((harmony, index) => {
                    if (harmony.note) {
                        const baseX = harmony.note.getAbsoluteX();

                        context.save();
                        context.fillStyle = "rgba(0, 0, 0, 0.7)";
                        context.textAlign = "center";
                        context.setFont("NanumSquare", 13, "bold");

                        const baseHarmonyText = harmony.harmony.generateHarmonyText();
                        context.fillText(baseHarmonyText, baseX, harmonyTextY);

                        // 다음 화음이 offset을 가진 경우 처리
                        const nextHarmony = harmonies[index + 1];
                        if (nextHarmony && nextHarmony.offset) {
                            const baseHarmonyWidth = context.measureText(baseHarmonyText).width;
                            const nextHarmonyText = nextHarmony.harmony.generateHarmonyText();
                            const nextHarmonyWidth = context.measureText(nextHarmonyText).width;

                            const spacing = baseHarmonyWidth + 40;
                            context.fillText(nextHarmonyText, baseX + spacing, harmonyTextY);
                        }

                        context.restore();
                    }
                });

                // 나머지 화음들 처리
                if (harmoniesWithoutNotes.length > 0) {
                    const staveStartX = currentStave.getNoteStartX();
                    const staveEndX = currentStave.getX() + currentStave.getWidth();
                    const availableWidth = staveEndX - staveStartX;

                    context.save();
                    context.setFont("NanumSquare", 13, "bold");
                    const harmonyWidths = harmoniesWithoutNotes.map((harmony) =>
                        context.measureText(harmony.harmony.generateHarmonyText()).width
                    );
                    const totalHarmonyWidth = harmonyWidths.reduce((sum, width) => sum + width, 0);

                    const totalMarginSpace = availableWidth - totalHarmonyWidth;
                    const marginPerHarmony = totalMarginSpace / harmoniesWithoutNotes.length;

                    let currentX = staveStartX;

                    harmoniesWithoutNotes.forEach((harmony, index) => {
                        const harmonyWidth = harmonyWidths[index];
                        context.save();
                        context.fillStyle = "rgba(0, 0, 0, 0.7)";
                        context.textAlign = "left";
                        context.setFont("NanumSquare", 13, "bold");
                        context.fillText(harmony.harmony.generateHarmonyText(), currentX, harmonyTextY);
                        context.restore();

                        currentX += harmonyWidth + marginPerHarmony;
                    });

                    context.restore();
                }

                tuplets.forEach((tuplet) => {
                    try {
                        tuplet.setContext(context).draw();
                    } catch (error) {
                        console.error("Tuplet 그리는 중 오류:", error);
                    }
                });

                if (!isTabStave) {
                    // 타이 그리기
                    tiesToDraw.forEach((tie) => {
                        if (isTabStave) {
                            return;
                        }
                        try {
                            if (tie.startNote && tie.endNote) {
                                const firstIndices = tie.startNote.getKeys().map((_, idx) => idx);
                                const lastIndices = tie.endNote.getKeys().map((_, idx) => idx);

                                new Vex.Flow.StaveTie({
                                    first_note: tie.startNote,
                                    last_note: tie.endNote,
                                    first_indices: firstIndices,
                                    last_indices: lastIndices,
                                })
                                    .setContext(context)
                                    .draw();
                            } else {
                                // 오픈 타이 처리
                                const note = tie.startNote || tie.endNote;
                                const isStart = !!tie.startNote;

                                const stemDirection = note.getStemDirection();

                                const curve = new Vex.Flow.Curve(
                                    isStart ? note : null,
                                    isStart ? null : note,
                                    {
                                        invert: false,
                                        position: Vex.Flow.Curve.Position.NEAR_HEAD,
                                        y_shift_start: 0,
                                        y_shift_end: 0,
                                        x_shift_start: 0,
                                        x_shift_end: 0,
                                        color: "black",
                                    }
                                );

                                curve.setContext(context).draw();
                            }
                        } catch (error) {
                            console.error("Error drawing tie:", error);
                        }
                    });

                    // 슬러 그리기
                    slursToDraw.forEach((slur) => {
                        try {
                            const currentStaveToUse = isDoubleStave
                                ? slur.staveIdx === 0
                                    ? currentStave
                                    : currentStave2
                                : currentStave;

                            let startStemDirection = slur.startNote.stem;
                            if (!startStemDirection) {
                                startStemDirection = "up";
                            }

                            let invert = false;

                            if (startStemDirection === "down") {
                                invert = false;
                            } else {
                                invert = true;
                            }

                            if (!slur.startNote.stem) {
                                invert = true;
                            }

                            const startX = slur.startNote.getAbsoluteX();
                            const endX = slur.endNote.getAbsoluteX();
                            const xDistance = endX - startX;

                            const minCpsY = 10;
                            const maxCpsY = 50;
                            let cpsY = (xDistance / 100) * 20;
                            cpsY = Math.max(minCpsY, Math.min(maxCpsY, cpsY));

                            if (invert) {
                                cpsY = Math.abs(cpsY);
                            } else {
                                cpsY = -Math.abs(cpsY);
                            }

                            const xShiftStart = -5;
                            const xShiftEnd = 5;

                            const slurCurve = new Vex.Flow.Curve(slur.startNote, slur.endNote, {
                                cps: [
                                    { x: 0, y: cpsY },
                                    { x: 0, y: cpsY },
                                ],
                                invert: false,
                                position: Vex.Flow.Curve.Position.NEAR_HEAD,
                                position_end: Vex.Flow.Curve.Position.NEAR_HEAD,
                                y_shift_start: 0,
                                y_shift_end: 0,
                                x_shift_start: xShiftStart,
                                x_shift_end: xShiftEnd,
                                thickness: 2,
                                color: "black",
                            });

                            slurCurve.setContext(context).draw();
                        } catch (error) {
                            console.error("Slur 그리는 중 오류:", error);
                        }
                    });
                }

                if (lyrics.length > 0) {
                    this.renderLyrics(lyrics, context, currentStave);
                }
            }
        }
    }

    renderCurrentAttributes(
        currentAttributes,
        currentStave,
        currentStave2,
        isDoubleStave,
        isTabStave,
        isPercussionStave
    ) {
        if (currentAttributes.clef && currentAttributes.clef.length > 0) {
            currentAttributes.clef.forEach((clef, idx) => {
                let vexClef = isPercussionStave ? "percussion" : clef.getVexFlowClef();

                const clefModifier = new Vex.Flow.Clef(vexClef);

                if (idx === 0 || !isDoubleStave) {
                    currentStave.addModifier(clefModifier);
                } else if (isDoubleStave && currentStave2) {
                    currentStave2.addModifier(clefModifier);
                }
            });
        }

        // Key signature rendering (skip on TAB staves)
        if (!isTabStave && !isPercussionStave) {
            // 수정
            const keyToRender = this.currentKeySignature || currentAttributes.key;
            if (keyToRender) {
                const vexKey = keyToRender.getVexFlowKeySignature();
                if (vexKey !== undefined) {
                    currentStave.addKeySignature(vexKey);
                    if (isDoubleStave && currentStave2) {
                        currentStave2.addKeySignature(vexKey);
                    }
                } else {
                    console.warn("VexFlow key signature is undefined");
                }
            }
        }

        // Time signature rendering (skip on TAB staves)
        if (!isTabStave) {
            const timeToRender = this.currentTimeSignature || currentAttributes.time;
            if (timeToRender) {
                const timeSignature = `${timeToRender.beats}/${timeToRender.beatType}`;
                currentStave.addTimeSignature(timeSignature);
                if (isDoubleStave && currentStave2) {
                    currentStave2.addTimeSignature(timeSignature);
                }
            }
        }
    }

    renderChangedAttributes(
        changes,
        mergedAttributes,
        currentAttributes,
        currentStave,
        currentStave2,
        isDoubleStave,
        isTabStave,
        isPercussionStave,
        voiceGroups
    ) {
        if (changes.clefChanged) {
            currentAttributes.clef = mergedAttributes.clef;
            if (mergedAttributes.clef) {
                mergedAttributes.clef.forEach((clef, idx) => {
                    let vexClef = isPercussionStave ? "percussion" : clef.getVexFlowClef();

                    const clefNote = new Vex.Flow.ClefNote(vexClef);
                    const staveIdx = isDoubleStave ? idx : 0;

                    const currentStaveToUse = isDoubleStave
                        ? staveIdx === 0
                            ? currentStave
                            : currentStave2
                        : currentStave;
                    clefNote.setStave(currentStaveToUse);

                    const voiceNumber = "1";
                    if (!voiceGroups[staveIdx][voiceNumber]) {
                        voiceGroups[staveIdx][voiceNumber] = [];
                    }
                    voiceGroups[staveIdx][voiceNumber].push(clefNote);
                });
            }
        }

        // Key signature change handling (skip on TAB staves)
        if (!isTabStave && changes.keyChanged && mergedAttributes.key && !isPercussionStave) {
            // 수정
            currentAttributes.key = mergedAttributes.key;
            this.currentKeySignature = mergedAttributes.key; // Update current key signature

            const vexKey = mergedAttributes.key.getVexFlowKeySignature();

            if (vexKey !== undefined) {
                const keySigNote = new Vex.Flow.KeySigNote(vexKey);
                keySigNote.setStave(currentStave);

                const voiceNumber = "1";
                if (!voiceGroups[0][voiceNumber]) {
                    voiceGroups[0][voiceNumber] = [];
                }
                voiceGroups[0][voiceNumber].push(keySigNote);

                if (isDoubleStave && currentStave2) {
                    const keySigNote2 = new Vex.Flow.KeySigNote(vexKey);
                    keySigNote2.setStave(currentStave2);
                    voiceGroups[1][voiceNumber] = voiceGroups[1][voiceNumber] || [];
                    voiceGroups[1][voiceNumber].push(keySigNote2);
                }
            } else {
                console.warn("VexFlow key signature is undefined");
            }
        }

        // Time signature change handling (skip on TAB staves)
        if (!isTabStave && changes.timeChanged && mergedAttributes.time) {
            currentAttributes.time = mergedAttributes.time;
            this.currentTimeSignature = mergedAttributes.time; // 현재 박자표 상태 업데이트

            const timeSigNote = new Vex.Flow.TimeSigNote(
                `${mergedAttributes.time.beats}/${mergedAttributes.time.beatType}`
            );
            timeSigNote.setStave(currentStave);

            const voiceNumber = "1";
            if (!voiceGroups[0][voiceNumber]) {
                voiceGroups[0][voiceNumber] = [];
            }
            voiceGroups[0][voiceNumber].push(timeSigNote);

            if (isDoubleStave && currentStave2) {
                const timeSigNote2 = new Vex.Flow.TimeSigNote(
                    `${mergedAttributes.time.beats}/${mergedAttributes.time.beatType}`
                );
                timeSigNote2.setStave(currentStave2);
                voiceGroups[1][voiceNumber] = voiceGroups[1][voiceNumber] || [];
                voiceGroups[1][voiceNumber].push(timeSigNote2);
            }
        }
    }

    // 타악기 스태프인지 확인하는 헬퍼 함수 추가
    isPercussionStave(attributes) {
        if (!attributes || !attributes.clef) return false;
        return attributes.clef.some((clef) => clef.sign === "percussion");
    }

    createGraceNote(element, isTabStave, isPercussionStave) {
        if (!element.grace) return null;

        const duration = this.mapDuration(element.type);

        if (isTabStave) {
            // GraceTabNote 생성
            const graceNoteStruct = {
                duration: duration,
                positions: element.getTabPositions(),
                slash: element.grace.slash === "yes",
            };
            const graceNote = new Vex.Flow.GraceTabNote(graceNoteStruct);
            element.applyTechnical(graceNote, isTabStave);
            return graceNote;
        } else if (isPercussionStave) {
            // Unpitched(타악기) 스태프용 GraceNote 생성
            const graceNoteStruct = {
                duration: duration,
                keys: element.getVexFlowKeys() || ["C/5"], // 기본 키 설정 또는 요소에서 키 가져오기
                slash: element.grace.slash === "yes",
            };
            const graceNote = new Vex.Flow.GraceNote(graceNoteStruct);
            graceNote.setStemDirection(element.stemDirection || 1); // Stem 방향 설정
            element.applyTechnical(graceNote, isTabStave);
            return graceNote;
        } else {
            // 일반 GraceNote 처리
            const graceNoteStruct = {
                duration: duration,
                keys: element.getVexFlowKeys(),
                slash: element.grace.slash === "yes",
            };
            const graceNote = new Vex.Flow.GraceNote(graceNoteStruct);
            graceNote.setStemDirection(element.stemDirection || 1); // Stem 방향 설정
            element.applyTechnical(graceNote, isTabStave);
            return graceNote;
        }
    }

    // getDirectionText 함수 추가
    getDirectionText(directionType) {
        for (const [type, value] of Object.entries(directionType)) {
            if (value) {
                switch (type) {
                    case "metronome":
                        if (value.perMinute) {
                            return `♩ = ${value.perMinute}`;
                        }
                        break;
                    case "words":
                        if (typeof value === "string" && value.trim() !== "") {
                            return value;
                        }
                        break;
                    case "dynamics":
                        if (typeof value === "string" && value.trim() !== "") {
                            return value;
                        } else if (typeof value === "object") {
                            for (const dynKey in value) {
                                return dynKey; // 예: 'mp', 'f', 'p' 등
                            }
                        }
                        break;
                    default:
                        // 다른 타입 처리 필요 시 여기에 추가
                        break;
                }
            }
        }
        return null;
    }

    // getDirectionTypeKey 함수 수정
    getDirectionTypeKey(directionType) {
        for (const [type, value] of Object.entries(directionType)) {
            if (value) {
                return type; // 타입을 그대로 키로 사용
            }
        }
        return "unknown";
    }

    renderDirection(
        directionType,
        placement,
        context,
        stave,
        minNoteY,
        maxNoteY,
        directionIndex = 0
    ) {
        const text = this.getDirectionText(directionType);
        if (!text) return directionIndex;

        const paddingY = 2;
        const textHeight = 13;
        const lineSpacing = 5;
        const minDistanceFromNote = 15;
        const staveTopPadding = 15;

        context.save();
        context.setFont("NanumSquare", 11, "");
        const textMetrics = context.measureText(text);
        const textWidth = Math.ceil(textMetrics.width);

        const x = stave.getNoteStartX() + 10;

        // 이미 렌더링된 direction들의 정보 수집
        const existingDirections = stave
            .getModifiers()
            .filter((mod) => mod.placement === placement) // 같은 방향의 direction만 고려
            .filter((mod) => {
                const modX = mod.getX();
                return Math.abs(modX - x) < textWidth; // x 위치가 겹치는 것만 고려
            })
            .map((mod) => ({
                y: mod.getY(),
                height: textHeight,
            }))
            .sort((a, b) => (placement === "above" ? a.y - b.y : b.y - a.y));

        // 기본 y 위치 계산
        let baseY;
        if (placement === "above") {
            baseY = minNoteY - minDistanceFromNote - (textHeight + lineSpacing) * directionIndex;

            // 이미 있는 direction들과 겹치지 않도록 조정
            for (const existing of existingDirections) {
                if (baseY > existing.y - (textHeight + lineSpacing)) {
                    baseY = existing.y - (textHeight + lineSpacing);
                }
            }

            // 스태프 상단과의 최소 거리 확보
            const staveTopY = stave.getYForLine(0);
            const minY = staveTopY - staveTopPadding - (textHeight + lineSpacing) * (directionIndex + 1);
            const y = Math.min(baseY, minY);

            context.textBaseline = "bottom";
            context.fillText(text, x, y);
        } else {
            baseY = maxNoteY + minDistanceFromNote + (textHeight + lineSpacing) * directionIndex;

            // 이미 있는 direction들과 겹치지 않도록 조정
            for (const existing of existingDirections) {
                if (baseY < existing.y + existing.height + lineSpacing) {
                    baseY = existing.y + existing.height + lineSpacing;
                }
            }

            // 최종 y 위치 결정
            const y = baseY;

            context.textBaseline = "top";
            context.fillText(text, x, y);
        }

        // 현재 direction의 정보를 stave의 modifier에 추가
        const directionInfo = {
            getX: () => x,
            getY: () => (placement === "above" ? baseY : baseY),
            placement: placement,
        };
        stave.modifiers = stave.modifiers || [];
        stave.modifiers.push(directionInfo);

        context.restore();
        return directionIndex + 1;
    }

    renderLyrics(lyrics, context, currentStave) {
        if (!lyrics || !Array.isArray(lyrics) || lyrics.length === 0) {
            return; // 가사가 없으면 바로 반환
        }

        const paddingX = 4;
        const paddingY = 2;
        const textHeight = 13;
        const lineSpacing = 5;
        const minDistanceFromNote = 5;
        const textLeftOffset = 2;

        const baseYPositionOptions = {
            1: 0,
            2: textHeight + lineSpacing,
        };

        // 1단계: 가사의 너비와 위치 정보 계산
        context.save();
        context.setFont("NanumSquare", 13, "");
        context.textAlign = "left";
        context.textBaseline = "bottom";

        const lyricsInfo = lyrics
            .map((lyric) => {
                // lyric 객체 자체가 유효한지 확인
                if (!lyric) {
                    console.warn("Invalid lyric object");
                    return null;
                }

                // note 객체와 getBoundingBox 메서드 존재 여부 확인
                if (!lyric.note || typeof lyric.note.getBoundingBox !== "function") {
                    console.warn("Invalid note or missing getBoundingBox method for lyric:", lyric);
                    return null;
                }

                // note의 BoundingBox 가져오기 시도
                let noteBBox;
                try {
                    noteBBox = lyric.note.getBoundingBox();
                } catch (error) {
                    console.warn("Error getting bounding box for note:", error);
                    return null;
                }

                // BoundingBox가 유효한지 확인
                if (
                    !noteBBox ||
                    typeof noteBBox.getX !== "function" ||
                    typeof noteBBox.getY !== "function" ||
                    typeof noteBBox.getW !== "function" ||
                    typeof noteBBox.getH !== "function"
                ) {
                    console.warn("Invalid bounding box for note");
                    return null;
                }

                const noteCenterX = noteBBox.getX() + noteBBox.getW() / 2;
                const noteBottomY = noteBBox.getY() + noteBBox.getH();
                const baseY = currentStave.getYForBottomText(0) + 10;

                // 가사 텍스트 유효성 검사
                if (!lyric.text || typeof lyric.text !== "string" || lyric.text.trim() === "") {
                    console.warn("Empty or invalid lyric text");
                    return null;
                }

                // 텍스트 측정
                let textWidth;
                try {
                    const textMetrics = context.measureText(lyric.text);
                    textWidth = Math.ceil(textMetrics.width);
                } catch (error) {
                    console.warn("Error measuring text:", error);
                    textWidth = lyric.text.length * 8; // 폴백 값 사용
                }

                if (textWidth <= 1) {
                    textWidth = lyric.text.length * 8;
                }

                const rectWidth = textWidth + paddingX * 2;

                const noteRange = {
                    start: noteCenterX - rectWidth / 2,
                    end: noteCenterX + rectWidth / 2,
                };

                let lowestNoteY = noteBottomY;

                // voice 관련 계산에 대한 방어 코드 추가
                if (lyric.note.voice && Array.isArray(lyric.note.voice.tickables)) {
                    lyric.note.voice.tickables.forEach((tickable) => {
                        if (tickable && typeof tickable.getBoundingBox === "function") {
                            try {
                                const tickableBBox = tickable.getBoundingBox();
                                if (tickableBBox) {
                                    const tickableX = tickableBBox.getX() + tickableBBox.getW() / 2;
                                    if (
                                        tickableX >= noteRange.start &&
                                        tickableX <= noteRange.end
                                    ) {
                                        const tickableBottomY =
                                            tickableBBox.getY() + tickableBBox.getH();
                                        lowestNoteY = Math.max(lowestNoteY, tickableBottomY);
                                    }
                                }
                            } catch (error) {
                                console.warn("Error processing tickable:", error);
                            }
                        }
                    });
                }

                return {
                    text: lyric.text,
                    x: noteCenterX - rectWidth / 2,
                    textWidth: textWidth,
                    rectWidth: rectWidth,
                    textHeight: textHeight,
                    rectHeight: textHeight + paddingY * 2,
                    baseY: Math.max(baseY, lowestNoteY + minDistanceFromNote),
                    lowestNoteY: lowestNoteY,
                    index: lyric.index || 0,
                    yPosition: 1,
                };
            })
            .filter((info) => info !== null && info.textWidth > 0);

        context.restore();

        if (lyricsInfo.length === 0) {
            console.warn("No valid lyrics to render");
            return;
        }

        lyricsInfo.sort((a, b) => a.x - b.x);

        const lyricsByLine = {};
        lyricsInfo.forEach((lyric) => {
            if (!lyricsByLine[lyric.index]) {
                lyricsByLine[lyric.index] = [];
            }
            lyricsByLine[lyric.index].push(lyric);
        });

        Object.values(lyricsByLine).forEach((lineLyrics, lineIndex) => {
            lineLyrics.forEach((currentLyric, idx) => {
                if (idx === 0) {
                    currentLyric.yPosition = 1;
                } else {
                    for (let i = idx - 1; i >= 0; i--) {
                        const prevLyric = lineLyrics[i];
                        if (this.isOverlapping(currentLyric, prevLyric)) {
                            currentLyric.yPosition = prevLyric.yPosition === 1 ? 2 : 1;
                        }
                    }
                }

                const dynamicYPositionOptions = {
                    1: 0,
                    2: textHeight + lineSpacing,
                };

                const yOffset = dynamicYPositionOptions[currentLyric.yPosition];
                currentLyric.rectY = currentLyric.baseY + yOffset;
                currentLyric.textY = currentLyric.rectY + currentLyric.rectHeight - paddingY;
            });
        });

        // 가사 텍스트 그리기
        context.save();
        context.setFont("NanumSquare", 13, "");

        lyricsInfo.forEach((lyricInfo) => {
            const textX = lyricInfo.x + paddingX - textLeftOffset;

            // 8방향으로 1px 흰색 텍스트를 그려서 외곽선 효과 생성
            context.setFillStyle("#FFFFFF");
            [
                [-1, -1],
                [0, -1],
                [1, -1],
                [-1, 0],
                [1, 0],
                [-1, 1],
                [0, 1],
                [1, 1],
            ].forEach(([dx, dy]) => {
                context.fillText(lyricInfo.text, textX + dx, lyricInfo.textY + dy);
            });

            // 원래 텍스트 그리기
            context.setFillStyle("#000000");
            context.fillText(lyricInfo.text, textX, lyricInfo.textY);
        });

        context.restore();
    }

    isOverlapping(currentLyric, placedLyric) {
        return !(
            currentLyric.x + currentLyric.rectWidth < placedLyric.x ||
            placedLyric.x + placedLyric.rectWidth < currentLyric.x
        );
    }
}
