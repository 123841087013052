// src/store/XMLParser.js
/* eslint-disable */
import { defineStore } from 'pinia'
import { ScorePartwise } from '@/components/score/Scorescripts/class.js'
import { Renderer } from '@/components/score/Scorescripts/renderer.js'
import { SongInfo } from '@/components/score/Scorescripts/songInfoClass.js'
import { koreanInstrumentNames } from '@/components/score/Scorescripts/koreanInstrumentNames.js'

export const useXMLParserStore = defineStore('xmlParser', {
    state: () => ({
        scorePartwise: null,
        songInfo: null,
        renderer: null,
        selectedParts: [],
        vex: null,
        isPartsLoaded: false,
        totalPages: 0,
        parsedVexflowData: null,
        isLoaded: false,
    }),

    actions: {
        async loadVex() {
            return new Promise((resolve, reject) => {
                if (window.Vex) {
                    this.vex = window.Vex;
                    resolve();
                    return;
                }
                const script = document.createElement('script');
                script.src = "https://unpkg.com/vexflow@4.2.2/build/cjs/vexflow.js";
                script.onload = () => {
                    this.vex = window.Vex;
                    resolve();
                };
                script.onerror = () => reject(new Error("Failed to load Vex Flow script"));
                document.head.append(script);
            });
        },

        async parseXML(xmlString) {
            this.isLoaded = false;
            try {
                if (!this.vex) {
                    await this.loadVex();
                }

                const parser = new DOMParser()
                const xmlDoc = parser.parseFromString(xmlString, "text/xml")

                if (xmlDoc.documentElement.nodeName === "parsererror") {
                    throw new Error("XML 파싱 오류: " + xmlDoc.documentElement.textContent);
                }

                this.scorePartwise = new ScorePartwise(xmlDoc)
                this.renderer = new Renderer(this.scorePartwise)

                // 파트 처리 로직
                if (this.scorePartwise.parts && this.scorePartwise.parts.length > 0) {
                    this.selectedParts = []
                    let partIndex = 0
                    this.scorePartwise.parts.forEach((part, index) => {
                        console.log(`Part ${index + 1}:`, part);
                        const staffCount = this.getStaffCount(part)
                        console.log(`Detected staff count for Part ${index + 1}: ${staffCount}`);
                        for (let i = 0; i < staffCount; i++) {
                            this.selectedParts.push({
                                partIndex: partIndex,
                                staffIndex: i,
                                name: `${part.name} - Staff ${i + 1}`
                            })
                        }
                        partIndex++
                    })
                    if (this.scorePartwise.parts[0].measures) {
                        this.totalPages = Math.ceil(this.scorePartwise.parts[0].measures.length / 4)
                    } else {
                        console.error("첫 번째 파트에서 마디를 찾을 수 없습니다")
                        this.totalPages = 1
                    }
                } else {
                    console.error("악보에서 파트를 찾을 수 없습니다")
                    this.selectedParts = []
                    this.totalPages = 1
                }

                this.isPartsLoaded = true;

                // koreanInstrumentNames 활용
                if (this.scorePartwise.parts) {
                    this.scorePartwise.parts.forEach(part => {
                        if (part.midiInstrument && part.midiInstrument.length > 0) {
                            const midiProgram = parseInt(part.midiInstrument[0].midiProgram);
                            part.koreanInstrumentName = koreanInstrumentNames[midiProgram] || part.name;
                        }
                    });
                }

                this.generateVexflowData();

                

                this.isLoaded = true;

            } catch (error) {
                console.error("XML 파싱 오류:", error)
                throw error
            }
        },

        getStaffCount(part) {
            if (part.measures && part.measures.length > 0) {
                const firstMeasure = part.measures[0];
                if (firstMeasure.attributes && firstMeasure.attributes.staves) {
                    return parseInt(firstMeasure.attributes.staves);
                }
            }
            return 1;
        },

        generateVexflowData() {
            if (!this.scorePartwise || !this.scorePartwise.parts) {
                console.error("ScorePartwise 또는 파트 데이터가 없습니다.");
                return;
            }

            // 클래스 인스턴스를 그대로 저장
            this.parsedVexflowData = this.scorePartwise;
        },

        updateCurrentPage(elapsedTime) {
            const newPage = this.pageStartTimes.findIndex(startTime => startTime > elapsedTime);
            this.currentPage = newPage !== -1 ? newPage : this.pageStartTimes.length;
        },
    },
    getters: {
        getParsedVexflowData: (state) => state.parsedVexflowData,
    }
})
