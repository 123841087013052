import { computed } from 'vue';
import { defineStore } from 'pinia';
import { getDatabase, ref, set, serverTimestamp, get } from 'firebase/database';

export const useNetworkLatencyStore = defineStore('networkLatency', {
    state: () => ({
        averageLatency: 0,
        averageTimeOffset: 0,
        measurements: [],
        timeOffsetMeasurements: [],
        perfTimeOrigin: performance.timeOrigin,
        isInitialized: false,
        isRemeasuring: false,
        measurementPromise: null,
        continuousMeasurementInterval: null,
    }),

    actions: {
        setupLatencyMeasurement() {
            if (this.isInitialized) {
                console.log('Latency measurement already initialized');
                return;
            }

            this.remeasureLatency().then(() => {
                this.startContinuousMeasurement();
                this.setupPageVisibilityListener();
                this.isInitialized = true;
            });
        },

        setupPageVisibilityListener() {
            document.addEventListener('visibilitychange', () => {
                if (document.visibilityState === 'visible') {
                    console.log('Page became visible, remeasuring latency');
                    this.remeasureLatency();
                }
            });
        },

        async measureLatency() {
            try {
                const start = performance.now();
                const response = await fetch('https://timeapi.io/api/Time/current/zone?timeZone=UTC');
                const data = await response.json();
                const end = performance.now();
                const serverTime = new Date(data.dateTime).getTime();
                const latency = (end - start) / 2;
                this.addMeasurement(latency);

                // 클라이언트 시간 계산
                const clientTime = this.perfTimeOrigin + performance.now();
                // timeOffset 계산 및 저장
                const timeOffset = serverTime - (clientTime - latency);
                this.addTimeOffsetMeasurement(timeOffset);
            } catch (error) {
                console.error('Error measuring latency:', error);
            }
        },

        addMeasurement(latency) {
            if (isNaN(latency) || latency <= 0) {
                console.warn('Invalid latency measurement:', latency);
                return;
            }
            this.measurements.push(latency);
            if (this.measurements.length > 10) {
                this.measurements.shift();  // 가장 오래된 측정값 제거
            }
            this.calculateMedianLatency();
        },

        addTimeOffsetMeasurement(timeOffset) {
            if (isNaN(timeOffset)) {
                console.warn('Invalid time offset measurement:', timeOffset);
                return;
            }
            this.timeOffsetMeasurements.push(timeOffset);
            if (this.timeOffsetMeasurements.length > 30) {
                this.timeOffsetMeasurements.shift();  // 가장 오래된 측정값 제거
            }
            this.calculateMedianTimeOffset();
        },

        calculateMedianLatency() {
            if (this.measurements.length === 0) {
                console.warn('No valid measurements to calculate median latency');
                this.averageLatency = 0;
                return;
            }

            const sortedMeasurements = [...this.measurements].sort((a, b) => a - b);
            const mid = Math.floor(sortedMeasurements.length / 2);
            this.averageLatency = sortedMeasurements.length % 2 !== 0
                ? sortedMeasurements[mid]
                : (sortedMeasurements[mid - 1] + sortedMeasurements[mid]) / 2;
        },

        calculateMedianTimeOffset() {
            if (this.timeOffsetMeasurements.length === 0) {
                console.warn('No valid measurements to calculate median time offset');
                this.averageTimeOffset = 0;
                return;
            }

            const sortedOffsets = [...this.timeOffsetMeasurements].sort((a, b) => a - b);
            const mid = Math.floor(sortedOffsets.length / 2);
            this.averageTimeOffset = sortedOffsets.length % 2 !== 0
                ? sortedOffsets[mid]
                : (sortedOffsets[mid - 1] + sortedOffsets[mid]) / 2;
        },

        getCurrentServerTime() {
            return this.perfTimeOrigin + performance.now() + this.averageTimeOffset;
        },

        getAverageLatency() {
            return this.averageLatency;
        },

        getAverageTimeOffset() {
            return this.averageTimeOffset;
        },

        async reportLatency(roomId, userId) {
            const db = getDatabase();
            const latencyRef = ref(db, `rooms/${roomId}/latencies/${userId}`);
            await set(latencyRef, {
                latency: this.averageLatency,
                timeOffset: this.averageTimeOffset,
                timestamp: serverTimestamp()
            });
        },

        async getMaxLatency(roomId) {
            const db = getDatabase();
            const latenciesRef = ref(db, `rooms/${roomId}/latencies`);
            const snapshot = await get(latenciesRef);
            let maxLatency = 0;

            if (snapshot.exists()) {
                const latencies = snapshot.val();
                Object.values(latencies).forEach(data => {
                    if (data.latency > maxLatency) {
                        maxLatency = data.latency;
                    }
                });
            }

            return maxLatency;
        },

        async remeasureLatency() {
            if (this.isRemeasuring) {
                console.log('Latency remeasurement already in progress');
                return;
            }

            this.isRemeasuring = true;
            console.log('Starting latency remeasurement');
            this.measurements = [];
            this.timeOffsetMeasurements = [];

            try {
                for (let i = 0; i < 5; i++) {
                    await this.measureLatency();
                }
                console.log('Latency re-measured:', this.averageLatency);
                console.log('Time offset re-measured:', this.averageTimeOffset);
            } catch (error) {
                console.error('Error during latency remeasurement:', error);
            } finally {
                this.isRemeasuring = false;
            }

            return { latency: this.averageLatency, timeOffset: this.averageTimeOffset };
        },

        startContinuousMeasurement() {
            if (this.continuousMeasurementInterval) {
                clearInterval(this.continuousMeasurementInterval);
            }
            this.continuousMeasurementInterval = setInterval(() => {
                this.measureLatency();
            }, 1000);
        },

        stopContinuousMeasurement() {
            if (this.continuousMeasurementInterval) {
                clearInterval(this.continuousMeasurementInterval);
                this.continuousMeasurementInterval = null;
            }
        }
    },

    getters: {
        latestAverageLatency: (state) => computed(() => state.averageLatency),
        latestAverageTimeOffset: (state) => computed(() => state.averageTimeOffset),
    },
});