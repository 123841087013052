// src/store/themeStore.js
import { defineStore } from 'pinia';
import { ref, watch, computed } from 'vue';
import { useAuthStore } from './auth';

export const useThemeStore = defineStore('theme', () => {
    const authStore = useAuthStore();

    const isDarkMode = ref(true);
    const scoreTheme = ref('invert');

    const lightModeOptions = [
        { label: '라이트', value: 'normal' },
        { label: '다크', value: 'invert' },
        { label: '실내', value: 'darken' },
        { label: '어두운 방', value: 'darkness' }
    ];

    const darkModeOptions = [
        { label: '라이트', value: 'normal' },
        { label: '다크', value: 'invert' },
        { label: '실내', value: 'darken' },
        { label: '어두운 방', value: 'darkness' }
    ];

    const currentFilterOptions = computed(() =>
        isDarkMode.value ? darkModeOptions : lightModeOptions
    );

    const defaultFilter = computed(() =>
        isDarkMode.value ? 'invert' : 'normal'
    );

    const initializeThemes = (userThemes) => {
        if (userThemes) {
            isDarkMode.value = userThemes.isDarkMode;
            scoreTheme.value = userThemes.scoreTheme || defaultFilter.value;
        } else {
            const savedIsDarkMode = localStorage.getItem('isDarkMode');
            const savedScoreTheme = localStorage.getItem('scoreTheme');

            if (savedIsDarkMode !== null) {
                isDarkMode.value = savedIsDarkMode === 'true';
            }
            if (savedScoreTheme) {
                scoreTheme.value = savedScoreTheme;
            } else {
                scoreTheme.value = defaultFilter.value;
            }
        }
    };

    const toggleTheme = () => {
        isDarkMode.value = !isDarkMode.value;
        scoreTheme.value = defaultFilter.value;
    };

    const updateScoreTheme = async (newTheme) => {
        scoreTheme.value = newTheme;
    };

    watch(
        [isDarkMode, scoreTheme],
        ([newIsDarkMode, newScoreTheme]) => {
            if (authStore.isAuthenticated) {
                authStore.updateUserThemes(newIsDarkMode, newScoreTheme);
            } else {
                localStorage.setItem('isDarkMode', newIsDarkMode);
                localStorage.setItem('scoreTheme', newScoreTheme);
            }
            if (newIsDarkMode) {
                document.body.classList.remove('light-mode');
            } else {
                document.body.classList.add('light-mode');
            }
        },
        { immediate: false }
    );

    watch(
        () => authStore.isAuthenticated,
        (isAuthenticated) => {
            if (isAuthenticated) {
                initializeThemes(authStore.userThemes);
            } else {
                initializeThemes(null);
            }
        },
        { immediate: true }
    );

    return {
        isDarkMode,
        scoreTheme,
        toggleTheme,
        initializeThemes,
        updateScoreTheme,
        currentFilterOptions,
        defaultFilter
    };
});