//modules/class.js
/*eslint-disable*/
export {
    Layout, ScorePartwise, Measure, Note, Attributes, Harmony, FiguredBass, Direction, Print, Sound, Barline,
    Credit, CreditImage, CreditWords, Encoding, Identification, Miscellaneous, Defaults, Scaling, PageLayout,
    SystemLayout, SystemMargins, SystemDividers, StaffLayout, Appearance, PartList, PartGroup, ScorePart,
    ScoreInstrument, VirtualInstrument, MidiDevice, MidiInstrument, Part, Backup, Forward, DirectionType, Figure, Repeat,
    Ending, Grouping, Feature, Link, Tied, Slur, Tuplet, Glissando, Slide, Ornaments, Technical, Bend,
    Articulations, Dynamics, Arpeggiate, NonArpeggiate, Rehearsal, Wedge, Metronome, BeatUnitTied,
    OctaveShift, Pedal, MeasureLayout, PartNameDisplay,
    PartAbbreviationDisplay, GenericElement, Key, Time, Clef, StaffDetails, StaffTuning, Transpose,
    MeasureStyle, BeatRepeat, Slash, PageMargins, Font
};


class Layout {
    constructor(layoutSettings) {
        this.pageWidth = layoutSettings.pageWidth || 800;
        this.pageHeight = layoutSettings.pageHeight || 1100;
        this.margins = {
            top: layoutSettings.margins?.top || 50,
            right: layoutSettings.margins?.right || 50,
            bottom: layoutSettings.margins?.bottom || 50,
            left: layoutSettings.margins?.left || 50
        };
        this.staveSpacing = layoutSettings.staveSpacing || 80;
        this.staffUnitSpacing = layoutSettings.staffUnitSpacing || 10;

        // 추가적인 레이아웃 설정이 있다면 여기에 추가
    }

    // 레이아웃 설정을 업데이트하는 메소드
    updateLayout(newSettings) {
        Object.assign(this, newSettings);
        if (newSettings.margins) {
            Object.assign(this.margins, newSettings.margins);
        }
    }

    // 필요한 경우 추가 메소드를 여기에 정의
}

class Font {
    constructor(family, size, weight) {
        this.family = family || 'NanumSquare';
        this.size = size || 10;
        this.weight = weight || 'normal';
    }

    // VexFlow 컨텍스트에 폰트 설정을 적용하는 메서드
    applyToContext(context) {
        context.setFont(this.family, this.size, this.weight);
    }

    // VexFlow 노트에 폰트 설정을 적용하는 메서드
    applyToNote(note) {
        note.setFont({
            family: this.family,
            size: this.size,
            weight: this.weight
        });
    }
}

class ScorePartwise {
    constructor(xmlDoc) {
        this.title = xmlDoc.querySelector('movement-title')?.textContent || xmlDoc.querySelector('work > work-title')?.textContent;
        this.version = xmlDoc.documentElement.getAttribute('version');
        this.identification = new Identification(xmlDoc.querySelector('identification'));
        this.defaults = new Defaults(xmlDoc.querySelector('defaults'));
        this.credits = Array.from(xmlDoc.querySelectorAll('credit')).map(el => new Credit(el));
        this.partList = new PartList(xmlDoc.querySelector('part-list'));
        this.parts = Array.from(xmlDoc.querySelectorAll('part')).map(el => {
            const partId = el.getAttribute('id');
            const partName = this.partList.scoreParts.find(sp => sp.id === partId)?.partName || partId;
            return new Part(el, partName);
        });
        // 레이아웃 정보 추가
        this.layout = {
            pageWidth: this.defaults.pageLayout?.pageWidth || 800,
            pageHeight: this.defaults.pageLayout?.pageHeight || 1100,
            margins: {
                top: this.defaults.pageLayout?.pageMargins[0]?.topMargin || 50,
                right: this.defaults.pageLayout?.pageMargins[0]?.rightMargin || 50,
                bottom: this.defaults.pageLayout?.pageMargins[0]?.bottomMargin || 50,
                left: this.defaults.pageLayout?.pageMargins[0]?.leftMargin || 50
            },

            staveSpacing: this.defaults.staffLayout?.staffDistance || 80,
            staffUnitSpacing: parseFloat(this.defaults.scaling?.tenths || 40) / 4,
        };
    }
}

class Identification {
    constructor(element) {
        if (!element) return;
        this.creators = Array.from(element.querySelectorAll('creator')).map(el => ({
            type: el.getAttribute('type'),
            value: el.textContent
        }));
        this.rights = Array.from(element.querySelectorAll('rights')).map(el => el.textContent);
        this.encoding = new Encoding(element.querySelector('encoding'));
        this.source = element.querySelector('source')?.textContent;
        this.relation = element.querySelector('relation')?.textContent;
        this.miscellaneous = new Miscellaneous(element.querySelector('miscellaneous'));
    }
}

class Encoding {
    constructor(element) {
        if (!element) return;
        this.encodingDate = element.querySelector('encoding-date')?.textContent;
        this.encoders = Array.from(element.querySelectorAll('encoder')).map(el => el.textContent);
        this.software = element.querySelector('software')?.textContent;
        this.encodingDescription = element.querySelector('encoding-description')?.textContent;
        this.supports = Array.from(element.querySelectorAll('supports')).map(el => ({
            element: el.getAttribute('element'),
            type: el.getAttribute('type'),
            value: el.getAttribute('value')
        }));
    }
}

class Miscellaneous {
    constructor(element) {
        if (!element) return;
        this.miscellaneousFields = Array.from(element.querySelectorAll('miscellaneous-field')).map(el => ({
            name: el.getAttribute('name'),
            value: el.textContent
        }));
    }
}

class Defaults {
    constructor(element) {
        if (!element) return;
        this.scaling = new Scaling(element.querySelector('scaling'));
        this.pageLayout = new PageLayout(element.querySelector('page-layout'));
        this.systemLayout = new SystemLayout(element.querySelector('system-layout'));
        this.staffLayout = new StaffLayout(element.querySelector('staff-layout'));
        this.appearance = new Appearance(element.querySelector('appearance'));
        this.musicFont = this.parseFont(element.querySelector('music-font'));
        this.wordFont = this.parseFont(element.querySelector('word-font'));
        this.lyricFont = this.parseFont(element.querySelector('lyric-font'));
    }

    parseFont(element) {
        if (!element) return null;
        return {
            fontFamily: element.getAttribute('font-family'),
            fontSize: element.getAttribute('font-size')
        };
    }
}

class Scaling {
    constructor(element) {
        if (!element) return;
        this.millimeters = element.querySelector('millimeters')?.textContent;
        this.tenths = element.querySelector('tenths')?.textContent;
    }
}

class PageLayout {
    constructor(element) {
        if (!element) return;
        this.pageHeight = element.querySelector('page-height')?.textContent;
        this.pageWidth = element.querySelector('page-width')?.textContent;
        this.pageMargins = Array.from(element.querySelectorAll('page-margins')).map(el => new PageMargins(el));
    }
}

class PageMargins {
    constructor(element) {
        if (!element) return;
        this.type = element.getAttribute('type');
        this.leftMargin = element.querySelector('left-margin')?.textContent;
        this.rightMargin = element.querySelector('right-margin')?.textContent;
        this.topMargin = element.querySelector('top-margin')?.textContent;
        this.bottomMargin = element.querySelector('bottom-margin')?.textContent;
    }
}

class SystemLayout {
    constructor(element) {
        if (!element) return;
        this.systemMargins = new SystemMargins(element.querySelector('system-margins'));
        this.systemDistance = element.querySelector('system-distance')?.textContent;
        this.topSystemDistance = element.querySelector('top-system-distance')?.textContent;
        this.systemDividers = new SystemDividers(element.querySelector('system-dividers'));
    }
}

class SystemMargins {
    constructor(element) {
        if (!element) return;
        this.leftMargin = element.querySelector('left-margin')?.textContent;
        this.rightMargin = element.querySelector('right-margin')?.textContent;
    }
}

class SystemDividers {
    constructor(element) {
        if (!element) return;
        this.leftDivider = new Divider(element.querySelector('left-divider'));
        this.rightDivider = new Divider(element.querySelector('right-divider'));
    }
}

class Divider {
    constructor(element) {
        if (!element) return;
        this.sign = element.querySelector('sign')?.textContent;
        this.line = element.querySelector('line')?.textContent;
    }
}

class StaffLayout {
    constructor(element) {
        if (!element) return;
        this.staffDistance = element.querySelector('staff-distance')?.textContent;
    }
}

class Appearance {
    constructor(element) {
        if (!element) return;
        this.lineWidth = this.parseLineWidth(element);
        this.noteSize = this.parseNoteSize(element);
        this.distance = this.parseDistance(element);
        this.otherAppearance = this.parseOtherAppearance(element);
    }

    parseLineWidth(element) {
        return Array.from(element.querySelectorAll('line-width')).map(el => ({
            type: el.getAttribute('type'),
            value: el.textContent
        }));
    }

    parseNoteSize(element) {
        return Array.from(element.querySelectorAll('note-size')).map(el => ({
            type: el.getAttribute('type'),
            value: el.textContent
        }));
    }

    parseDistance(element) {
        return Array.from(element.querySelectorAll('distance')).map(el => ({
            type: el.getAttribute('type'),
            value: el.textContent
        }));
    }

    parseOtherAppearance(element) {
        return Array.from(element.querySelectorAll('other-appearance')).map(el => ({
            type: el.getAttribute('type'),
            value: el.textContent
        }));
    }
}

class Credit {
    constructor(element) {
        if (!element) return;
        this.page = element.getAttribute('page');
        this.creditImage = new CreditImage(element.querySelector('credit-image'));
        this.creditWords = Array.from(element.querySelectorAll('credit-words')).map(el => new CreditWords(el));
    }
}

class CreditImage {
    constructor(element) {
        if (!element) return;
        this.source = element.getAttribute('source');
        this.type = element.getAttribute('type');
        this.defaultX = element.getAttribute('default-x');
        this.defaultY = element.getAttribute('default-y');
    }
}

class CreditWords {
    constructor(element) {
        if (!element) return;
        this.defaultX = element.getAttribute('default-x');
        this.defaultY = element.getAttribute('default-y');
        this.justify = element.getAttribute('justify');
        this.valign = element.getAttribute('valign');
        this.fontFamily = element.getAttribute('font-family');
        this.fontSize = element.getAttribute('font-size');
        this.fontStyle = element.getAttribute('font-style');
        this.fontWeight = element.getAttribute('font-weight');
        this.value = element.textContent;
    }
}

class PartList {
    constructor(element) {
        if (!element) return;
        this.partGroups = Array.from(element.querySelectorAll('part-group')).map(el => new PartGroup(el));
        this.scoreParts = Array.from(element.querySelectorAll('score-part')).map(el => new ScorePart(el));
    }
}

class PartGroup {
    constructor(element) {
        if (!element) return;
        this.number = element.getAttribute('number');
        this.type = element.getAttribute('type');
        this.groupName = element.querySelector('group-name')?.textContent;
        this.groupAbbreviation = element.querySelector('group-abbreviation')?.textContent;
        this.groupSymbol = element.querySelector('group-symbol')?.textContent;
        this.groupBarline = element.querySelector('group-barline')?.textContent;
    }
}

class ScorePart {
    constructor(element) {
        if (!element) return;
        this.id = element.getAttribute('id');
        this.identification = new Identification(element.querySelector('identification'));
        this.partName = element.querySelector('part-name')?.textContent;
        this.partAbbreviation = element.querySelector('part-abbreviation')?.textContent;
        this.scoreInstrument = Array.from(element.querySelectorAll('score-instrument')).map(el => new ScoreInstrument(el));
        this.midiDevice = Array.from(element.querySelectorAll('midi-device')).map(el => new MidiDevice(el));
        this.midiInstrument = Array.from(element.querySelectorAll('midi-instrument')).map(el => new MidiInstrument(el));
    }
}

class ScoreInstrument {
    constructor(element) {
        if (!element) return;
        this.id = element.getAttribute('id');
        this.instrumentName = element.querySelector('instrument-name')?.textContent;
        this.instrumentAbbreviation = element.querySelector('instrument-abbreviation')?.textContent;
        this.instrumentSound = element.querySelector('instrument-sound')?.textContent;
        this.ensemble = element.querySelector('ensemble')?.textContent;
        this.virtualInstrument = new VirtualInstrument(element.querySelector('virtual-instrument'));
    }
}

class VirtualInstrument {
    constructor(element) {
        if (!element) return;
        this.virtualLibrary = element.querySelector('virtual-library')?.textContent;
        this.virtualName = element.querySelector('virtual-name')?.textContent;
    }
}

class MidiDevice {
    constructor(element) {
        if (!element) return;
        this.port = element.getAttribute('port');
        this.id = element.getAttribute('id');
        this.value = element.textContent;
    }
}

class MidiInstrument {
    constructor(element) {
        if (!element) return;
        this.id = element.getAttribute('id');
        this.midiChannel = element.querySelector('midi-channel')?.textContent;
        this.midiProgram = element.querySelector('midi-program')?.textContent;
        this.midiUnpitched = element.querySelector('midi-unpitched')?.textContent;
        this.volumeValue = element.querySelector('volume')?.textContent;
        this.panValue = element.querySelector('pan')?.textContent;
        this.elevation = element.querySelector('elevation')?.textContent;
    }
}

class Part {
    constructor(element, partName) {
        this.id = element.getAttribute('id');
        this.name = partName;
        this.measures = [];
        this.latestAttributes = {};
        this.parseMeasures(element);
    }

    parseMeasures(element) {
        Array.from(element.querySelectorAll('measure')).forEach(measureElement => {
            const measure = new Measure(measureElement, this.latestAttributes);
            this.measures.push(measure);
            // clef 정보를 명시적으로 포함하여 업데이트
            this.latestAttributes = {
                ...this.latestAttributes,
                ...measure.attributes,
                clef: measure.attributes.clef || this.latestAttributes.clef
            };
        });
    }
}

class Measure {
    constructor(element, previousAttributes = {}) {
        this.number = element.getAttribute('number');
        this.width = element.getAttribute('width');
        this.attributes = new Attributes(null, previousAttributes);
        this.elements = [];
        this.parseElements(element);
    }

    parseElements(element) {
        let currentClef = this.attributes.clef ? this.attributes.clef[0] : null;

        Array.from(element.children).forEach(child => {
            switch (child.nodeName) {
                case 'attributes':
                    const newAttributes = new Attributes(child, this.attributes);
                    this.attributes = newAttributes; // 이전 속성을 업데이트
                    if (newAttributes.clef && newAttributes.clef.length > 0) {
                        currentClef = newAttributes.clef[0];
                    }
                    this.elements.push(newAttributes);
                    break;
                case 'note':
                    const note = new Note(child, this.attributes, currentClef);
                    this.elements.push(note);
                    break;
                case 'backup':
                    this.elements.push(new Backup(child));
                    break;
                case 'forward':
                    this.elements.push(new Forward(child));
                    break;
                case 'direction':
                    this.elements.push(new Direction(child));
                    break;
                case 'harmony':
                    this.elements.push(new Harmony(child));
                    break;
                case 'figured-bass':
                    this.elements.push(new FiguredBass(child));
                    break;
                case 'print':
                    this.elements.push(new Print(child));
                    break;
                case 'sound':
                    this.elements.push(new Sound(child));
                    break;
                case 'barline':
                    this.elements.push(new Barline(child));
                    break;
                case 'grouping':
                    this.elements.push(new Grouping(child));
                    break;
                case 'link':
                    this.elements.push(new Link(child));
                    break;
                case 'bookmark':
                    this.elements.push(new Bookmark(child));
                    break;
                default:
                    console.warn(`Unhandled element type in Measure: ${child.nodeName}`);
                    this.elements.push(new GenericElement(child));
            }
        });

        this.processChords();
    }

    processChords() {
        const newElements = [];
        let currentChord = null;

        this.elements.forEach(element => {
            if (element instanceof Note) {
                if (element.isChordTone) {
                    if (currentChord) {
                        this.mergeChordNote(currentChord, element);
                    } else {
                        console.warn('Chord tone found without a main note');
                        newElements.push(element);
                    }
                } else {
                    if (currentChord) {
                        newElements.push(currentChord);
                        currentChord = null;
                    }
                    currentChord = element;
                }
            } else {
                if (currentChord) {
                    newElements.push(currentChord);
                    currentChord = null;
                }
                newElements.push(element);
            }
        });

        if (currentChord) {
            newElements.push(currentChord);
        }

        this.elements = newElements;
    }

    mergeChordNote(mainNote, chordNote) {
        if (!mainNote.pitches) {
            mainNote.pitches = [mainNote.pitch];
            delete mainNote.pitch;
        }
        mainNote.pitches.push(...chordNote.pitches);

        if (chordNote.tabPositions && chordNote.tabPositions.length > 0) {
            if (!mainNote.tabPositions) {
                mainNote.tabPositions = [];
            }
            mainNote.tabPositions = mainNote.tabPositions.concat(chordNote.tabPositions);
        }

        if (chordNote.pitchesDisplay && chordNote.pitchesDisplay.length > 0) {
            if (!mainNote.pitchesDisplay) {
                mainNote.pitchesDisplay = [];
            }
            mainNote.pitchesDisplay = mainNote.pitchesDisplay.concat(chordNote.pitchesDisplay);
        }

        if (chordNote.notations) {
            if (!mainNote.notations) {
                mainNote.notations = {};
            }
            this.mergeNotations(mainNote.notations, chordNote.notations);
        }

        mainNote.vexflowKeys.push(chordNote.getVexFlowKeyText());

        this.mergeProperty(mainNote, chordNote, 'grace');
        this.mergeProperty(mainNote, chordNote, 'tie');
        this.mergeProperty(mainNote, chordNote, 'timeModification');
        this.mergeProperty(mainNote, chordNote, 'stem');
        this.mergeProperty(mainNote, chordNote, 'notehead');
        this.mergeProperty(mainNote, chordNote, 'staff');
        this.mergeProperty(mainNote, chordNote, 'beam');
        this.mergeProperty(mainNote, chordNote, 'lyric');

        mainNote.chord = true;
    }

    mergeNotations(mainNotations, chordNotations) {
        for (let key in chordNotations) {
            if (chordNotations.hasOwnProperty(key)) {
                if (!mainNotations[key]) {
                    mainNotations[key] = chordNotations[key];
                } else if (Array.isArray(mainNotations[key])) {
                    mainNotations[key] = mainNotations[key].concat(chordNotations[key]);
                } else if (typeof mainNotations[key] === 'object') {
                    this.mergeNotations(mainNotations[key], chordNotations[key]);
                }
            }
        }
    }

    mergeProperty(mainNote, chordNote, property) {
        if (property === 'grace') {
            if (mainNote.grace || chordNote.grace) {
                mainNote.grace = mainNote.grace || chordNote.grace;
            }
        } else {
            if (chordNote[property] !== undefined) {
                if (mainNote[property] === undefined) {
                    mainNote[property] = chordNote[property];
                } else if (Array.isArray(mainNote[property])) {
                    mainNote[property] = mainNote[property].concat(chordNote[property]);
                }
            }
        }
    }

}

class Attributes {
    constructor(element, previousAttributes) {
        // 이전 attributes의 모든 속성을 먼저 복사
        if (previousAttributes) {
            Object.assign(this, previousAttributes);
        }

        if (!element) return;

        // 새로운 element에서 각 속성을 확인하고 업데이트
        if (element.querySelector('divisions')) {
            this.divisions = element.querySelector('divisions').textContent;
        }

        if (element.querySelector('key')) {
            this.key = new Key(element.querySelector('key'));
        }

        if (element.querySelector('time')) {
            this.time = new Time(element.querySelector('time'));
        }

        if (element.querySelector('staves')) {
            this.staves = element.querySelector('staves').textContent;
        }

        if (element.querySelector('instruments')) {
            this.instruments = element.querySelector('instruments').textContent;
        }

        const clefElements = element.querySelectorAll('clef');
        if (clefElements.length > 0) {
            this.clef = Array.from(clefElements).map(el => new Clef(el));
        }

        const staffDetailsElements = element.querySelectorAll('staff-details');
        if (staffDetailsElements.length > 0) {
            this.staffDetails = Array.from(staffDetailsElements).map(el => new StaffDetails(el));
        }

        if (element.querySelector('transpose')) {
            this.transpose = new Transpose(element.querySelector('transpose'));
        }

        const directiveElements = element.querySelectorAll('directive');
        if (directiveElements.length > 0) {
            this.directives = Array.from(directiveElements).map(el => el.textContent);
        }

        if (element.querySelector('measure-style')) {
            this.measureStyle = new MeasureStyle(element.querySelector('measure-style'));
        }
    }

    getClef(staffNumber = 1) {
        const clef = this.clef.find(c => c.number === staffNumber.toString());
        return clef ? clef.sign : 'G';  // 기본값으로 'G' (높은음자리표) 반환
    }
}


class Key {
    constructor(element) {
        if (!element) return;
        this.fifths = element.querySelector('fifths')?.textContent;
        this.mode = element.querySelector('mode')?.textContent;
        this.cancel = element.querySelector('cancel')?.textContent;
    }

    getVexFlowKeySignature() {
        const keyMap = {
            '-7': 'Cb',
            '-6': 'Gb',
            '-5': 'Db',
            '-4': 'Ab',
            '-3': 'Eb',
            '-2': 'Bb',
            '-1': 'F',
            '0': 'C',
            '1': 'G',
            '2': 'D',
            '3': 'A',
            '4': 'E',
            '5': 'B',
            '6': 'F#',
            '7': 'C#'
        };

        // fifths가 undefined인 경우 처리
        if (this.fifths === undefined) {
            console.warn('Key signature is undefined, defaulting to C');
            return 'C';
        }

        // fifths가 문자열이므로 정수로 변환
        const fifths = parseInt(this.fifths);

        if (isNaN(fifths) || fifths < -7 || fifths > 7) {
            console.warn(`Invalid key signature: ${this.fifths}, defaulting to C`);
            return 'C';
        }

        let key = keyMap[fifths];

        if (this.mode && this.mode.toLowerCase() === 'minor') {
            key += 'm';
        }

        return key;
    }
}

class Time {
    constructor(element) {
        if (!element) return;
        this.beats = element.querySelector('beats')?.textContent;
        this.beatType = element.querySelector('beat-type')?.textContent;
        this.senzaMisura = element.querySelector('senza-misura')?.textContent;
    }
}

class Clef {
    constructor(element) {
        if (!element) return;
        this.number = element.getAttribute('number') || '1';
        this.sign = element.querySelector('sign')?.textContent;
        this.line = element.querySelector('line')?.textContent;
        this.clefOctaveChange = element.querySelector('clef-octave-change')?.textContent;
    }

    getVexFlowClef() {
        const clefMap = {
            'G': 'treble',
            'F': 'bass',
            'C': 'alto',
            'percussion': 'percussion',
            'TAB': 'tab',
            'none': 'none',
            'jianpu': 'jianpu',
            'french': 'french',
        };

        let clefType = clefMap[this.sign] || 'treble';
        let annotation = null;

        if (this.clefOctaveChange) {
            const octaveChange = parseInt(this.clefOctaveChange);
            if (octaveChange === -1) annotation = '8vb';
            else if (octaveChange === 1) annotation = '8va';
            else if (octaveChange === -2) annotation = '15mb';
            else if (octaveChange === 2) annotation = '15ma';
        }

        return clefType;
    }
}

class StaffDetails {
    constructor(element) {
        if (!element) return;
        this.staffLines = element.querySelector('staff-lines')?.textContent;
        this.staffTuning = Array.from(element.querySelectorAll('staff-tuning')).map(el => new StaffTuning(el));
        this.staffType = element.querySelector('staff-type')?.textContent;
    }
}

class StaffTuning {
    constructor(element) {
        if (!element) return;
        this.tuningStep = element.querySelector('tuning-step')?.textContent;
        this.tuningAlter = element.querySelector('tuning-alter')?.textContent;
        this.tuningOctave = element.querySelector('tuning-octave')?.textContent;
    }
}

class Transpose {
    constructor(element) {
        if (!element) return;
        this.diatonic = element.querySelector('diatonic')?.textContent;
        this.chromatic = element.querySelector('chromatic')?.textContent;
        this.octaveChange = element.querySelector('octave-change')?.textContent;
        this.double = element.querySelector('double')?.textContent;
    }
}

class MeasureStyle {
    constructor(element) {
        if (!element) return;
        this.multipleRest = element.querySelector('multiple-rest')?.textContent;
        this.measureRepeat = element.querySelector('measure-repeat')?.textContent;
        this.beatRepeat = new BeatRepeat(element.querySelector('beat-repeat'));
        this.slash = new Slash(element.querySelector('slash'));
    }
}

class BeatRepeat {
    constructor(element) {
        if (!element) return;
        this.slashes = element.getAttribute('slashes');
        this.useSymbols = element.getAttribute('use-symbols');
        this.type = element.getAttribute('type');
    }
}

class Slash {
    constructor(element) {
        if (!element) return;
        this.type = element.getAttribute('type');
        this.useVoice = element.getAttribute('use-voice');
        this.useSymbols = element.getAttribute('use-symbols');
    }
}

class Note {
    constructor(element, attributes) {
        if (!element) return;
        this.attributes = attributes;
        this.element = element;
        this.defaultX = element.getAttribute('default-x');
        this.defaultY = element.getAttribute('default-y');
        this.relativeX = element.getAttribute('relative-x');
        this.relativeY = element.getAttribute('relative-y');
        this.fontFamily = element.getAttribute('font-family');
        this.fontSize = element.getAttribute('font-size');
        this.fontStyle = element.getAttribute('font-style');
        this.fontWeight = element.getAttribute('font-weight');
        this.color = element.getAttribute('color');

        this.printObject = element.getAttribute('print-object') === 'no' ? false : true;
        this.printSpacing = element.getAttribute('print-spacing') === 'no' ? false : true;

        this.grace = element.querySelector('grace') ? new Note.Grace(element.querySelector('grace')) : null;
        this.isChordTone = element.querySelector('chord') !== null;

        this.noteType;

        this.isTransparent = false;

        if (element.querySelector('pitch')) {
            this.pitch = new Note.Pitch(element.querySelector('pitch'));
            this.noteType = 'pitch';
        } else if (element.querySelector('rest')) {
            this.rest = new Note.Rest(element.querySelector('rest'));
            this.noteType = 'rest';
        } else if (element.querySelector('unpitched')) {
            this.unpitched = new Note.Unpitched(element.querySelector('unpitched'));
            this.noteType = 'unpitched';
        }

        if (this.notations && this.notations.technical) {
            this.technical = this.notations.technical;
            if (this.technical.bend) {
                this.bend = this.technical.bend;
            }
        }

        this.duration = element.querySelector('duration')?.textContent;
        this.ties = Array.from(element.querySelectorAll('tie')).map(el => new Note.Tie(el));
        this.instrument = element.querySelector('instrument') ? new Note.Instrument(element.querySelector('instrument')) : null;
        this.voice = element.querySelector('voice')?.textContent;
        this.type = element.querySelector('type')?.textContent;
        this.dots = element.querySelectorAll('dot').length;
        this.accidental = element.querySelector('accidental')?.textContent;
        this.timeModification = element.querySelector('time-modification') ? new Note.TimeModification(element.querySelector('time-modification')) : null;
        this.stem = element.querySelector('stem')?.textContent;
        this.notehead = element.querySelector('notehead')
            ? new Note.Notehead(element.querySelector('notehead'))
            : null;
        const noteheadElement = element.querySelector('notehead');
        if (noteheadElement) {
            this.notehead = {
                type: noteheadElement.textContent,
                filled: noteheadElement.getAttribute('filled')
            };
        }
        this.staff = element.querySelector('staff')?.textContent;
        this.beams = Array.from(element.querySelectorAll('beam')).map(el => new Note.Beam(el));
        this.notations = element.querySelector('notations') ? new Note.Notations(element.querySelector('notations')) : null;
        this.lyrics = Array.from(element.querySelectorAll('lyric')).map(el => new Note.Lyric(el));

        this.pitches = [];
        this.tabPositions = [];
        if (this.notations && this.notations.technical) {
            const string = parseInt(this.notations.technical.string);
            const fret = parseInt(this.notations.technical.fret);
            if (!isNaN(string) && !isNaN(fret)) {
                this.tabPositions.push({ str: string, fret: fret });
            }
        }

        this.pitchesDisplay = [];

        this.vexflowKeys = [this.getVexFlowKeyText()];

        if (this.isChordTone) {
            const pitchElements = element.querySelectorAll('pitch');
            const unpitchedElements = element.querySelectorAll('unpitched');

            this.pitches = Array.from(pitchElements).map(pitchEl => new Note.Pitch(pitchEl));
            this.pitchesDisplay = Array.from(unpitchedElements).map(unpitchedEl => new Note.Unpitched(unpitchedEl));

            // Extract string and fret information for tab notation
            if (this.notations && this.notations.technical) {
                this.string = this.notations.technical.string;
                this.fret = this.notations.technical.fret;
                if (this.string && this.fret) {
                    this.tabPositions.push({ str: parseInt(this.string), fret: parseInt(this.fret) });
                }
            }
        } else if (this.pitch) {
            this.pitches = [this.pitch];
            // Extract string and fret information for single notes as well
            if (this.notations && this.notations.technical) {
                this.string = this.notations.technical.string;
                this.fret = this.notations.technical.fret;
                if (this.string && this.fret) {
                    this.tabPositions.push({ str: parseInt(this.string), fret: parseInt(this.fret) });
                }
            }
        } else if (this.unpitched) {
            this.pitchesDisplay = [this.unpitched];
        }

        // Parse tab-specific information
        if (this.notations && this.notations.technical) {
            this.string = this.notations.technical.string;
            this.fret = this.notations.technical.fret;
        }
    }
    /**
     * 기술적 기호를 적용하는 메서드
     * @param {Vex.Flow.StaveNote | Vex.Flow.TabNote} vexNote - VexFlow 노트 객체
     * @param {boolean} isTabStave - 현재 스태프가 TAB 스태프인지 여부
     */
    applyTechnical(vexNote, isTabStave) {
        if (isTabStave && this.notations && this.notations.technical) {
            if (this.notations.technical.bend) {
                const bendAmount = parseFloat(this.notations.technical.bend.bendAlter) || 0;
                const bendText = this.notations.technical.bend.withBar || '';
                const vexBend = new Vex.Flow.Bend(bendText, bendAmount);
                vexNote.addModifier(vexBend, 0);
            }
            // 필요한 경우 다른 기술적 기호도 처리
        }
        // 추가적인 기술적 기호 적용 로직 필요 시 여기에 추가
    }

    getVexFlowStaveNote(factory) {
        const duration = this.getVexFlowDuration();
        let noteData = { duration };

        // 클레프 정보 가져오기
        noteData.voice = this.voice || '1';
        const staffNumber = parseInt(this.staff) || 1;
        const clef = this.attributes.clef ? this.attributes.clef[staffNumber - 1] : null;
        noteData.clef = clef ? clef.getVexFlowClef() : 'treble';

        if (this.rest) {
            // 쉼표의 경우 항상 높은음자리표 위치 사용
            const restPosition = this.getRestPosition();
            noteData.keys = [restPosition];
            noteData.clef = 'treble';  // 쉼표는 항상 높은음자리표 위치 사용

            if (this.rest.measure) {
                return factory.StaveNote({
                    keys: [restPosition],
                    duration: 'wr',
                    align_center: true
                });
            }
        } else {
            noteData.keys = this.getVexFlowKeys();
        }

        if (this.stem) {
            noteData.stem_direction = this.stem === 'up' ? 1 : -1;
        }

        if (this.grace) {
            noteData.slash = this.grace.slash === 'yes';
        }

        const staveNote = factory.StaveNote(noteData);

        this.applyAccidental(staveNote, factory);
        this.applyDots(staveNote, factory);
        this.applyArticulations(staveNote, factory);
        this.applyOrnaments(staveNote, factory);
        this.applyColor(staveNote);

        return staveNote;
    }

    getRestPosition() {
        const restPositions = {
            'whole': 'b/4',
            'half': 'b/4',
            'quarter': 'b/4',
            'eighth': 'b/4',
            '16th': 'b/4',
            '32nd': 'b/4',
            '64th': 'b/4'
        };

        return restPositions[this.type] || 'b/4';
    }

    getVexFlowKeys() {
        const staffNumber = parseInt(this.staff) || 1;

        const clef = this.attributes.clef ? this.attributes.clef[staffNumber - 1] : null;

        const clefOctaveChange = clef && clef.clefOctaveChange ? parseInt(clef.clefOctaveChange) : 0;

        if (this.unpitched) {
            const step = this.unpitched.displayStep || 'C';
            let octave = parseInt(this.unpitched.displayOctave || '4');

            octave -= clefOctaveChange;

            return [`${step}/${octave}`];
        } else {
            if (this.rest) {
                return [this.getRestPosition()];
            }
            return this.pitches.map(pitch => {
                let octave = parseInt(pitch.octave);
                const step = pitch.step.toLowerCase();
                let alter = '';
                if (pitch.alter) {
                    alter = parseFloat(pitch.alter) > 0 ? '#'.repeat(Math.floor(parseFloat(pitch.alter)))
                        : 'b'.repeat(Math.abs(Math.floor(parseFloat(pitch.alter))));
                }

                octave -= clefOctaveChange;

                return `${step}${alter}/${octave}`;
            });
        }
    }




    getVexFlowDuration() {
        const durationMap = {
            'maxima': 'w', 'long': 'w', 'breve': 'w', 'whole': 'w',
            'half': 'h', 'quarter': 'q', 'eighth': '8',
            '16th': '16', '32nd': '32', '64th': '64',
            '128th': '128', '256th': '256'
        };

        let duration = durationMap[this.type] || '8';

        if (this.rest) {
            duration = duration === 'w' ? 'wr' : duration + 'r';
        }

        if (this.dots > 0) {
            duration += 'd'.repeat(this.dots);
        }
        return duration;
    }

    setTransparent(isTransparent) {
        this.isTransparent = isTransparent;
        return this;
    }

    getTabPositions() {
        if (this.tabPositions.length > 0) {
            return this.tabPositions;
        }
        // If there is no pitch information (e.g., rest), return an empty array
        if (!this.pitch && !this.unpitched) {
            return [];
        }
        // Ensure 'string' and 'fret' are defined before using them
        if (this.notations && this.notations.technical) {
            const string = parseInt(this.notations.technical.string);
            const fret = parseInt(this.notations.technical.fret);
            if (!isNaN(string) && !isNaN(fret)) {
                return [{ str: string, fret: fret }];
            }
        }
        return [];
    }

    // class.js 파일의 Note 클래스에서 getVexFlowTabNote 메서드 수정
    getVexFlowTabNote(factory) {
        const positions = this.getTabPositions();
        if (positions.length === 0) {
            // GhostNote 반환
            return factory.GhostNote({
                duration: this.getVexFlowDuration()
            });
        }
        const tabNoteStruct = {
            positions: positions,
            duration: this.getVexFlowDuration()
        };

        // stem_direction 설정
        if (this.stem) {
            tabNoteStruct.stem_direction = this.stem === 'up' ? 1 : -1;
        }

        const tabNote = factory.TabNote(tabNoteStruct);

        // TAB 노트에 시스템 폰트 적용
        tabNote.setFont({
            family: '', // 빈 문자열로 설정하여 시스템 기본 폰트 사용
            size: 10,
            weight: ''
        });

        // 폰트 메트릭 재계산
        tabNote.preFormat();

        return tabNote;
    }





    applyAccidental(staveNote, factory) {
        if (this.accidental) {
            const accidentalMap = {
                'sharp': '#', 'flat': 'b', 'natural': 'n',
                'double-sharp': '##', 'double-flat': 'bb',
                'sharp-sharp': '##', 'flat-flat': 'bb',
                'natural-sharp': '#', 'natural-flat': 'b',
                'quarter-flat': 'db', 'quarter-sharp': 'd#',
                'three-quarters-flat': 'dd', 'three-quarters-sharp': '+'
            };
            const vexAccidental = accidentalMap[this.accidental] || this.accidental;
            staveNote.addModifier(factory.Accidental({ type: vexAccidental }));
        }
    }
    getVexFlowPercussionNote(factory) {
        const duration = this.getVexFlowDuration();
        const vexNotehead = this.getVexFlowNoteheadString();

        const noteData = {
            duration: duration,
            keys: this.vexflowKeys,
            type: this.rest ? 'r' : 'n',
        };

        const staveNote = factory.StaveNote(noteData);

        if (this.stem) {
            staveNote.setStemDirection(this.stem === 'up' ? 1 : -1);
        }

        if (this.notehead) {

        }

        this.applyArticulations(staveNote, factory);
        this.applyColor(staveNote);
        this.applyDots(staveNote, factory);

        return staveNote;
    }

    getPercussionKey() {
        if (this.unpitched) {
            return `${this.unpitched.displayStep.toLowerCase()}/${this.unpitched.displayOctave}`;
        }
        return 'b/4'; // Default position if unpitched is not specified
    }

    getVexFlowKeyText() {
        let step, octave, alter, noteheadType;

        if (this.pitch) {
            // Pitched note
            step = this.pitch.step.toLowerCase();
            octave = this.pitch.octave;
            alter = this.pitch.alter ? parseFloat(this.pitch.alter) : 0;
        } else if (this.unpitched || (this.pitchesDisplay && this.pitchesDisplay.length > 0)) {
            // Unpitched note
            const unpitched = this.unpitched || this.pitchesDisplay[0];
            step = unpitched.displayStep.toLowerCase();
            octave = unpitched.displayOctave;
            alter = 0; // Unpitched notes don't have alter
        } else {
            // Default if neither pitched nor unpitched
            return 'b/4/default';
        }

        // Apply alter to step
        if (alter !== 0) {
            const alterSymbol = alter > 0 ? '#'.repeat(Math.floor(alter)) : 'b'.repeat(Math.abs(Math.floor(alter)));
            step += alterSymbol;
        }

        // Get notehead type
        if (this.notehead) {
            noteheadType = this.getVexFlowNoteheadString();
        } else {
            noteheadType = 'default';
        }

        return `${step}/${octave}/${noteheadType}`;
    }

    getVexFlowNoteheadString() {
        if (!this.notehead) {
            return 'default'; // 기본값
        }

        const noteheadMap = {
            // Standard noteheads
            'normal': 'default',
            'diamond': 'D0',
            'x': 'X2',
            'triangle': 'T0',
            'slash': 'slash',
            'circle-x': 'circle_x',
            'do': 'DO',
            're': 'RE',
            'mi': 'MI',
            'fa': 'FA',
            'fa up': 'FAUP',
            'so': 'SO',
            'la': 'LA',
            'ti': 'TI',

            // Alternate noteheads
            'cluster': 'cluster',
            'inverted triangle': 'T4',
            'arrow down': 'triangle_down',
            'arrow up': 'triangle_up',
            'slashed': 'slash',
            'back slashed': 'backslash',
            'normal-harmonic': 'harmonic',
            'diamond-harmonic': 'diamond',
            'shape-note do': 'D0',
            'shape-note re': 'RE',
            'shape-note mi': 'MI',
            'shape-note fa': 'FA',
            'shape-note so': 'SO',
            'shape-note la': 'LA',
            'shape-note ti': 'TI',

            // Additional noteheads
            'square': 'S1',
            'rectangle': 'S2',
            'diamond old': 'D0',
            'triangle right': 'T1',
            'moon': 'D3',
            'isosceles triangle': 'T2',
            'semicircle': 'S0',
            'plus sign': 'harmonic',
            'x-diamond': 'D2',
            'large x': 'X0',
            'null': 'none',
            'circle dot': 'circle_dot',
            'left triangle': 'T3',
            'large arrow up': 'triangle_up',
            'large arrow down': 'triangle_down',
            'slashed forward': 'slash',
            'slashed backward': 'backslash',
            'cross': 'X1',
            'x and diamond': 'D2',
            'large plus sign': 'circle_dot',
            'parenthesis': 'parentheses',
            'circle': 'circle',
            'invisible': 'hidden',
            'circled x': 'circle_x',
            'inverted triangle and slash': 'T3',
            'sharp and x': 'X2',
            'circle with vertical line': 'circle_dot',
            'muted slash': 'slash',
            'round x': 'circle_x',
            'diamond and x': 'D2',

            // Percussion noteheads
            'percussion-single line': 'slash',
            'percussion-3 lines': 'X2',
            'percussion-4 lines': 'X2',
            'percussion-5 lines': 'X2',
            'percussion-action': 'circle_x',
            'percussion-dead': 'X2',
            'percussion-ghost': 'parentheses',

            // Extended techniques
            'rim shot': 'circle_x',
            'dead note': 'X2',
            'half-harmonic': 'D3',
            'muted': 'X2',
            'open': 'circle',
            'closed': 'X2'
        };

        return noteheadMap[this.notehead.type] || 'default';
    }

    applyDots(staveNote) {
        if (this.dots > 0) {
            for (let i = 0; i < this.dots; i++) {
                Vex.Flow.Dot.buildAndAttach([staveNote], { all: true });
            }
        }
    }

    applyArticulations(staveNote, factory) {
        if (this.notations && this.notations.articulations) {
            const vexArticulations = this.notations.articulations.getVexFlowArticulations();
            vexArticulations.forEach(artType => {
                try {
                    let position = Vex.Flow.Modifier.Position.ABOVE;
                    if (artType === 'a.') {  // 스타카토
                        position = staveNote.getStemDirection() === Vex.Flow.Stem.UP
                            ? Vex.Flow.Modifier.Position.BELOW
                            : Vex.Flow.Modifier.Position.ABOVE;
                    }
                    staveNote.addModifier(factory.Articulation({ type: artType }).setPosition(position));
                } catch (error) {
                    console.warn(`Failed to add articulation: ${artType}`, error);
                }
            });
        }
    }

    applyOrnaments(staveNote, factory) {
        if (this.notations && this.notations.ornaments) {
            const vexOrnaments = this.notations.ornaments.getVexFlowOrnaments();
            vexOrnaments.forEach(ornType => {
                try {
                    staveNote.addModifier(factory.Ornament({ type: ornType }));
                } catch (error) {
                    console.warn(`Failed to add ornament: ${ornType}`, error);
                }
            });
        }
    }

    applyColor(staveNote) {
        if (this.color) {
            staveNote.setStyle({ fillStyle: this.color, strokeStyle: this.color });
        }
    }
    static Notehead = class {
        constructor(element) {
            if (!element) return;
            this.type = element.textContent;
            this.filled = element.getAttribute('filled');
            this.parentheses = element.getAttribute('parentheses');
        }
    }

    static Grace = class {
        constructor(element) {
            if (!element) return;
            this.stealTimePrevious = element.getAttribute('steal-time-previous');
            this.stealTimeFollowing = element.getAttribute('steal-time-following');
            this.makeTime = element.getAttribute('make-time');
            this.slash = element.getAttribute('slash');
            this.firstBeat = element.getAttribute('first-beat') === 'yes';
            this.lastBeat = element.getAttribute('last-beat') === 'yes';
            this.startNote = element.getAttribute('start-note');
            this.stopNote = element.getAttribute('stop-note');
            this.slashChordGraceNote = element.getAttribute('slash-chord-grace-note') === 'yes';
        }
        getVexFlowGraceNoteStruct() {
            return {
                slash: this.slash === 'yes',
                // VexFlow에서 사용할 수 있는 다른 속성들을 여기에 추가
            };
        }
    }

    static Pitch = class {
        constructor(element) {
            if (!element) return;
            this.step = element.querySelector('step')?.textContent;
            this.alter = element.querySelector('alter')?.textContent;
            this.octave = element.querySelector('octave')?.textContent;
        }
    }

    static Rest = class {
        constructor(element) {
            if (!element) return;
            this.measure = element.getAttribute('measure') === 'yes';
            this.displayStep = element.querySelector('display-step')?.textContent;
            this.displayOctave = element.querySelector('display-octave')?.textContent;
        }
    }

    static Unpitched = class {
        constructor(element) {
            if (!element) return;
            this.displayStep = element.querySelector('display-step')?.textContent;
            this.displayOctave = element.querySelector('display-octave')?.textContent;
        }
    }

    static Tie = class {
        constructor(element) {
            if (!element) return;
            this.type = element.getAttribute('type');
        }
    }

    static Instrument = class {
        constructor(element) {
            if (!element) return;
            this.id = element.getAttribute('id');
        }
    }

    static TimeModification = class {
        constructor(element) {
            if (!element) return;
            this.actualNotes = element.querySelector('actual-notes')?.textContent;
            this.normalNotes = element.querySelector('normal-notes')?.textContent;
            this.normalType = element.querySelector('normal-type')?.textContent;
            this.normalDot = element.querySelectorAll('normal-dot').length;
        }
    }

    static Notehead = class {
        constructor(element) {
            if (!element) return;
            this.type = element.textContent;
            this.filled = element.getAttribute('filled');
            this.parentheses = element.getAttribute('parentheses');
        }
    }

    static Beam = class {
        constructor(element) {
            if (!element) return;
            this.number = element.getAttribute('number');
            this.value = element.textContent;
        }
    }

    static Notations = class {
        constructor(element) {
            if (!element) return;
            this.tied = Array.from(element.querySelectorAll('tied')).map(el => new Note.Notations.Tied(el));
            this.slur = Array.from(element.querySelectorAll('slur')).map(el => new Note.Notations.Slur(el));
            this.tuplet = new Note.Notations.Tuplet(element.querySelector('tuplet'));
            this.glissando = new Note.Notations.Glissando(element.querySelector('glissando'));
            this.slide = new Note.Notations.Slide(element.querySelector('slide'));
            this.ornaments = new Note.Notations.Ornaments(element.querySelector('ornaments'));
            const technicalElement = element.querySelector('technical');
            this.technical = technicalElement ? new Technical(technicalElement) : null;
            this.articulations = new Note.Notations.Articulations(element.querySelector('articulations'));
            this.dynamics = new Note.Notations.Dynamics(element.querySelector('dynamics'));
            this.fermata = element.querySelector('fermata')?.textContent;
            this.arpeggiate = new Note.Notations.Arpeggiate(element.querySelector('arpeggiate'));
            this.nonArpeggiate = new Note.Notations.NonArpeggiate(element.querySelector('non-arpeggiate'));
        }

        static Tied = class {
            constructor(element) {
                if (!element) return;
                this.type = element.getAttribute('type');
                this.number = element.getAttribute('number');
                this.lineType = element.getAttribute('line-type');
                this.dashLength = element.getAttribute('dash-length');
                this.spaceLength = element.getAttribute('space-length');
                this.placement = element.getAttribute('placement');
                this.orientation = element.getAttribute('orientation');
            }
        }

        static Slur = class {
            constructor(element) {
                if (!element) return;
                this.type = element.getAttribute('type');
                this.number = element.getAttribute('number') || '1';
                this.lineType = element.getAttribute('line-type') || 'solid';
                this.dashLength = element.getAttribute('dash-length');
                this.spaceLength = element.getAttribute('space-length');
                this.placement = element.getAttribute('placement');
                this.orientation = element.getAttribute('orientation');
                this.bezierX = element.getAttribute('bezier-x');
                this.bezierY = element.getAttribute('bezier-y');
                this.defaultX = element.getAttribute('default-x');
                this.defaultY = element.getAttribute('default-y');
                this.relativeX = element.getAttribute('relative-x');
                this.relativeY = element.getAttribute('relative-y');
                this.color = element.getAttribute('color') || 'black';
            }
        }

        static Tuplet = class {
            constructor(element) {
                if (!element) return;
                this.type = element.getAttribute('type');
                this.number = element.getAttribute('number');
                this.bracket = element.getAttribute('bracket');
                this.showNumber = element.getAttribute('show-number');
                this.lineShape = element.getAttribute('line-shape');
                this.placement = element.getAttribute('placement');
            }
        }

        static Glissando = class {
            constructor(element) {
                if (!element) return;
                this.type = element.getAttribute('type');
                this.number = element.getAttribute('number');
                this.lineType = element.getAttribute('line-type');
            }
        }

        static Slide = class {
            constructor(element) {
                if (!element) return;
                this.type = element.getAttribute('type');
                this.number = element.getAttribute('number');
                this.lineType = element.getAttribute('line-type');
            }
        }

        static Ornaments = class {
            constructor(element) {
                if (!element) return;
                this.trillMark = element.querySelector('trill-mark') !== null;
                this.turn = element.querySelector('turn') !== null;
                this.invertedTurn = element.querySelector('inverted-turn') !== null;
                this.shake = element.querySelector('shake') !== null;
                this.wavyLine = element.querySelector('wavy-line') !== null;
                this.mordent = element.querySelector('mordent') !== null;
                this.invertedMordent = element.querySelector('inverted-mordent') !== null;
                this.schleifer = element.querySelector('schleifer') !== null;
                this.tremolo = element.querySelector('tremolo')?.textContent;
            }

            getVexFlowOrnaments() {
                const ornamentMap = {
                    'trillMark': 'tr',
                    'turn': 'turn',
                    'invertedTurn': 'turnInverted',
                    'shake': 'shake',
                    'wavyLine': 'mordent',
                    'mordent': 'mordent',
                    'invertedMordent': 'mordentInverted',
                    'schleifer': 'schleifer',
                    'tremolo': 'tremolo'
                };

                return Object.entries(ornamentMap)
                    .filter(([xmlOrn, _]) => this[xmlOrn])
                    .map(([_, vexOrn]) => vexOrn);
            }
        }

        static Technical = class {
            constructor(element) {
                if (!element) return;
                this.upBow = element.querySelector('up-bow') !== null;
                this.downBow = element.querySelector('down-bow') !== null;
                this.harmonic = element.querySelector('harmonic') !== null;
                this.openString = element.querySelector('open-string') !== null;
                this.thumbPosition = element.querySelector('thumb-position') !== null;
                this.fingering = element.querySelector('fingering')?.textContent;
                this.pluck = element.querySelector('pluck')?.textContent;
                this.doubleTongue = element.querySelector('double-tongue') !== null;
                this.tripleTongue = element.querySelector('triple-tongue') !== null;
                this.stopped = element.querySelector('stopped') !== null;
                this.snapPizzicato = element.querySelector('snap-pizzicato') !== null;
                this.fret = element.querySelector('fret')?.textContent;
                this.string = element.querySelector('string')?.textContent;
                this.hammerOn = element.querySelector('hammer-on')?.textContent;
                this.pullOff = element.querySelector('pull-off')?.textContent;
                this.bend = new Note.Notations.Technical.Bend(element.querySelector('bend'));
                this.tap = element.querySelector('tap')?.textContent;
                this.heel = element.querySelector('heel') !== null;
                this.toe = element.querySelector('toe') !== null;
                this.fingernails = element.querySelector('fingernails') !== null;
            }

            static Bend = class {
                constructor(element) {
                    if (!element) return;
                    this.bendAlter = element.querySelector('bend-alter')?.textContent;
                    this.preBend = element.querySelector('pre-bend') !== null;
                    this.release = element.querySelector('release') !== null;
                    this.withBar = element.querySelector('with-bar')?.textContent;
                }
            }
        }

        static Articulations = class {
            constructor(element) {
                if (!element) return;
                this.accent = element.querySelector('accent') !== null;
                this.strongAccent = element.querySelector('strong-accent') !== null;
                this.staccato = element.querySelector('staccato') !== null;
                this.tenuto = element.querySelector('tenuto') !== null;
                this.detachedLegato = element.querySelector('detached-legato') !== null;
                this.staccatissimo = element.querySelector('staccatissimo') !== null;
                this.spiccato = element.querySelector('spiccato') !== null;
                this.scoop = element.querySelector('scoop') !== null;
                this.plop = element.querySelector('plop') !== null;
                this.doit = element.querySelector('doit') !== null;
                this.falloff = element.querySelector('falloff') !== null;
                this.breathMark = element.querySelector('breath-mark') !== null;
                this.caesura = element.querySelector('caesura') !== null;
                this.stress = element.querySelector('stress') !== null;
                this.unstress = element.querySelector('unstress') !== null;
            }

            static articulationMap = {
                'accent': 'a>',
                'strongAccent': 'a^',
                'staccato': 'a.',
                'tenuto': 'a-',
                'detachedLegato': 'a>.',
                'staccatissimo': 'av',
                'spiccato': 'a|',
                'scoop': 'ay',
                'plop': 'ah',
                'doit': 'ao',
                'falloff': 'am',
                'breathMark': 'ab',
                'caesura': 'a||',
                'stress': 'a>',
                'unstress': 'a-'
            };

            getVexFlowArticulations() {
                return Object.entries(Articulations.articulationMap)
                    .filter(([xmlArt, _]) => this[xmlArt])
                    .map(([_, vexArt]) => vexArt);
            }
        }

        static Dynamics = class {
            constructor(element) {
                if (!element) return;
                const dynamics = ['p', 'pp', 'ppp', 'pppp', 'ppppp', 'pppppp', 'f', 'ff', 'fff', 'ffff', 'fffff', 'ffffff', 'mp', 'mf', 'sf', 'sfp', 'sfpp', 'fp', 'rf', 'rfz', 'sfz', 'sffz', 'fz'];
                dynamics.forEach(d => {
                    this[d] = element.querySelector(d) !== null;
                });
                this.otherDynamics = element.querySelector('other-dynamics')?.textContent;
            }
        }

        static Arpeggiate = class {
            constructor(element) {
                if (!element) return;
                this.number = element.getAttribute('number');
                this.direction = element.getAttribute('direction');
            }
        }

        static NonArpeggiate = class {
            constructor(element) {
                if (!element) return;
                this.number = element.getAttribute('number');
                this.type = element.getAttribute('type');
            }
        }
    }

    static Lyric = class {
        constructor(element) {
            if (!element) return;
            this.number = element.getAttribute('number');
            this.syllabic = element.querySelector('syllabic')?.textContent;
            this.text = element.querySelector('text')?.textContent;
        }
    }
}

class Backup {
    constructor(element) {
        if (!element) return;
        this.duration = element.querySelector('duration')?.textContent;
    }
}

class Forward {
    constructor(element) {
        if (!element) return;
        this.duration = element.querySelector('duration')?.textContent;
    }
}

class Direction {
    constructor(element) {
        if (!element) return;
        this.placement = element.getAttribute('placement');
        this.directionType = new DirectionType(element.querySelector('direction-type'));
        this.staff = element.querySelector('staff')?.textContent;
        this.sound = new Sound(element.querySelector('sound'));
    }
}

class DirectionType {
    constructor(element) {
        if (!element) return;
        this.rehearsal = new Rehearsal(element.querySelector('rehearsal'));
        this.segno = element.querySelector('segno') !== null;
        this.words = element.querySelector('words')?.textContent;
        this.coda = element.querySelector('coda') !== null;
        this.dynamics = new Dynamics(element.querySelector('dynamics'));
        this.wedge = new Wedge(element.querySelector('wedge'));
        this.metronome = new Metronome(element.querySelector('metronome'));
        this.octaveShift = new OctaveShift(element.querySelector('octave-shift'));
        this.pedal = new Pedal(element.querySelector('pedal'));
    }
}

class Harmony {
    constructor(element) {
        if (!element) return;
        this.root = new Harmony.Root(element.querySelector('root'));
        this.function = element.querySelector('function')?.textContent;
        this.kind = new Harmony.Kind(element.querySelector('kind'));
        this.inversion = element.querySelector('inversion')?.textContent;
        this.bass = new Harmony.Bass(element.querySelector('bass'));
        this.degrees = Array.from(element.querySelectorAll('degree')).map(el => new Harmony.Degree(el));
        this.frame = new Harmony.Frame(element.querySelector('frame'));
        this.staff = element.querySelector('staff')?.textContent;
        this.offset = element.querySelector('offset')?.textContent;
        this.text = this.generateHarmonyText();
    }

    getVexFlowText() {
        let text = '';

        // Root
        if (this.root) {
            text += this.root.rootStep;
            if (this.root.rootAlter) {
                text += this.root.rootAlter === '1' ? '#' : 'b';
            }
        }

        // Kind
        if (this.kind) {
            switch (this.kind.value) {
                case 'major': break;
                case 'minor': text += 'm'; break;
                case 'augmented': text += '+'; break;
                case 'diminished': text += 'dim'; break;
                case 'dominant': text += '7'; break;
                case 'major-seventh': text += 'maj7'; break;
                case 'minor-seventh': text += 'm7'; break;
                case 'diminished-seventh': text += 'dim7'; break;
                case 'augmented-seventh': text += '+7'; break;
                case 'half-diminished': text += 'm7b5'; break;
                case 'major-minor': text += 'm(maj7)'; break;
                case 'major-sixth': text += '6'; break;
                case 'minor-sixth': text += 'm6'; break;
                default: text += this.kind.text || this.kind.value;
            }
        }

        // Degrees
        if (this.degrees) {
            this.degrees.forEach(degree => {
                if (degree.degreeValue && degree.degreeAlter) {
                    const alter = degree.degreeAlter === '1' ? '#' : 'b';
                    text += alter + degree.degreeValue;
                }
            });
        }

        // Bass
        if (this.bass && this.bass.bassStep) {
            text += '/' + this.bass.bassStep;
            if (this.bass.bassAlter) {
                text += this.bass.bassAlter === '1' ? '#' : 'b';
            }
        }

        return text;
    }

    generateHarmonyText() {
        let text = '';

        if (this.root) {
            text += this.root.rootStep;
            if (this.root.rootAlter) {
                text += this.getAlterSymbol(this.root.rootAlter);
            }
        }

        if (this.kind) {
            // sus4 + 7 특수 케이스 처리
            if (this.kind.value === 'suspended-fourth' &&
                this.degrees.some(deg =>
                    deg.degreeValue === '7' &&
                    deg.degreeType === 'add' &&
                    deg.degreeAlter === '0')) {
                text += '7sus4';
            } else {
                text += this.getKindSymbol(this.kind.value);
            }
        }

        // kind가 suspended-fourth + 7 조합이 아닐 때만 alterDegrees 처리
        if (this.kind.value !== 'suspended-fourth' ||
            !this.degrees.some(deg =>
                deg.degreeValue === '7' &&
                deg.degreeType === 'add' &&
                deg.degreeAlter === '0')) {
            const alteredDegrees = this.getAlteredDegrees();
            if (alteredDegrees.length > 0) {
                text += alteredDegrees.join('');
            }
        }

        if (this.bass && this.bass.bassStep) {
            text += '/' + this.bass.bassStep;
            if (this.bass.bassAlter) {
                text += this.getAlterSymbol(this.bass.bassAlter);
            }
        }

        return text;
    }

    getAlteredDegrees() {
        return this.degrees
            .filter(degree => degree.degreeAlter !== '0' && degree.degreeType === 'alter')
            .map(degree => {
                const alter = parseInt(degree.degreeAlter);
                const symbol = alter < 0 ? 'b' : '#';
                return symbol + degree.degreeValue;
            });
    }

    getAlterSymbol(alter) {
        switch (alter) {
            case '1': return '#';
            case '-1': return 'b';
            default: return '';
        }
    }

    getKindSymbol(kind) {
        const kindMap = {
            'major': '',
            'minor': 'm',
            'augmented': 'aug',
            'diminished': 'dim',
            'dominant': '7',
            'major-seventh': 'maj7',
            'minor-seventh': 'm7',
            'diminished-seventh': 'dim7',
            'augmented-seventh': 'aug7',
            'half-diminished': 'm7b5',
            'major-minor': 'm(maj7)',
            'suspended-second': 'sus2',
            'suspended-fourth': 'sus4'
        };

        return kindMap[kind] !== undefined ? kindMap[kind] : '';
    }

    static Root = class {
        constructor(element) {
            if (!element) return;
            this.rootStep = element.querySelector('root-step')?.textContent;
            this.rootAlter = element.querySelector('root-alter')?.textContent;
        }
    }

    static Kind = class {
        constructor(element) {
            if (!element) return;
            this.value = element.textContent;
            this.useSymbols = element.getAttribute('use-symbols') === 'yes';
            this.text = element.getAttribute('text');
            this.stackDegrees = element.getAttribute('stack-degrees') === 'yes';
            this.parenthesesDegrees = element.getAttribute('parentheses-degrees') === 'yes';
            this.bracketDegrees = element.getAttribute('bracket-degrees') === 'yes';
            this.halign = element.getAttribute('halign') || 'left';
            this.valign = element.getAttribute('valign');
        }
    }

    static Bass = class {
        constructor(element) {
            if (!element) return;
            this.bassStep = element.querySelector('bass-step')?.textContent;
            this.bassAlter = element.querySelector('bass-alter')?.textContent;
        }
    }

    static Degree = class {
        constructor(element) {
            if (!element) return;
            this.degreeValue = element.querySelector('degree-value')?.textContent;
            this.degreeAlter = element.querySelector('degree-alter')?.textContent;
            this.degreeType = element.querySelector('degree-type')?.textContent;
        }
    }

    static Frame = class {
        constructor(element) {
            if (!element) return;
            this.frameStrings = element.querySelector('frame-strings')?.textContent;
            this.frameFrets = element.querySelector('frame-frets')?.textContent;
            this.frameNote = Array.from(element.querySelectorAll('frame-note')).map(el => new Harmony.Frame.FrameNote(el));
        }

        static FrameNote = class {
            constructor(element) {
                if (!element) return;
                this.string = element.querySelector('string')?.textContent;
                this.fret = element.querySelector('fret')?.textContent;
                this.fingering = element.querySelector('fingering')?.textContent;
                this.barre = element.querySelector('barre')?.getAttribute('type');
            }
        }
    }
}

class FiguredBass {
    constructor(element) {
        if (!element) return;
        this.figure = Array.from(element.querySelectorAll('figure')).map(el => new Figure(el));
        this.duration = element.querySelector('duration')?.textContent;
    }
}

class Figure {
    constructor(element) {
        if (!element) return;
        this.prefix = element.querySelector('prefix')?.textContent;
        this.figureNumber = element.querySelector('figure-number')?.textContent;
        this.suffix = element.querySelector('suffix')?.textContent;
        this.extend = element.querySelector('extend') !== null;
    }
}

class Print {
    constructor(element) {
        if (!element) return;
        this.staffLayout = Array.from(element.querySelectorAll('staff-layout')).map(el => new StaffLayout(el));
        this.measureLayout = new MeasureLayout(element.querySelector('measure-layout'));
        this.partNameDisplay = new PartNameDisplay(element.querySelector('part-name-display'));
        this.partAbbreviationDisplay = new PartAbbreviationDisplay(element.querySelector('part-abbreviation-display'));
    }
}

class Sound {
    constructor(element) {
        if (!element) return;

        // Direct attributes
        this.tempo = element.getAttribute('tempo');
        this.dynamics = element.getAttribute('dynamics');
        this.timeOnly = element.getAttribute('time-only');
        this.pizzicato = element.getAttribute('pizzicato') === 'yes';
        this.pan = element.getAttribute('pan');
        this.elevation = element.getAttribute('elevation');
        this.damperPedal = element.getAttribute('damper-pedal');
        this.softPedal = element.getAttribute('soft-pedal');
        this.sostenutoPedal = element.getAttribute('sostenuto-pedal');

        // Child elements
        this.swing = new Sound.Swing(element.querySelector('swing'));
        this.midi = new Sound.Midi(element.querySelector('midi'));
        this.instrument = new Sound.Instrument(element.querySelector('instrument'));
        this.offset = element.querySelector('offset')?.textContent;
    }

    static Swing = class {
        constructor(element) {
            if (!element) return;

            // straight가 있거나 first/second가 있음
            this.straight = element.querySelector('straight')?.textContent;

            if (!this.straight) {
                this.first = element.querySelector('first')?.textContent;
                this.second = element.querySelector('second')?.textContent;
                this.swingType = element.querySelector('swing-type')?.textContent || 'eighth';
            }
        }
    }

    static Midi = class {
        constructor(element) {
            if (!element) return;
            this.tempo = element.getAttribute('tempo');
            this.volume = element.getAttribute('volume');
            this.pan = element.getAttribute('pan');
            this.elevation = element.getAttribute('elevation');
        }
    }

    static Instrument = class {
        constructor(element) {
            if (!element) return;
            this.id = element.getAttribute('id');
            this.virtualLibrary = element.querySelector('virtual-library')?.textContent;
            this.virtualName = element.querySelector('virtual-name')?.textContent;
            this.solo = new Sound.Instrument.Solo(element.querySelector('solo'));
            this.ensemble = new Sound.Instrument.Ensemble(element.querySelector('ensemble'));
        }

        static Solo = class {
            constructor(element) {
                if (!element) return;
                this.required = element.getAttribute('required') === 'yes';
                this.type = element.getAttribute('type');
            }
        }

        static Ensemble = class {
            constructor(element) {
                if (!element) return;
                this.required = element.getAttribute('required') === 'yes';
                this.size = element.getAttribute('size');
                this.type = element.getAttribute('type');
            }
        }
    }
}

class Barline {
    constructor(element) {
        if (!element) return;
        this.location = element.getAttribute('location');
        this.barStyle = element.querySelector('bar-style')?.textContent;
        this.repeat = new Repeat(element.querySelector('repeat'));
        this.ending = new Ending(element.querySelector('ending'));
    }
}

class Repeat {
    constructor(element) {
        if (!element) return;
        this.direction = element.getAttribute('direction');
        this.times = element.getAttribute('times');
    }
}

class Ending {
    constructor(element) {
        if (!element) return;
        this.number = element.getAttribute('number');
        this.type = element.getAttribute('type');
        this.text = element.textContent;
    }
}

class Grouping {
    constructor(element) {
        if (!element) return;
        this.type = element.getAttribute('type');
        this.number = element.getAttribute('number');
        this.memberOf = element.getAttribute('member-of');
        this.feature = Array.from(element.querySelectorAll('feature')).map(el => new Feature(el));
    }
}

class Feature {
    constructor(element) {
        if (!element) return;
        this.type = element.getAttribute('type');
        this.value = element.textContent;
    }
}

class Link {
    constructor(element) {
        if (!element) return;
        this.href = element.getAttribute('xlink:href');
        this.type = element.getAttribute('xlink:type');
        this.role = element.getAttribute('xlink:role');
        this.title = element.getAttribute('xlink:title');
        this.show = element.getAttribute('xlink:show');
        this.actuate = element.getAttribute('xlink:actuate');
    }
}

class Tied {
    constructor(element) {
        if (!element) return;
        this.type = element.getAttribute('type');
        this.number = element.getAttribute('number');
        this.lineType = element.getAttribute('line-type');
        this.dashLength = element.getAttribute('dash-length');
        this.spaceLength = element.getAttribute('space-length');
        this.placement = element.getAttribute('placement');
        this.orientation = element.getAttribute('orientation');
    }
}

class Slur {
    constructor(element) {
        if (!element) return;
        this.type = element.getAttribute('type');
        this.number = element.getAttribute('number') || '1';
        this.lineType = element.getAttribute('line-type') || 'solid';
        this.dashLength = element.getAttribute('dash-length');
        this.spaceLength = element.getAttribute('space-length');
        this.placement = element.getAttribute('placement');
        this.orientation = element.getAttribute('orientation');
        this.bezierX = element.getAttribute('bezier-x');
        this.bezierY = element.getAttribute('bezier-y');
        this.defaultX = element.getAttribute('default-x');
        this.defaultY = element.getAttribute('default-y');
        this.relativeX = element.getAttribute('relative-x');
        this.relativeY = element.getAttribute('relative-y');
        this.color = element.getAttribute('color') || 'black';
    }
}

class Tuplet {
    constructor(element) {
        if (!element) return;
        this.type = element.getAttribute('type');
        this.number = element.getAttribute('number');
        this.bracket = element.getAttribute('bracket');
        this.showNumber = element.getAttribute('show-number');
        this.lineShape = element.getAttribute('line-shape');
        this.placement = element.getAttribute('placement');
    }
}

class Glissando {
    constructor(element) {
        if (!element) return;
        this.type = element.getAttribute('type');
        this.number = element.getAttribute('number');
        this.lineType = element.getAttribute('line-type');
    }
}

class Slide {
    constructor(element) {
        if (!element) return;
        this.type = element.getAttribute('type');
        this.number = element.getAttribute('number');
        this.lineType = element.getAttribute('line-type');
    }
}

class Ornaments {
    constructor(element) {
        if (!element) return;
        this.trillMark = element.querySelector('trill-mark') !== null;
        this.turn = element.querySelector('turn') !== null;
        this.invertedTurn = element.querySelector('inverted-turn') !== null;
        this.shake = element.querySelector('shake') !== null;
        this.wavyLine = element.querySelector('wavy-line') !== null;
        this.mordent = element.querySelector('mordent') !== null;
        this.invertedMordent = element.querySelector('inverted-mordent') !== null;
        this.schleifer = element.querySelector('schleifer') !== null;
        this.tremolo = element.querySelector('tremolo')?.textContent;
        // 추가 장식음 요소들...
    }
}

class Technical {
    constructor(element) {
        if (!element) return;
        this.upBow = element.querySelector('up-bow') !== null;
        this.downBow = element.querySelector('down-bow') !== null;
        this.harmonic = element.querySelector('harmonic') !== null;
        this.openString = element.querySelector('open-string') !== null;
        this.thumbPosition = element.querySelector('thumb-position') !== null;
        this.fingering = element.querySelector('fingering')?.textContent;
        this.pluck = element.querySelector('pluck')?.textContent;
        this.doubleTongue = element.querySelector('double-tongue') !== null;
        this.tripleTongue = element.querySelector('triple-tongue') !== null;
        this.stopped = element.querySelector('stopped') !== null;
        this.snapPizzicato = element.querySelector('snap-pizzicato') !== null;
        this.fret = element.querySelector('fret')?.textContent;
        this.string = element.querySelector('string')?.textContent;
        this.hammerOn = element.querySelector('hammer-on')?.textContent;
        this.pullOff = element.querySelector('pull-off')?.textContent;
        const bendElement = element.querySelector('bend');
        this.bend = bendElement ? new Note.Notations.Technical.Bend(bendElement) : null;
        this.tap = element.querySelector('tap')?.textContent;
        this.heel = element.querySelector('heel') !== null;
        this.toe = element.querySelector('toe') !== null;
        this.fingernails = element.querySelector('fingernails') !== null;
        // 추가 기술적 요소들...
    }
}

class Bend {
    constructor(element) {
        if (!element) return;
        this.bendAlter = element.querySelector('bend-alter')?.textContent;
        this.preBend = element.querySelector('pre-bend') !== null;
        this.release = element.querySelector('release') !== null;
        this.withBar = element.querySelector('with-bar')?.textContent;
    }
}

class Articulations {
    constructor(element) {
        if (!element) return;
        this.accent = element.querySelector('accent') !== null;
        this.strongAccent = element.querySelector('strong-accent') !== null;
        this.staccato = element.querySelector('staccato') !== null;
        this.tenuto = element.querySelector('tenuto') !== null;
        this.detachedLegato = element.querySelector('detached-legato') !== null;
        this.staccatissimo = element.querySelector('staccatissimo') !== null;
        this.spiccato = element.querySelector('spiccato') !== null;
        this.scoop = element.querySelector('scoop') !== null;
        this.plop = element.querySelector('plop') !== null;
        this.doit = element.querySelector('doit') !== null;
        this.falloff = element.querySelector('falloff') !== null;
        this.breathMark = element.querySelector('breath-mark') !== null;
        this.caesura = element.querySelector('caesura') !== null;
        this.stress = element.querySelector('stress') !== null;
        this.unstress = element.querySelector('unstress') !== null;
        // 추가 아티큘레이션 요소들...
    }

    static articulationMap = {
        'accent': 'a>',
        'strongAccent': 'a^',
        'staccato': 'a.',
        'tenuto': 'a-',
        'detachedLegato': 'a>.',
        'staccatissimo': 'av',
        'spiccato': 'a|',
        'scoop': 'ay',
        'plop': 'ah',
        'doit': 'ao',
        'falloff': 'am',
        'breathMark': 'ab',
        'caesura': 'a||',
        'stress': 'a>',
        'unstress': 'a-'
    };

    getVexFlowArticulations() {
        const vexArticulations = [];
        for (const [xmlArt, vexArt] of Object.entries(Articulations.articulationMap)) {
            if (this[xmlArt]) {
                vexArticulations.push(vexArt);
            }
        }
        return vexArticulations;
    }
}

class Dynamics {
    constructor(element) {
        if (!element || typeof element !== 'object') return;

        const dynamicsList = ['pppppp', 'ppppp', 'pppp', 'ppp', 'pp', 'p',
            'mp', 'mf', 'f', 'ff', 'fff', 'ffff', 'fffff',
            'ffffff', 'sf', 'sfp', 'sfpp', 'fp', 'rf', 'rfz',
            'sfz', 'sffz', 'fz'];

        // dynamics 데이터가 있는지 먼저 확인
        let hasDynamics = false;
        dynamicsList.forEach(d => {
            if (element[d]) {
                hasDynamics = true;
                this[d] = true;
            }
        });

        // dynamics가 없으면 early return
        if (!hasDynamics && !element['other-dynamics']) return;

        this.otherDynamics = element['other-dynamics'] || null;
    }

    getDynamicsText() {
        const dynamicsOrder = ['pppppp', 'ppppp', 'pppp', 'ppp', 'pp', 'p',
            'mp', 'mf', 'f', 'ff', 'fff', 'ffff', 'fffff',
            'ffffff', 'sf', 'sfp', 'sfpp', 'fp', 'rf', 'rfz',
            'sfz', 'sffz', 'fz'];

        for (const dyn of dynamicsOrder) {
            if (this[dyn]) return dyn;
        }
        return this.otherDynamics || '';
    }
}



class Arpeggiate {
    constructor(element) {
        if (!element) return;
        this.number = element.getAttribute('number');
        this.direction = element.getAttribute('direction');
    }
}

class NonArpeggiate {
    constructor(element) {
        if (!element) return;
        this.number = element.getAttribute('number');
        this.type = element.getAttribute('type');
    }
}

class Rehearsal {
    constructor(element) {
        if (!element) return;
        this.value = element.textContent;
        this.fontFamily = element.getAttribute('font-family');
        this.fontSize = element.getAttribute('font-size');
        this.fontStyle = element.getAttribute('font-style');
        this.fontWeight = element.getAttribute('font-weight');
    }
}

class Wedge {
    constructor(element) {
        if (!element) return;
        this.type = element.getAttribute('type');
        this.number = element.getAttribute('number');
        this.spread = element.getAttribute('spread');
    }
}

class Metronome {
    constructor(element) {
        if (!element) return;
        this.beatUnit = element.querySelector('beat-unit')?.textContent;
        this.perMinute = element.querySelector('per-minute')?.textContent;
        this.beatUnitDot = element.querySelector('beat-unit-dot') !== null;
        this.beatUnitTied = new BeatUnitTied(element.querySelector('beat-unit-tied'));
    }
}

class BeatUnitTied {
    constructor(element) {
        if (!element) return;
        this.beatUnit = element.querySelector('beat-unit')?.textContent;
        this.beatUnitDot = element.querySelector('beat-unit-dot') !== null;
    }
}

class OctaveShift {
    constructor(element) {
        if (!element) return;
        this.type = element.getAttribute('type');
        this.number = element.getAttribute('number');
        this.size = element.getAttribute('size');
    }
}

class Pedal {
    constructor(element) {
        if (!element) return;
        this.type = element.getAttribute('type');
        this.line = element.getAttribute('line') === 'yes';
        this.sign = element.getAttribute('sign') === 'yes';
    }
}

class MeasureLayout {
    constructor(element) {
        if (!element) return;
        this.measureDistance = element.querySelector('measure-distance')?.textContent;
    }
}

class PartNameDisplay {
    constructor(element) {
        if (!element) return;
        this.displayText = element.querySelector('display-text')?.textContent;
        this.accidentalText = element.querySelector('accidental-text')?.textContent;
    }
}

class PartAbbreviationDisplay {
    constructor(element) {
        if (!element) return;
        this.displayText = element.querySelector('display-text')?.textContent;
        this.accidentalText = element.querySelector('accidental-text')?.textContent;
    }
}

class GenericElement {
    constructor(element) {
        this.nodeName = element.nodeName;
        this.attributes = this.parseAttributes(element);
        this.textContent = element.textContent;
        this.children = Array.from(element.children).map(child => new GenericElement(child));
    }

    parseAttributes(element) {
        const attributes = {};
        for (let attr of element.attributes) {
            attributes[attr.name] = attr.value;
        }
        return attributes;
    }
}