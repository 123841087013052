//room.js
import { defineStore } from 'pinia';
import {
    ref,
    onValue,
    update,
    push,
    set,
    remove,
    get,
    query,
    orderByChild,
    equalTo
} from 'firebase/database';
import { rtdb } from '../firebase';
import { useAuthStore } from './auth';
import { useMetronomeStore } from '@/store/metronome';
import {
    trackCreateRoom,
    trackJoinRoom,
    trackLeaveRoom,
    trackRemoveRoom,
    // trackRoomSettingChange,
    trackBPMChange,
    trackPlay,
    trackStop,
    trackGroupSession,
    trackAlbum,
    // trackBeatProgress,
    trackMeasureChange,
    trackAlbumFetch
} from '@/analytics';

export const useRoomStore = defineStore('room', {
    state: () => ({
        rooms: {},
        currentRoom: null,
        currentBeat: 1,
        currentAlbumId: null,
        currentRoomData: null,
        roomListener: null,
        settingScores: null, // { uid: string, nickname: string, startTime: number, type: string }
        settingTimeout: null // 30초 타임아웃 참조를 저장
    }),

    getters: {
        isInRoom: (state) => !!state.currentRoom,
        computedRoomData: (state) => state.currentRoom ? state.rooms[state.currentRoom] || null : null,
        isPlayDisabled: (state) => !!state.settingScores
    },

    actions: {
        async startScoreSettings(settingType) {
            const authStore = useAuthStore();
            if (!this.currentRoom || !authStore.isAuthenticated) return false;

            const roomRef = ref(rtdb, `rooms/${this.currentRoom}`);
            const snapshot = await get(roomRef);
            const roomData = snapshot.val();

            // 이미 다른 사람이 설정 중인 경우
            if (roomData.settingScores) {
                const settingTime = roomData.settingScores.startTime;
                const now = Date.now();

                // 30초가 지났다면 이전 설정을 클리어
                if (now - settingTime > 30000) {
                    await this.clearScoreSettings();
                } else if (roomData.settingScores.uid !== authStore.user.uid) {
                    return false;
                }
            }

            // 설정 시작
            const settingData = {
                uid: authStore.user.uid,
                nickname: authStore.userProfile?.nickname || '익명',
                startTime: Date.now(),
                type: settingType
            };

            // 기존 타임아웃이 있다면 클리어
            if (this.settingTimeout) {
                clearTimeout(this.settingTimeout);
            }

            await update(roomRef, { settingScores: settingData });
            this.settingScores = settingData;

            // 30초 후 자동 종료
            this.settingTimeout = setTimeout(async () => {
                await this.checkAndClearSettings(settingData);
            }, 30000);

            return true;
        },

        async checkAndClearSettings(originalSettings) {
            if (!this.currentRoom) return;

            const roomRef = ref(rtdb, `rooms/${this.currentRoom}`);
            const snapshot = await get(roomRef);
            const roomData = snapshot.val();

            // 현재 설정이 원래 시작한 설정과 같은 경우에만 클리어
            if (roomData.settingScores?.startTime === originalSettings.startTime) {
                await this.clearScoreSettings();
            }
        },

        async clearScoreSettings() {
            if (!this.currentRoom) return;

            if (this.settingTimeout) {
                clearTimeout(this.settingTimeout);
                this.settingTimeout = null;
            }

            const roomRef = ref(rtdb, `rooms/${this.currentRoom}`);
            await update(roomRef, { settingScores: null });
            this.settingScores = null;
        },

        async finalizeScoreSettings(settings) {
            if (!this.currentRoom) return;

            if (this.settingTimeout) {
                clearTimeout(this.settingTimeout);
                this.settingTimeout = null;
            }

            const roomRef = ref(rtdb, `rooms/${this.currentRoom}`);
            await update(roomRef, {
                ...settings,
                settingScores: null
            });
            this.settingScores = null;
        },

        async fetchRooms() {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated) {
                console.error('User not authenticated');
                return;
            }

            this.clearRoomsListener();

            console.log('Fetching rooms...');
            const roomsRef = ref(rtdb, 'rooms');
            this.roomsListener = onValue(roomsRef, (snapshot) => {
                const allRooms = snapshot.val();
                console.log('All rooms:', JSON.parse(JSON.stringify(allRooms)));
                if (allRooms) {
                    this.rooms = allRooms;
                    console.log('Fetched rooms:', JSON.parse(JSON.stringify(this.rooms)));
                } else {
                    this.rooms = {};
                    console.log('No rooms found');
                }
            }, (error) => {
                console.error('Error fetching rooms:', error);
            });
        },

        clearRoomsListener() {
            if (this.roomsListener) {
                console.log('Clearing rooms listener');
                this.roomsListener();
                this.roomsListener = null;
            }
        },

        clearAllRoomListeners() {
            this.clearRoomsListener();
            if (this.roomListener) {
                console.log('Clearing room listener');
                this.roomListener();
                this.roomListener = null;
            }
        },

        async fetchCurrentRoom(roomId) {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated) {
                console.error('User not authenticated');
                return;
            }

            console.log('Fetching current room:', roomId);
            if (!roomId) {
                console.error('No roomId provided to fetchCurrentRoom');
                return;
            }
            const roomRef = ref(rtdb, `rooms/${roomId}`);
            try {
                const snapshot = await get(roomRef);
                if (snapshot.exists()) {
                    const roomData = snapshot.val();
                    if (roomData.isPersonal && roomData.createdBy !== authStore.user.uid) {
                        console.error('No access to this personal room');
                        return;
                    }
                    this.rooms = { ...this.rooms, [roomId]: roomData };
                    this.currentRoom = roomId;
                    this.currentRoomData = roomData;
                } else {
                    console.log('Room not found:', roomId);
                    this.currentRoom = null;
                    this.currentRoomData = null;
                }
            } catch (error) {
                console.error('Error fetching current room:', error);
            }
        },

        async checkRoomExists(roomId) {
            if (!roomId) return false;
            const roomRef = ref(rtdb, `rooms/${roomId}`);
            try {
                const snapshot = await get(roomRef);
                return snapshot.exists();
            } catch (error) {
                console.error("Error checking room existence:", error);
                return false;
            }
        },

        async joinRoom(roomId) {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated) {
                console.error('User not authenticated');
                return;
            }

            if (this.roomListener) {
                this.roomListener();
                this.roomListener = null;
            }

            await authStore.fetchUserProfile();
            const nickname = authStore.userProfile?.nickname || '익명';

            const roomRef = ref(rtdb, `rooms/${roomId}`);

            const roomSnapshot = await get(roomRef);
            const roomData = roomSnapshot.val();
            const currentParticipants = roomData?.participants || {};
            const participantCount = Object.keys(currentParticipants).length;

            const userInfo = {
                nickname: nickname,
                joinedAt: new Date().toISOString(),
                lastPing: new Date().toISOString(),
            };

            await update(roomRef, {
                [`participants/${authStore.user.uid}`]: userInfo,
            });

            this.currentRoom = roomId;

            // Analytics

            await authStore.updateCurrentRoom(roomId);
            await this.fetchCurrentRoom(roomId);
            this.listenToRoomChanges();
            trackJoinRoom(roomId, {
                participantCount: participantCount + 1,
                user_id: authStore.user.uid
            });
        },

        async updateLastPing() {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated || !this.currentRoom) {
                console.error('User not authenticated or not in a room');
                return;
            }

            const pingRef = ref(rtdb, `rooms/${this.currentRoom}/participants/${authStore.user.uid}/lastPing`);
            await set(pingRef, new Date().toISOString());
        },

        async checkRoomNameDuplicate(name) {
            const roomsRef = ref(rtdb, 'rooms');
            const roomQuery = query(roomsRef, orderByChild('name'), equalTo(name));
            const snapshot = await get(roomQuery);
            return snapshot.exists();
        },

        async createRoom(roomData) {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated) {
                console.error('User not authenticated');
                return;
            }

            await authStore.fetchUserProfile();
            const nickname = authStore.userProfile?.nickname || '익명';
            const roomsRef = ref(rtdb, 'rooms');
            const newRoomRef = push(roomsRef);

            const defaultRoomData = {
                bpm: 120,
                createdAt: new Date().toISOString(),
                createdBy: authStore.user.uid,
                hasPassword: false,
                isPlaying: false,
                isPublic: true,
                isPersonal: false,
                name: roomData.name || '새 방',
                participants: {},
                prebeat: 1,
                soundType: 'sine',
                startTime: Date.now(),
                timeSignature: '4/4',
                totalBeats: 0,
                volume: 0.5,
            };

            const finalRoomData = { ...defaultRoomData, ...roomData };

            if (finalRoomData.isPersonal) {
                finalRoomData.isPublic = false;
                finalRoomData.hasPassword = true;
                finalRoomData.password = authStore.user.uid;
            } else {
                finalRoomData.isPublic = !finalRoomData.password;
                finalRoomData.hasPassword = !!finalRoomData.password;
            }

            finalRoomData.participants[authStore.user.uid] = {
                nickname: nickname,
                joinedAt: new Date().toISOString(),
                lastPing: new Date().toISOString(),
                isHost: true
            };

            trackCreateRoom(newRoomRef.key, {
                isPersonal: finalRoomData.isPersonal,
                name: finalRoomData.name,
                user_Id: authStore.user.uid
            });

            await set(newRoomRef, finalRoomData);
            this.currentRoom = newRoomRef.key;
            this.currentRoomData = finalRoomData;
            await authStore.updateCurrentRoom(newRoomRef.key);
            this.listenToRoomChanges();
            return newRoomRef.key;
        },

        async deleteRoom() {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated || !this.currentRoom) {
                console.error('User not authenticated or not in a room');
                return;
            }

            const roomRef = ref(rtdb, `rooms/${this.currentRoom}`);
            await remove(roomRef);

            trackRemoveRoom(this.currentRoom);

            this.currentRoom = null;
            this.currentRoomData = null;
            await authStore.updateCurrentRoom(null);
        },

        async leaveRoom() {
            const authStore = useAuthStore();
            const metronomeStore = useMetronomeStore();

            trackStop(this.currentRoom);

            if (!authStore.isAuthenticated || !this.currentRoom) {
                console.error('User not authenticated or not in a room');
                return;
            }

            if (this.roomListener) {
                this.roomListener();
                this.roomListener = null;
            }

            metronomeStore.pause();

            // 모든 기기의 participants 항목을 삭제
            const roomParticipantsRef = ref(rtdb, `rooms/${this.currentRoom}/participants`);
            const participantsSnapshot = await get(roomParticipantsRef);
            if (participantsSnapshot.exists()) {
                const participants = participantsSnapshot.val();
                // 해당 유저의 모든 참여 기록 삭제
                const updates = {};
                Object.keys(participants).forEach(participantId => {
                    if (participantId.startsWith(authStore.user.uid)) {
                        updates[participantId] = null;
                    }
                });
                await update(roomParticipantsRef, updates);
            }

            trackLeaveRoom(this.currentRoom);
            this.currentRoom = null;
            this.currentRoomData = null;
            await authStore.updateCurrentRoom(null);
        },

        async updateRoomSettings(roomId, settings) {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated || !roomId) {
                console.error('User not authenticated or no roomId provided');
                return;
            }

            const roomRef = ref(rtdb, `rooms/${roomId}`);
            const previousSettings = this.rooms[roomId] || {};

            try {
                await update(roomRef, settings);

                if (this.rooms[roomId]) {
                    this.rooms[roomId] = { ...this.rooms[roomId], ...settings };
                }

                const updatedSnapshot = await get(roomRef);
                const updatedRoomData = updatedSnapshot.val();

                if (settings.bpm !== undefined && settings.bpm !== previousSettings.bpm) {
                    trackBPMChange(roomId, {
                        previousBpm: previousSettings.bpm,
                        newBpm: settings.bpm,
                        isPlaying: updatedRoomData.isPlaying
                    });
                }

                // trackRoomSettingChange(roomId, {
                //     previousSettings,
                //     newSettings: settings,
                //     changeTime: new Date().toISOString()
                // });

                if (this.currentRoom === roomId) {
                    this.currentRoomData = updatedRoomData;
                }

            } catch (error) {
                console.error('Error updating room settings:', error);
                throw error;
            }
        },

        async updateCurrentBeat(roomId, currentBeat) {
            if (!roomId) {
                console.error('No roomId provided');
                return;
            }

            console.log(`Updating current beat for room ${roomId}: ${currentBeat}`);
            const currentBeatRef = ref(rtdb, `rooms/${roomId}/currentBeat`);
            await set(currentBeatRef, currentBeat);
            this.currentBeat = currentBeat;

            if (this.currentRoomData?.isPlaying) {
                // trackBeatProgress(roomId, {
                //     currentBeat: currentBeat,
                //     bpm: this.currentRoomData.bpm
                // });
            }
        },

        async updateCurrentAlbum(albumId) {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated || !this.currentRoom) {
                console.error('User not authenticated or not in a room');
                return;
            }

            console.log('Updating current album:', albumId);
            const albumRef = ref(rtdb, `rooms/${this.currentRoom}/currentAlbum`);
            const previousAlbumId = this.currentAlbumId;

            await set(albumRef, albumId);
            this.currentAlbumId = albumId;

            // Analytics
            trackAlbum(this.currentRoom, {
                previousAlbumId,
                newAlbumId: albumId,
            });
            if (albumId){
            trackAlbumFetch(albumId);
            }

            console.log(`Updated current album in room ${this.currentRoom}: ${albumId}`);
        },

        listenToRoomChanges() {
            if (!this.currentRoom) {
                console.error('방에 참여하지 않았습니다.');
                return;
            }

            console.log(`방 변경 사항을 듣고 있습니다: ${this.currentRoom}`);
            const roomRef = ref(rtdb, `rooms/${this.currentRoom}`);
            const metronomeStore = useMetronomeStore();

            if (this.roomListener) {
                this.roomListener();
                this.roomListener = null;
            }

            let previousParticipantCount = 0;
            let wasPlaying = false;

            this.roomListener = onValue(roomRef, (snapshot) => {
                const roomData = snapshot.val();
                if (roomData) {
                    this.rooms = { ...this.rooms, [this.currentRoom]: roomData };
                    this.currentRoomData = { ...roomData };
                    this.currentAlbumId = roomData.currentAlbum;
                    this.settingScores = roomData.settingScores || null;

                    // 설정 동기화
                    if (roomData.settingScores) {
                        // 다른 유저가 설정 중이라면 모든 컨트롤 비활성화
                        if (roomData.isPlaying) {
                            this.updateRoomSettings(this.currentRoom, { isPlaying: false });
                        }
                    }

                    const isNowPlaying = roomData.isPlaying;
                    const isPlayingChanged = wasPlaying !== isNowPlaying;
                    const currentParticipantCount = Object.keys(roomData.participants || {}).length;

                    if (currentParticipantCount !== previousParticipantCount) {
                        trackGroupSession(this.currentRoom, {
                            previousCount: previousParticipantCount,
                            currentCount: currentParticipantCount,
                            isPlaying: isNowPlaying
                        });
                        previousParticipantCount = currentParticipantCount;
                    }

                    if (isPlayingChanged) {
                        if (isNowPlaying && !roomData.settingScores) {
                            metronomeStore.start(roomData.startTime);
                            trackPlay(this.currentRoom, {
                                bpm: roomData.bpm,
                                timeSignature: roomData.timeSignature,
                                soundType: roomData.soundType,
                                totalParticipants: currentParticipantCount,
                                isAlbumPlaying: !!this.currentAlbumId,
                                measure: metronomeStore.currentMeasure
                            });
                        } else {
                            console.log('Tracking stop event...');
                            trackStop(this.currentRoom);
                            console.log('Stopping metronome...');
                            metronomeStore.stop();
                            // Stop 이벤트 먼저 트래킹
                            console.log('Tracking stop event...');
                            trackStop(this.currentRoom, {
                                isAlbumPlaying: !!this.currentAlbumId,
                                measure: metronomeStore.currentMeasure
                            });

                            // 이벤트 트래킹 후 메트로놈 중지
                            console.log('Stopping metronome...');
                            metronomeStore.stop();
                        }
                        wasPlaying = isNowPlaying;
                    }

                    // 반복 설정 동기화
                    if (roomData.repeatSettings) {
                        const { startMeasure, endMeasure, isEnabled } = roomData.repeatSettings;
                        metronomeStore.setLoop(isEnabled, startMeasure, endMeasure);
                    }

                    // 템포 설정 동기화
                    if (roomData.tempoSettings) {
                        const { multiplier } = roomData.tempoSettings;
                        metronomeStore.setPlaybackSpeed(multiplier);
                    }

                    if (roomData.bpm !== metronomeStore.bpm) {
                        metronomeStore.updateBPM(roomData.bpm);
                    }

                    if (roomData.currentBeat !== metronomeStore.currentBeat) {
                        metronomeStore.setCurrentBeat(roomData.currentBeat);
                        if (isNowPlaying) {
                            // trackBeatProgress(this.currentRoom, {
                            //     currentBeat: roomData.currentBeat,
                            //     bpm: roomData.bpm
                            // });
                        }
                    }

                    if (!isNowPlaying && roomData.lastStoppedMeasure !== metronomeStore.currentMeasure) {
                        metronomeStore.setCurrentMeasure(roomData.lastStoppedMeasure);
                        trackMeasureChange(this.currentRoom, {
                            previousMeasure: metronomeStore.currentMeasure,
                            currentMeasure: roomData.lastStoppedMeasure,
                            timeSignature: roomData.timeSignature,
                            totalBeats: roomData.totalBeats,
                            currentBeat: roomData.currentBeat
                        });
                    }
                } else {
                    console.log(`방 ${this.currentRoom}이 더 이상 존재하지 않습니다.`);
                    this.rooms = { ...this.rooms };
                    delete this.rooms[this.currentRoom];

                    const metronomeStore = useMetronomeStore();
                    metronomeStore.stop();

                    trackLeaveRoom(this.currentRoom);

                    this.currentRoom = null;
                    this.currentAlbumId = null;
                    this.currentRoomData = null;
                    this.settingScores = null;

                    if (this.settingTimeout) {
                        clearTimeout(this.settingTimeout);
                        this.settingTimeout = null;
                    }

                    if (this.roomListener) {
                        this.roomListener();
                        this.roomListener = null;
                    }
                }
            });
        },

        async checkRoomParticipation() {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated) {
                return false;
            }

            await authStore.fetchUserProfile();
            const currentRoomId = authStore.currentRoomId;

            if (!currentRoomId) {
                return false;
            }

            const roomRef = ref(rtdb, `rooms/${currentRoomId}`);
            try {
                const snapshot = await get(roomRef);
                if (snapshot.exists()) {
                    this.currentRoom = currentRoomId;
                    await this.fetchCurrentRoom(currentRoomId);
                    this.listenToRoomChanges();
                    return true;
                } else {
                    await authStore.updateCurrentRoom(null);
                    this.currentRoom = null;
                    this.currentRoomData = null;
                    return false;
                }
            } catch (error) {
                console.error("Error checking room participation:", error);
                return false;
            }
        },
    },
});