//router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/store/auth'
import { useSeoScoreStore } from '@/store/seoscore'

const routes = [
    {
        path: '/',
        name: 'Landing',
        component: () => import('@/screens/LandingScreen.vue'),
        meta: {
            title: 'FEAT - 무료 악보 & 악기 연습 툴',
            description: 'FEAT의 무료 악보와 싱크로나이즈드 메트로놈을 이용해 멤버들과 함께 합주하고 악기를 연습해보세요.'
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/screens/LoginScreen.vue'),
        meta: {
            title: '로그인 | KAMO ID',
            description: 'KAMO ID로 로그인하여 FEAT를 이용하세요.'
        }
    },
    {
        path: '/find-id',
        name: 'FindID',
        component: () => import('@/screens/FindIDScreen.vue'),
        meta: {
            title: '비밀번호 찾기 | KAMO ID',
            description: '비밀번호를 잊어버리셨나요?'
        }
    },
    {
        path: '/room',
        name: 'Room',
        component: () => import('@/screens/RoomScreen.vue'),
        meta: {
            title: '방 생성 | FEAT',
            description: '합주실을 생성하고 멤버들과 함께 합주해보세요.',
            requiresAuth: true
        }
    },
    {
        path: '/main/:roomId',
        name: 'Main',
        component: () => import('@/screens/MainScreen.vue'),
        props: true,
        meta: {
            title: '합주실 | FEAT',
            description: 'FEAT의 무료 악보와 싱크로나이즈드 메트로놈을 이용해 멤버들과 함께 합주하고 악기를 연습해보세요.',
            requiresAuth: true
        }
    },
    {
        path: '/signup',
        name: 'Signup',
        component: () => import('@/screens/SignupScreen.vue'),
        meta: {
            title: '회원가입 | KAMO ID',
            description: 'KAMO ID를 생성하고 다양한 기능을 이용해보세요.'
        }
    },
    {
        path: '/setacc',
        name: 'setacc',
        component: () => import('@/screens/SettingAccount.vue'),
        meta: {
            requiresAuth: true,
            title: '계정 설정 | KAMO ID',
            discription: 'KAMO ID를 설정해 보세요.'
        }
    },
    {
        path: '/temple',
        name: 'temple',
        component: () => import('@/screens/MohapTemple.vue'),
        meta: {
            requiresAuth: true,
            title: '이곳에 허락없이 들어온 자 돌돔의 심판을 받을 것이니',
        }
    },
    {
        path: '/setgoogle',
        name: 'GoogleSignupComplete',
        component: () => import('@/screens/GoogleSignupComplete.vue'),
        meta: {
            requiresAuth: true,
            allowIncompleteProfile: true,
            title: 'Google로 회원가입 | KAMO ID',
        }
    },
    {
        path: '/Board',
        name: 'Board',
        component: () => import('@/screens/BoardScreen.vue'),
        meta: {
            requiresAuth: true,
            title: '공지사항 | FEAT',
        }
    },
    {
        path: '/scores/:id',
        name: 'SeoScore',
        component: () => import('@/screens/SeoScreen.vue'),
        meta: {
            public: true,
            title: '무료 악보 | FEAT',
            description: 'FEAT가 제공하는 무료 악보로 멤버들과 합주하고, 악기를 연습해보세요.'
        },
        props: true,
        async beforeEnter(to, from, next) {
            const isBot = /bot|crawler|spider|crawling/i.test(navigator.userAgent)
            const seoScoreStore = useSeoScoreStore()

            try {
                if (isBot) {
                    await seoScoreStore.fetchScoreData(to.params.id)
                    const metadata = seoScoreStore.scoreMetadata
                    if (metadata) {
                        document.title = `무료 악보 | ${metadata.title} - ${metadata.artist}`

                        let description = document.querySelector('meta[name="description"]')
                        if (!description) {
                            description = document.createElement('meta')
                            description.setAttribute('name', 'description')
                            document.head.appendChild(description)
                        }
                        description.content = `${metadata.title} - ${metadata.artist} | 무료 악보`
                    }
                }
                next()
            } catch (error) {
                console.error('Error in route navigation:', error)
                next()
            }
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore()
    await authStore.checkAuth()

    if (to.meta.title) {
        document.title = to.meta.title
        let ogTitle = document.querySelector('meta[property="og:title"]')
        if (!ogTitle) {
            ogTitle = document.createElement('meta')
            ogTitle.setAttribute('property', 'og:title')
            document.head.appendChild(ogTitle)
        }
        ogTitle.content = to.meta.title
    }

    if (to.meta.description) {
        let description = document.querySelector('meta[name="description"]')
        if (!description) {
            description = document.createElement('meta')
            description.setAttribute('name', 'description')
            document.head.appendChild(description)
        }
        description.content = to.meta.description
    }

    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const allowIncompleteProfile = to.matched.some(record => record.meta.allowIncompleteProfile)
    const publicPages = ['Landing', 'Login', 'FindID', 'Signup', 'ScoreSample', 'SeoScore']

    if (requiresAuth && !authStore.isAuthenticated) {
        next('/login')
    } else if (authStore.isAuthenticated) {
        const isProfileComplete = authStore.userProfile?.nickname && authStore.userProfile?.profileImageId

        if (!isProfileComplete && !allowIncompleteProfile && to.name !== 'GoogleSignupComplete') {
            next('/setgoogle')
        } else if (isProfileComplete && to.name === 'GoogleSignupComplete') {
            next('/room')
        } else if (publicPages.includes(to.name)) {
            next('/room')
        } else {
            next()
        }
    } else if (publicPages.includes(to.name)) {
        next()
    } else {
        next('/login')
    }
})

export default router
