/* eslint-disable */

import { defineStore } from 'pinia'
import { useNetworkLatencyStore } from './networkLatencyStore';
import { useXMLParserStore } from '@/store/XMLParser.js';
import { useAudioEngineStore } from './audioEngine';
import { useScoreInfoStore } from './InfoParser';
import * as Tone from 'tone'

export const useMidiStore = defineStore('midi', {
  state: () => ({
    midiData: null,
    tracks: [],
    tempo: 120,
    timeSignature: [4, 4],
    keySignature: 'C',
    markers: [],
    lyrics: [],
    isPlaying: false,
    currentTime: 0,
    duration: 0,
    error: null,
  }),

  actions: {
    getInstrumentType(partInfo) {
      let result = 'etc';

      if (!partInfo || !partInfo.name) {
        console.log(`[분류로그] 입력: ${partInfo?.name || '없음'} => etc (유효하지 않은 입력)`);
        return result;
      }

      const name = partInfo.name.toLowerCase();

      if (name.includes('bass') ||
        name.includes('베이스')) {
        result = 'bass';
      } else if (name.includes('guitar') ||
        name.includes('gtr') ||
        name.includes('기타')) {
        result = 'guitar';
      } else if (name.includes('piano') ||
        name.includes('keys') ||
        name.includes('keyboard') ||
        name.includes('synth') ||
        name.includes('신디') ||
        name.includes('피아노')) {
        result = 'piano';
      } else if (name.includes('voice') ||
        name.includes('vocal') ||
        name.includes('보컬') ||
        name.includes('보이스') ||
        name.includes('oboe') ||
        name.includes('오보에') ||
        name.includes('melody') ||
        name.includes('멜로디') ||
        name.includes('리드') ||
        name.includes('lead')) {
        result = 'melody';
      } else if (name.includes('drum') ||
        name.includes('drums') ||
        name.includes('드럼') ||
        name.includes('snare') ||
        name.includes('kick') ||
        name.includes('cymbal') ||
        name.includes('hi-hat') ||
        name.includes('hihat')) {
        result = 'drums';
      }

      console.log(`[분류로그] 입력: ${partInfo.name} => ${result}`);
      return result;
    },

    getRepresentativePartsFromGroups() {
      const scoreInfoStore = useScoreInfoStore();
      const partGroups = scoreInfoStore.getPartGroups;

      if (!partGroups || partGroups.length === 0) {
        return [];
      }

      // 각 파트 그룹의 첫 번째 파트만 선택
      const representativeParts = partGroups.map(group => {
        if (group.parts && group.parts.length > 0) {
          return group.parts[0].id; // 각 그룹의 첫 번째 파트의 ID를 반환
        }
        return null;
      }).filter(id => id !== null); // null 값 제거

      return representativeParts;
    },

    calculatePickupMeasureDuration(measure, divisions, tempo) {
      // 마디의 모든 노트/쉼표 duration을 합산
      let totalDuration = 0;
      measure.elements.forEach(element => {
        if (element.duration) {
          totalDuration += parseFloat(element.duration);
        }
      });

      // divisions와 템포를 사용하여 실제 시간(초) 계산
      return (totalDuration / divisions) * (60 / tempo);
    },

    loadMIDIFromXML() {
      const xmlParserStore = useXMLParserStore();
      const audioEngineStore = useAudioEngineStore();
      const scoreInfoStore = useScoreInfoStore();
      const scorePartwise = xmlParserStore.scorePartwise;

      if (!scorePartwise || !scorePartwise.parts || scorePartwise.parts.length === 0) {
        console.error('No valid score data found');
        return null;
      }

      console.log(scorePartwise);

      const representativePartIds = this.getRepresentativePartsFromGroups();
      let currentTransposition = 0;

      scorePartwise.parts.forEach((part, partIndex) => {
        const partInfo = scoreInfoStore.getParts.find(p => p.id === scorePartwise.partList.scoreParts[partIndex].id);

        if (!partInfo || !representativePartIds.includes(partInfo.id)) {
          return;
        }

        const instrumentType = this.getInstrumentType(partInfo);

        part.measures.forEach((measure, index) => {
          const engineMeasure = audioEngineStore.measures.find(m => m.measureIndex == index);

          if (!engineMeasure) {
            console.warn(`No measure data found for index ${index}`);
            return;
          }

          if (!engineMeasure.timelineEvents) {
            engineMeasure.timelineEvents = [];
          }

          let currentDivision = parseFloat(measure.attributes.divisions);
          const currentTempo = Number(engineMeasure.bpm);
          const secondsPerQuarter = 60 / currentTempo;

          // 보이스별 시간 추적을 위한 객체
          const voiceTimes = {};

          measure.elements.forEach((element) => {
            const voice = element.voice || '1';
            if (!voiceTimes[voice]) voiceTimes[voice] = 0;

            if ((element.pitch || element.pitches) && element.duration && instrumentType !== 'drums') {
              const pitchesToProcess = element.pitches || [element.pitch];
              const duration = (parseFloat(element.duration) / currentDivision) * secondsPerQuarter;

              pitchesToProcess.forEach(pitch => {
                const finalPitch = this.transposePitch(pitch, currentTransposition);
                const event = {
                  type: instrumentType,
                  time: voiceTimes[voice],
                  duration: duration,
                  velocity: instrumentType === 'melody' ? 0.9 : 0.8,
                  data: {
                    note: `${finalPitch.step}${finalPitch.alter === 1 ? '#' : finalPitch.alter === -1 ? 'b' : ''}${finalPitch.octave}`
                  }
                };
                engineMeasure.timelineEvents.push(event);
              });
              voiceTimes[voice] += duration;
            } else if (element.unpitched && element.duration && instrumentType === 'drums') {
              const pitchesToProcess = element.pitchesDisplay || [element.unpitched];
              let duration = (parseFloat(element.duration) / currentDivision) * secondsPerQuarter;

              pitchesToProcess.forEach(pitch => {
                const displayNote = `${pitch.displayStep}${pitch.displayOctave}`;
                let midiNote;

                // 드럼 노트 매핑
                switch (displayNote) {
                  case 'G5': // 하이햇
                    midiNote = 'F#1';
                    break;
                  case 'A5': // 크래쉬
                  case 'B5':
                    midiNote = 'C#2';
                    duration = 1.5;
                    break;
                  case 'F5': // 라이드
                    midiNote = 'Eb2';
                    break;
                  case 'F4': // 킥
                    midiNote = 'C1';
                    break;
                  case 'C5': // 스네어
                    midiNote = 'D1';
                    break;
                  case 'E5': // 하이 톰
                    midiNote = 'D2';
                    break;
                  case 'D5': // 미드 톰
                    midiNote = 'B1';
                    break;
                  case 'A4': // 로우 톰
                    midiNote = 'F1';
                    break;
                  default:
                    console.warn(`Unmapped drum note: ${displayNote}`);
                    return;
                }

                const event = {
                  type: 'drums',
                  time: voiceTimes[voice],
                  duration: duration,
                  velocity: this.calculateVelocity(measure, element),
                  data: {
                    note: midiNote
                  }
                };
                engineMeasure.timelineEvents.push(event);
              });
              voiceTimes[voice] += (parseFloat(element.duration) / currentDivision) * secondsPerQuarter;
            } else if (element.rest && element.duration) {
              const restDuration = (parseFloat(element.duration) / currentDivision) * secondsPerQuarter;
              voiceTimes[voice] += restDuration;
            }
          });
          //만약 못갖춘마디면 마디의 길이를 1번 voice의 길이에 맞게 재조정
          if (engineMeasure.measure === "0" || engineMeasure.measure === 0) {  // 문자열 "0"과 숫자 0 모두 체크
            engineMeasure.duration = voiceTimes['1'];
            // engineMeasure.duration보다 늦게 시작하는 이벤트 제거
            engineMeasure.timelineEvents = engineMeasure.timelineEvents.filter(event => {
              return event.time + event.duration <= engineMeasure.duration;
            });
          }
          if (engineMeasure?.swing) {
            const BPM = engineMeasure.bpm;
            const MEASURES_PER_MINUTE = BPM / engineMeasure.timeSignature[0];
            const SECONDS_PER_MEASURE = 60 / MEASURES_PER_MINUTE;
            const TOLERANCE = 0.001;

            if (engineMeasure.swing.type === "16th") {
              const SIXTEENTH_DURATION = SECONDS_PER_MEASURE / 16;
              const ratio = engineMeasure.swing.first / (engineMeasure.swing.first + engineMeasure.swing.second);
              const offsetTime = SIXTEENTH_DURATION * (ratio - 0.5) * 2;

              engineMeasure.timelineEvents.forEach(event => {
                const startTime = event.time / SIXTEENTH_DURATION;
                const endTime = (event.time + event.duration) / SIXTEENTH_DURATION;

                // 시작 위치가 16분음표 위치인 경우 시간 조정
                const startDiff = Math.abs(startTime - Math.round(startTime));
                if (startDiff <= TOLERANCE) {
                  const startPosition = Math.round(startTime);
                  if (startPosition % 2 === 1) {  // 홀수 위치면
                    event.time += offsetTime;    // 늦춤
                  }
                }

                // 끝 위치가 16분음표 위치인 경우 duration 조정
                const endDiff = Math.abs(endTime - Math.round(endTime));
                if (endDiff <= TOLERANCE) {
                  const startPosition = Math.round(startTime);
                  const endPosition = Math.round(endTime);

                  if (startPosition % 2 === 0 && endPosition % 2 === 1) {
                    event.duration += offsetTime;
                  }
                  else if (startPosition % 2 === 1 && endPosition % 2 === 0) {
                    event.duration -= offsetTime;
                  }
                }
              });
            } else if (engineMeasure.swing.type === "eighth") {
              const EIGHTH_DURATION = SECONDS_PER_MEASURE / 8;
              const ratio = engineMeasure.swing.first / (engineMeasure.swing.first + engineMeasure.swing.second);
              const offsetTime = EIGHTH_DURATION * (ratio - 0.5) * 2;

              engineMeasure.timelineEvents.forEach(event => {
                const startTime = event.time / EIGHTH_DURATION;
                const endTime = (event.time + event.duration) / EIGHTH_DURATION;

                // 시작 위치가 8분음표 위치인 경우 시간 조정
                const startDiff = Math.abs(startTime - Math.round(startTime));
                if (startDiff <= TOLERANCE) {
                  const startPosition = Math.round(startTime);
                  if (startPosition % 2 === 1) {  // 홀수 위치면
                    event.time += offsetTime;    // 늦춤
                  }
                }

                // 끝 위치가 8분음표 위치인 경우 duration 조정
                const endDiff = Math.abs(endTime - Math.round(endTime));
                if (endDiff <= TOLERANCE) {
                  const startPosition = Math.round(startTime);
                  const endPosition = Math.round(endTime);

                  if (startPosition % 2 === 0 && endPosition % 2 === 1) {
                    event.duration += offsetTime;
                  }
                  else if (startPosition % 2 === 1 && endPosition % 2 === 0) {
                    event.duration -= offsetTime;
                  }
                }
              });
            }
          }
        });
      });
    },

    calculateVelocity(measure, note) {
      // 스네어 드럼 체크 (C5)
      if (note.unpitched &&
        note.unpitched.displayStep === 'C' &&
        note.unpitched.displayOctave === '5') {

        // measure의 다른 드럼 노트에 accent가 있는지 체크
        const hasAccentInOtherDrums = measure.elements.some(element =>
          element.notations?.articulations?.accent
        );

        // 악센트 체크
        const hasAccent = note.notations?.articulations?.accent;

        if (!hasAccentInOtherDrums) {
          return 0.8;  // 다른 드럼에 accent 없으면 0.8
        }
        return hasAccent ? 1 : 0.3;  // 있으면 원래 로직대로
      }
      return 0.8;
    },

    transposePitch(pitch, transposition) {
      const stepToIndex = {
        'C': 0, 'B#': 0,
        'C#': 1, 'Db': 1,
        'D': 2,
        'D#': 3, 'Eb': 3,
        'E': 4, 'Fb': 4,
        'F': 5, 'E#': 5,
        'F#': 6, 'Gb': 6,
        'G': 7,
        'G#': 8, 'Ab': 8,
        'A': 9,
        'A#': 10, 'Bb': 10,
        'B': 11, 'Cb': 11
      };

      const pitchClass = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];

      let originalStep = pitch.step;
      let alter = pitch.alter ? parseInt(pitch.alter) : 0;
      let octave = parseInt(pitch.octave);

      let noteWithAlter = originalStep;
      if (alter === 1) noteWithAlter += '#';
      if (alter === -1) noteWithAlter += 'b';

      let semitone = stepToIndex[noteWithAlter];
      if (semitone === undefined) {
        throw new Error('Invalid note combination: ' + noteWithAlter);
      }

      let octaveAdjustment = 0;
      if (noteWithAlter === 'B#') octaveAdjustment = 1;  // B# -> C는 옥타브 +1
      if (noteWithAlter === 'Cb') octaveAdjustment = -1; // Cb -> B는 옥타브 -1

      let absoluteSemitone = semitone + (octave + octaveAdjustment) * 12;
      let newAbsoluteSemitone = absoluteSemitone + transposition;

      let newOctave = Math.floor(newAbsoluteSemitone / 12);
      let newSemitone = ((newAbsoluteSemitone % 12) + 12) % 12;  // 음수 처리를 위해

      newOctave = Math.max(0, Math.min(9, newOctave));

      let newNote = pitchClass[newSemitone];
      let newAlter = 0;

      if (newNote.includes('#')) {
        newAlter = 1;
        newNote = newNote[0];
      }

      return {
        step: newNote,
        alter: newAlter,
        octave: newOctave
      };
    },

    getPitchAndAlter(pitchString) {
      if (pitchString.length === 1) {
        return [pitchString, 0];
      } else if (pitchString[1] === '#') {
        return [pitchString[0], 1];
      } else {
        return [pitchString[0], -1];
      }
    },
  }
});