import { createPinia } from 'pinia'
import { defineStore } from 'pinia'

export const useMainStore = defineStore('main', {
    state: () => ({
        user: null,
        currentComponent: 'ScoreBrowser'
    }),
    actions: {
        setUser(user) {
            this.user = user
        },
        setCurrentComponent(component) {
            this.currentComponent = component
        }
    }
})

export const pinia = createPinia()