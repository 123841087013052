import { defineStore } from 'pinia';
import { collection, getDocs, setDoc, doc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref as storageRef, getDownloadURL } from 'firebase/storage';
import { db } from '@/firebase';
import { useAuthStore } from '@/store/auth';
import { trackAlbumViewDuration } from '@/analytics';

export const useAlbumStore = defineStore('album', {
    state: () => ({
        albums: [],
        currentMusicXml: null,
        currentInfoXml: null,
        currentMidiFile: null,
        loading: false,
        error: null,
        currentAlbum: null,
    }),
    actions: {
        async fetchAlbums() {
            this.loading = true;
            this.error = null;
            try {
                const querySnapshot = await getDocs(collection(db, 'tracks'));
                this.albums = await Promise.all(querySnapshot.docs.map(async doc => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        ...data,
                        imageUrl: await this.getAlbumImageUrl(doc.id),
                        isFavorite: false,
                        genre: data.genre || {},
                    };
                }));
                await this.fetchFavorites();
                console.log("Albums fetched successfully:", this.albums);
            } catch (error) {
                console.error("Error fetching albums:", error);
                this.error = `Error fetching albums: ${error.message}`;
            } finally {
                this.loading = false;
            }
        },

        async getAlbumImageUrl(albumId) {
            const storage = getStorage();
            const imageRef = storageRef(storage, `assets/album/${albumId}.jpg`);
            try {
                return await getDownloadURL(imageRef);
            } catch (error) {
                console.error("Error getting album image:", error);
                return null;
            }
        },

        async fetchAlbumFiles(albumId) {
            this.loading = true;
            this.error = null;
            try {
                const storage = getStorage();

                // Fetch main MusicXML
                const musicXmlRef = storageRef(storage, `data/${albumId}/${albumId}.xml`);
                const musicXmlUrl = await getDownloadURL(musicXmlRef);
                const musicXmlResponse = await fetch(musicXmlUrl);
                if (!musicXmlResponse.ok) {
                    throw new Error(`HTTP error! status: ${musicXmlResponse.status}`);
                }
                this.currentMusicXml = await musicXmlResponse.text();

                // Fetch info XML
                const infoXmlRef = storageRef(storage, `data/${albumId}/info_${albumId}.xml`);
                const infoXmlUrl = await getDownloadURL(infoXmlRef);
                const infoXmlResponse = await fetch(infoXmlUrl);
                if (!infoXmlResponse.ok) {
                    throw new Error(`HTTP error! status: ${infoXmlResponse.status}`);
                }
                this.currentInfoXml = await infoXmlResponse.text();

                // Fetch MIDI file
                const midiRef = storageRef(storage, `data/${albumId}/${albumId}.midi`);
                const midiUrl = await getDownloadURL(midiRef);
                const midiResponse = await fetch(midiUrl);
                if (!midiResponse.ok) {
                    throw new Error(`HTTP error! status: ${midiResponse.status}`);
                }
                this.currentMidiFile = await midiResponse.arrayBuffer();

                console.log("MusicXML, InfoXML, and MIDI files fetched successfully");
            } catch (error) {
                console.error("Error fetching album files:", error);
                this.error = `Error fetching album files: ${error.message}`;
            } finally {
                this.loading = false;
            }
        },

        async toggleFavorite(albumId) {
            const authStore = useAuthStore();
            const userId = authStore.user.uid;
            const favoriteRef = doc(db, `users/${userId}/favorites/${albumId}`);

            const album = this.albums.find(a => a.id === albumId);
            if (album) {
                album.isFavorite = !album.isFavorite;
                if (album.isFavorite) {
                    await setDoc(favoriteRef, {});
                } else {
                    await deleteDoc(favoriteRef);
                }
            }
        },

        async fetchFavorites() {
            const authStore = useAuthStore();
            const userId = authStore.user.uid;
            const favoritesRef = collection(db, `users/${userId}/favorites`);
            const favoritesSnapshot = await getDocs(favoritesRef);

            const favoriteIds = favoritesSnapshot.docs.map(doc => doc.id);
            this.albums.forEach(album => {
                album.isFavorite = favoriteIds.includes(album.id);
            });
        },

        setCurrentAlbum(album) {
            if (this.currentAlbum?.id !== album?.id) {
                if (this.currentAlbum) {
                    trackAlbumViewDuration(this.currentAlbum.id);
                }
                this.currentAlbum = album;
            
            }
        },

        clearCurrentAlbum() {
            if (this.currentAlbum) {
                trackAlbumViewDuration(this.currentAlbum.id);
                this.currentAlbum = null;
            }
        },
    },
});