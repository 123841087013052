export { koreanInstrumentNames };

const koreanInstrumentNames = {
    0: "어쿠스틱 그랜드 피아노",
    1: "브라이트 어쿠스틱 피아노",
    2: "일렉트릭 그랜드 피아노",
    3: "혼키통크 피아노",
    4: "일렉트릭 피아노 1",
    5: "일렉트릭 피아노 2",
    6: "하프시코드",
    7: "클라비",
    8: "첼레스타",
    9: "글로켄슈필",
    10: "뮤직박스",
    11: "비브라폰",
    12: "마림바",
    13: "실로폰",
    14: "튜블러 벨",
    15: "덜시머",
    16: "드로우바 오르간",
    17: "퍼커시브 오르간",
    18: "록 오르간",
    19: "처치 오르간",
    20: "리드 오르간",
    21: "아코디언",
    22: "하모니카",
    23: "탱고 아코디언",
    24: "어쿠스틱 기타 (나일론)",
    25: "어쿠스틱 기타 (스틸)",
    26: "일렉트릭 기타 (재즈)",
    27: "일렉트릭 기타 (클린)",
    28: "일렉트릭 기타 (뮤트)",
    29: "오버드라이브 기타",
    30: "디스토션 기타",
    31: "기타 하모닉스",
    32: "어쿠스틱 베이스",
    33: "일렉트릭 베이스 (핑거)",
    34: "일렉트릭 베이스 (픽)",
    35: "프레트리스 베이스",
    36: "슬랩 베이스 1",
    37: "슬랩 베이스 2",
    38: "신스 베이스 1",
    39: "신스 베이스 2",
    40: "바이올린",
    41: "비올라",
    42: "첼로",
    43: "콘트라베이스",
    44: "트레몰로 현",
    45: "피치카토 현",
    46: "하프",
    47: "팀파니",
    48: "현 앙상블 1",
    49: "현 앙상블 2",
    50: "신스 현 1",
    51: "신스 현 2",
    52: "합창 아~",
    53: "보이스 우~",
    54: "신스 합창",
    55: "오케스트라 히트",
    56: "트럼펫",
    57: "트롬본",
    58: "튜바",
    59: "뮤트 트럼펫",
    60: "프렌치 호른",
    61: "브라스 섹션",
    62: "신스 브라스 1",
    63: "신스 브라스 2",
    64: "소프라노 색소폰",
    65: "알토 색소폰",
    66: "테너 색소폰",
    67: "바리톤 색소폰",
    68: "오보에",
    69: "잉글리시 호른",
    70: "바순",
    71: "클라리넷",
    72: "피콜로",
    73: "플룻",
    74: "리코더",
    75: "팬 플룻",
    76: "병 부는 소리",
    77: "샤쿠하치",
    78: "휘슬",
    79: "오카리나",
    80: "리드 1 (스퀘어)",
    81: "리드 2 (톱니파)",
    82: "리드 3 (칼리오페)",
    83: "리드 4 (치프)",
    84: "리드 5 (차랑)",
    85: "리드 6 (보이스)",
    86: "리드 7 (5도)",
    87: "리드 8 (베이스+리드)",
    88: "패드 1 (뉴에이지)",
    89: "패드 2 (웜)",
    90: "패드 3 (폴리신스)",
    91: "패드 4 (합창)",
    92: "패드 5 (보우드)",
    93: "패드 6 (메탈릭)",
    94: "패드 7 (헤일로)",
    95: "패드 8 (스위프)",
    96: "FX 1 (비)",
    97: "FX 2 (사운드트랙)",
    98: "FX 3 (크리스탈)",
    99: "FX 4 (분위기)",
    100: "FX 5 (브라이트니스)",
    101: "FX 6 (고블린)",
    102: "FX 7 (에코)",
    103: "FX 8 (사이파이)",
    104: "시타르",
    105: "반조",
    106: "샤미센",
    107: "고토",
    108: "칼림바",
    109: "백파이프",
    110: "피들",
    111: "샤나이",
    112: "팅클 벨",
    113: "아고고",
    114: "스틸 드럼",
    115: "우드블록",
    116: "타이코 드럼",
    117: "멜로딕 톰",
    118: "신스 드럼",
    119: "리버스 심벌",
    120: "기타 프렛 노이즈",
    121: "브레스 노이즈",
    122: "해변",
    123: "새 지저귐",
    124: "전화 벨",
    125: "헬리콥터",
    126: "박수",
    127: "총소리"
};