<template>
  <div
    :class="{
      'landing-page': isLandingPage,
      'light-mode': !themeStore.isDarkMode,
      'ios-device': isIOS,
    }"
  >
    <div id="app">
      <router-view></router-view>
    </div>
    
    <!-- 오버레이 -->
    <div v-if="showOverlay" class="overlay">
      <div class="overlay-content">
        <h3>Sound Context 활성화</h3>
        <p>새로고침, 또는 장시간 미사용으로 Sound Context가 감지되지 않습니다.<br>계속 이용하려면 "활성화" 버튼을 눌러 주세요.</p>
        <button @click="handleOverlayClose">활성화</button>
      </div>
    </div>

    <!-- 설문 모달 -->
    <SurveyModal 
      v-if="showSurveyModal"
      :show="showSurveyModal"
      :userId="authStore.user?.uid"
      @close="showSurveyModal = false"
    />
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { useThemeStore } from '@/store/themeStore';
import { useAuthStore } from '@/store/auth';
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { trackUserRetention } from '@/analytics';
import errorCharPng from '@/assets/feat_logo_min.png';
import * as Tone from 'tone';
import { useNetworkLatencyStore } from './store/networkLatencyStore';
import { getDatabase, ref as dbRef, onValue, off } from 'firebase/database';
import { useAudioEngineStore } from './store/audioEngine';
import SurveyModal from '@/components/general/survey/Survey_0_1_6.vue';
import { collection, query, where, getDocs, doc, onSnapshot } from 'firebase/firestore';
import { db } from '@/firebase';

export default {
  name: 'App',
  components: {
    SurveyModal
  },
  setup() {
    const { t } = useI18n();
    const themeStore = useThemeStore();
    const authStore = useAuthStore();
    const router = useRouter();
    const route = useRoute();
    const rtdb = getDatabase();
    const networkLatencyStore = useNetworkLatencyStore();

    const isLandingPage = computed(() => route.name === 'seo');
    const isIOS = ref(false);
    const safeAreaBottom = ref(0);
    const isProcessingRoute = ref(false);
    const showSurveyModal = ref(false);
    
    // Survey check variables
    let surveyUnsubscribe = null;
    let logsUnsubscribe = null;

    // Sound Context 관련
    const audioEngine = useAudioEngineStore();
    const initializeAudio = async () => {
      if (Tone.context.state !== 'running') {
        try {
          await Tone.start();
          const initSynth = new Tone.Synth({
            volume: -59
          }).toDestination();
          initSynth.triggerAttackRelease("C4", 0.1);
          console.log("Audio context initialized");
        } catch (error) {
          console.error("Failed to initialize audio:", error);
        }
      }
      if (!audioEngine.isInitialized) {
        audioEngine.initializeSynths();
      }
    };

    // Survey 관련
    const setupSurveyCheck = (userId) => {
      if (!userId) return;

      // 먼저 서베이 완료 여부 확인
      surveyUnsubscribe = onSnapshot(doc(db, `users/${userId}/survey/survey1`), async (surveyDoc) => {
        if (surveyDoc.exists()) {
          // 이미 서베이를 완료했으면 리스너 제거
          cleanupSurveyListeners();
          return;
        }

        // fetchAlbum 로그 수 확인
        const logsRef = collection(db, `users/${userId}/logs`);
        const q = query(logsRef, where('timestamp', '!=', null));
        const querySnapshot = await getDocs(q);
        
        const fetchCount = querySnapshot.docs.filter(doc => 
          doc.id.includes('_fetchAlbum')
        ).length;

        if (fetchCount >= 10) {
          showSurveyModal.value = true;
          cleanupSurveyListeners();
        }
      });
    };

    const cleanupSurveyListeners = () => {
      if (surveyUnsubscribe) {
        surveyUnsubscribe();
        surveyUnsubscribe = null;
      }
      if (logsUnsubscribe) {
        logsUnsubscribe();
        logsUnsubscribe = null;
      }
    };

    // 방 상태 관리 리스너
    let roomsListener = null;

    const handleRoomChange = async (userId, snapshot) => {
      if (isProcessingRoute.value) return;
      if (!snapshot.exists()) return;
      
      try {
        isProcessingRoute.value = true;
        const rooms = snapshot.val();
        let foundRoom = null;
        
        for (const [roomId, room] of Object.entries(rooms)) {
          if (room.participants && room.participants[userId]) {
            foundRoom = { id: roomId, ...room };
            break;
          }
        }

        const currentRouteName = route.name;
        const excludedRoutes = [
          'Landing', 
          'Login', 
          'FindID', 
          'Signup', 
          'CreateRoom', 
          'setacc', 
          'temple', 
          'GoogleSignupComplete',
          'SeoScore'
        ];

        await authStore.updateCurrentRoom(foundRoom?.id || null);

        if (foundRoom) {
          if (currentRouteName !== 'Main' || route.params.roomId !== foundRoom.id) {
            await router.replace({ name: 'Main', params: { roomId: foundRoom.id } });
          }
        } else {
          if (!excludedRoutes.includes(currentRouteName)) {
            await router.replace({ name: 'Room' });
          }
        }
      } catch (error) {
        console.error('Error handling room change:', error);
      } finally {
        isProcessingRoute.value = false;
      }
    };

    const setupRoomListener = (userId) => {
      if (!userId) return;
      
      if (roomsListener) {
        off(dbRef(rtdb, 'rooms'), 'value', roomsListener);
      }

      roomsListener = onValue(
        dbRef(rtdb, 'rooms'),
        (snapshot) => handleRoomChange(userId, snapshot),
        (error) => console.error('Room listener error:', error)
      );
    };

    const showOverlay = ref(false);
    const handleOverlayClose = () => {
      showOverlay.value = false;
      networkLatencyStore.remeasureLatency();
      localStorage.setItem('lastOverlayCloseTime', Date.now().toString());
    };

    const checkOverlayCondition = () => {
      const publicPages = ['Landing', 'Login', 'FindID', 'Signup', 'Room', 'seo'];
      if (publicPages.includes(route.name)) return;

      const hasLoadedBefore = sessionStorage.getItem('hasLoadedBefore');
      if (!hasLoadedBefore) {
        sessionStorage.setItem('hasLoadedBefore', 'true');
        return;
      }

      const lastOverlayCloseTime = parseInt(localStorage.getItem('lastOverlayCloseTime') || '0', 10);
      const now = Date.now();
      const thirtyMinutes = 30 * 60 * 1000;

      const isReloaded = sessionStorage.getItem('isReloaded');
      if (isReloaded) {
        showOverlay.value = true;
      } else {
        sessionStorage.setItem('isReloaded', 'true');
      }

      if (now - lastOverlayCloseTime > thirtyMinutes) {
        showOverlay.value = true;
      }
    };

    // Event handlers
    const handleButtonClick = (event) => {
      if (event.target.tagName === 'BUTTON') {
        initializeAudio();
      }
    };

    let lastTouchEnd = 0;
    const handleTouchEnd = (event) => {
      const now = (new Date()).getTime();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    };

    const preventZoom = (event) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    };

    const preventHistoryNavigation = (event) => {
      event.preventDefault();
      history.pushState(null, '', window.location.pathname);
      return false;
    };

    const preventSwipeNavigation = (event) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    };

    onMounted(async () => {
      isIOS.value = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

      if (isIOS.value) {
        const root = document.documentElement;
        safeAreaBottom.value = parseInt(
          getComputedStyle(root).getPropertyValue('--sat') || '0',
          10
        );
      }

      await authStore.checkAuth();
      if (authStore.isAuthenticated) {
        themeStore.initializeThemes(authStore.themes);
        trackUserRetention();
        setupRoomListener(authStore.user.uid);
        setupSurveyCheck(authStore.user.uid);
      }

      document.addEventListener('click', handleButtonClick);
      document.addEventListener('touchend', handleTouchEnd, { passive: false });
      document.addEventListener('touchmove', preventZoom, { passive: false });
      window.addEventListener('popstate', preventHistoryNavigation);
      document.addEventListener('touchstart', preventSwipeNavigation, { passive: false });
      
      history.pushState(null, '', window.location.pathname);
      checkOverlayCondition();
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', handleButtonClick);
      document.removeEventListener('touchend', handleTouchEnd);
      document.removeEventListener('touchmove', preventZoom);
      window.removeEventListener('popstate', preventHistoryNavigation);
      document.removeEventListener('touchstart', preventSwipeNavigation);
      
      if (roomsListener) {
        off(dbRef(rtdb, 'rooms'), 'value', roomsListener);
        roomsListener = null;
      }

      cleanupSurveyListeners();
    });

    watch(
      () => authStore.user,
      (newUser, oldUser) => {
        if (newUser?.uid !== oldUser?.uid) {
          if (newUser) {
            setupRoomListener(newUser.uid);
            setupSurveyCheck(newUser.uid);
          } else {
            if (roomsListener) {
              off(dbRef(rtdb, 'rooms'), 'value', roomsListener);
              roomsListener = null;
            }
            cleanupSurveyListeners();
            themeStore.initializeThemes(null);
            const publicPages = ['Landing', 'Login', 'FindID', 'Signup', 'SeoScore'];
            if (!publicPages.includes(route.name)) {
              router.replace({ name: 'Login' });
            }
          }
        }
      }
    );

    const sizeBlockStyle = computed(() => {
      if (isIOS.value) {
        return {
          height: `calc(100vh - ${safeAreaBottom.value}px)`,
        };
      }
      return {};
    });

    return {
      themeStore,
      authStore,
      t,
      errorCharPng,
      isLandingPage,
      isIOS,
      sizeBlockStyle,
      showOverlay,
      handleOverlayClose,
      showSurveyModal
    };
  },
};
</script>

<style>
* {
  touch-action: manipulation;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: NanumSquare;
  border: none;
  outline: none;
  scrollbar-width: auto !important;
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;    
  user-select: none;  
  font-weight: 600;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
}

:root {
  --shadow-1: none;
  --shadow-2: none;
  --highlight-color: #cc2e0e;
  --highlight-disable: #42221c;
  --text-color: #e7e7e7;
  --green: #00ff00;
  --red-warning: #ff0000;
  --red-button: #AE002A;
  --bg-opacity: #0e0e0ebb;
  --1-13: #151515;
  --2-13: #1e1e1e;
  --3-13: #252525;
  --4-13: #303030;
  --5-13: #424242;
  --6-13: #505050;
  --7-13: #767676;
  --1-12: #151515;
  --2-12: #1e1e1e;
  --3-12: #252525;
  --4-12: #303030;
  --5-12: #424242;
  --6-12: #505050;
  --7-12: #767676;
  --1-11: #151515;
  --2-11: #1e1e1e;
  --3-11: #252525;
  --4-11: #303030;
  --5-11: #424242;
  --6-11: #505050;
  --7-11: #767676;
  --1-10: #151515;
  --2-10: #1e1e1e;
  --3-10: #252525;
  --4-10: #303030;
  --5-10: #424242;
  --6-10: #505050;
  --7-10: #767676;
  --1-9: #151515;
  --2-9: #1e1e1e;
  --3-9: #252525;
  --4-9: #303030;
  --5-9: #424242;
  --6-9: #505050;
  --7-9: #767676;
  --1-8: #151515;
  --2-8: #1e1e1e;
  --3-8: #252525;
  --4-8: #303030;
  --5-8: #424242;
  --6-8: #505050;
  --7-8: #767676;
  --1-7: #151515;
  --2-7: #1e1e1e;
  --3-7: #252525;
  --4-7: #303030;
  --5-7: #424242;
  --6-7: #505050;
  --7-7: #767676;
  --dark: #000;
  --sat: env(safe-area-inset-bottom);
}

.light-mode {
  --shadow-1: 4px 4px 8px rgba(0, 0, 0, 0.05);
  --shadow-2: 4px 0px 8px rgba(0, 0, 0, 0.03);
  --highlight-color: #db592b;
  --highlight-disable: #d29883;
  --text-color: #000;
  --green: #68b468;
  --red-warning: #ff0000;
  --red-button: #AE002A;
  --bg-opacity: #c8c8c8bb;
  --1-13: #f9f9f9;
  --2-13: #f9f9f9;
  --3-13: #f9f9f9;
  --4-13: #f9f9f9;
  --5-13: #f9f9f9;
  --6-13: #f9f9f9;
  --7-13: #f9f9f9;
  --1-12: #e7e7e7;
  --2-12: #e1e1e1;
  --3-12: #e1e1e1;
  --4-12: #e1e1e1;
  --5-12: #e1e1e1;
  --6-12: #e1e1e1;
  --7-12: #e1e1e1;
  --1-11: #c8c8c8;
  --2-11: #c8c8c8;
  --3-11: #c8c8c8;
  --4-11: #c8c8c8;
  --5-11: #c8c8c8;
  --6-11: #c8c8c8;
  --7-11: #c8c8c8;
  --1-10: #aaaaaa;
  --2-10: #aaaaaa;
  --3-10: #aaaaaa;
  --4-10: #aaaaaa;
  --5-10: #aaaaaa;
  --6-10: #aaaaaa;
  --7-10: #aaaaaa;
  --1-9: #999999;
  --2-9: #999999;
  --3-9: #999999;
  --4-9: #999999;
  --5-9: #999999;
  --6-9: #999999;
  --7-9: #999999;
  --1-8: #858585;
  --2-8: #858585;
  --3-8: #858585;
  --4-8: #858585;
  --5-8: #858585;
  --6-8: #858585;
  --7-8: #858585;
  --1-7: #767676;
  --2-7: #767676;
  --3-7: #767676;
  --4-7: #767676;
  --5-7: #767676;
  --6-7: #767676;
  --7-7: #767676;
  --dark: #c8c8c8;
}

body {
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: var(--2-13);
  color: var(--text-color);
  -ms-overflow-style: none;
}

button {
  display: flex;
  width: 80%;
  height: 46px;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px 20px;
  border-radius: 23px;
  background-color: var(--4-11);
  color: var(--text-color);
  border: none;
  opacity: 0.9;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.1s ease;
}

@media (hover: hover) and (pointer: fine) {
  button:hover {
    opacity: 1;
    font-weight: 600;
  }
}

button:active {
  transform: scale(0.98);
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 25px;
}

h6 {
  font-size: 10px;
}

input {
  width: 80%;
  height: 45px;
  background-color: var(--4-13);
  border-radius: 25px;
  margin-bottom: 15px;
  padding: 0 20px;
  border: 1px solid var(--4-12);
  outline: none;
  transition: border-right-color 0s ease;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 600;
}

input:focus {
  border: 1px solid var(--highlight-color);
  background-color: var(--4-12);
}

.ios-safe-area-bottom {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: env(safe-area-inset-bottom);
  background-color: var(--2-13);
  z-index: 1000;
}

/* 오버레이 스타일 추가 */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--bg-opacity);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(1px);
  z-index: 200000000;
}

.overlay-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: var(--2-13);
  padding: 20px;
  border-radius: 30px;
  text-align: center;
  color: var(--text-color);
  height: 220px;
  margin: 20px;
}

.overlay-content h4 {
  color: var(--text-color);
}

.overlay-content p {
  margin-bottom: 0px;
  font-size: 15px;
  color: var(--text-color);
  opacity: 0.7;
  line-height: 1.5;
}

.overlay-content button {
  margin: 0;
  width: 95%;
  padding: 10px 20px;
  background-color: var(--highlight-color);
  color: #f9f9f9;
}

select {
  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
}

select::-ms-expand {
    display: none;
}

@media (max-width: 600px), (max-height: 500px) {
  body {
    background-color: var(--1-10);
  }

  button:hover {
    opacity: 0.9;
    font-weight: 600;
  }

  @media (hover: hover) and (pointer: fine) {
    button:hover {
    opacity: 0.9;
    font-weight: 600;
  }
}

  .landing-page .size-block {
    display: none !important;
  }

  .size-block {
    display: none;
  }

  .size-block img {
    width: 100px;
    height: auto;
    margin-bottom: 20px;
  }

  .size-block i {
    font-size: 60px;
    margin-bottom: 20px;
    margin-left: 20px;
  }

  .img-cont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  body {
  width: 100vw;
  width: 100dvw;
  height: 100vh;
  height: 100dvh;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: var(--2-13);
  color: var(--text-color);
  -ms-overflow-style: none;
}

.overlay-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: var(--2-13);
  padding: 20px;
  border-radius: 30px;
  text-align: center;
  color: var(--text-color);
  height: 220px;
  margin: 20px;
  word-break: keep-all;
}

.overlay-content h3 {
  color: var(--text-color);
  font-weight: 800;
}

.overlay-content p {
  margin-bottom: 0px;
  font-size: 12px;
  color: var(--text-color);
  opacity: 0.7;
  line-height: 1.5;
  font-weight: 600;
}

.overlay-content button {
  width: 100%;
}

#app {
  width: 100vw;
  width: 100dvw;
  height: 100vh;
  height: 100dvh;
  overflow-y: hidden;
  overflow-x: hidden;
}
}
</style>