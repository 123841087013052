import { getAnalytics, logEvent } from "firebase/analytics";
import { db } from '@/firebase';  // Firebase db import 추가
import {
  doc,
  setDoc,
  serverTimestamp,
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
  deleteDoc,
  // updateDoc
} from 'firebase/firestore';

import { rtdb } from '@/firebase';
import { ref, get } from "firebase/database";

const analytics = getAnalytics();


//사용자가 각 콘텐츠를 어디서부터 어디까지 시청했는지 
//사용자가 언제 시청을 멈추는지, 뒤로 돌려보는지, 앞으로 돌려보는지
//어떤 기기를 통해 시청하는지 
//언제 일시정지를 하는지
//얼마나 있어야 다시 돌아와서 콘텐츠를 시청하는지 등
//사용자 속성

//각 날짜별로 페이지뷰 대비 클릭이 많이 일어난 콘텐츠
//클릭해서 들어간 후 스크롤을 끝까지 내린 콘텐츠
//10초(유효한 시간 범위) 이상 콘텐츠에 머무르며 글을 읽은 사람이 많은 콘텐츠

// 핵심 이벤트 타입 정의


// GA4 구성 가이드
/*
사용자 닉네임이나 유아이디 엮기 사용자 트랙킹 사용자 구별 맞춤속성 디플로이
필요한 GA4 맞춤 정의 매개변수:
1. room_id (텍스트)
2. bpm (숫자)
3. time_signature (텍스트)
4. participant_count (숫자)
5. duration_sec (숫자)
6. is_playing (부울)
7. current_beat (숫자)
8. total_beats (숫자)
9. measure (숫자)

GA4 설정 단계:
1. GA4 관리 > 이벤트 > 맞춤 정의 > 맞춤 매개변수 생성
2. 각 매개변수의 범위와 유형 설정
3. 이벤트별로 필요한 매개변수 연결
4. 필요한 보고서 설정

rooms: {
    [roomId]: {
      // 자주 업데이트되는 데이터
      currentBeat: number,      // 매 비트마다 업데이트 (bpm에 따라 빈번)
      isPlaying: boolean,       // 재생 상태 변경시
      startTime: number,        // 재생 시작시 한번
      totalBeats: number,       // 매 비트마다 증가 -----------였는데 메트로놈 로직수정으로 없음
      
      // 중간 빈도 업데이트
      participants: {           // 참가자 입장/퇴장/ping시
        [userId]: {
          nickname: string,
          joinedAt: timestamp,
          lastPing: timestamp,  // 주기적 업데이트 (예: 30초)
          isHost: boolean
        }
      },
      
      // 낮은 빈도 업데이트
      bpm: number,             // 설정 변경시
      timeSignature: string,   // 설정 변경시
      soundType: string,       // 설정 변경시
      prebeat: number,         // 설정 변경시
      volume: number,          // 설정 변경시
      name: string,            // 생성시
      createdAt: timestamp,    // 생성시
      createdBy: string,       // 생성시
      isPublic: boolean,       // 생성시
      isPersonal: boolean,     // 생성시
      hasPassword: boolean,    // 생성시
      password: string,        // 생성시
      
      // 선택적 데이터
      currentAlbum: string,    // 앨범 선택시
      lastStoppedMeasure: number // 정지시
    }
  }
*/



const CORE_PARAMETERS = {
  // GA4 기본 제공 파라미터 활용
  STANDARD: {
    TIMESTAMP: 'timestamp',
    PLATFORM: 'platform',
    USER_ID: 'user',
  },
  // 맞춤 정의 파라미터 (가장 중요한 지표만 선별)
  CUSTOM: {
    ROOM_ID: 'room_id',           // 방 식별
    BPM: 'bpm',                   // 템포
    MEASURE: 'measure',           // 마디
    TOTAL_BEATS: 'total_beats',   // 총 비트수
    DURATION: 'duration_sec',     // 지속시간
    PARTICIPANT_COUNT: 'participant_count', // 참여자 수
    IS_PLAYING: 'is_playing',     // 재생상태
    SETTING_TYPE: 'setting_type', // 설정 타입
    SETTING_VALUE: 'setting_value', // 설정 값
    ALBUM_ID: 'album_id',         // 앨범 ID
    ERROR_CODE: 'error_code',     // 에러 코드
    ERROR_MESSAGE: 'error_message', // 에러 메시지
    CURRENT_BEAT: 'current_beat',  // 현재 비트
    TIME_SIGNATURE: 'time_signature', // 박자
    IS_PERSONAL: 'is_personal', // 개인룸
    ROOM_NAME: 'room_name', // 방이름
    PLAY_COUNT: 'play_count', // 재생 시작 횟수
    PART_COUNT: 'part_count' // 파트 바꾼 횟수
  }
};


export const CORE_EVENTS = {
  ROOM: {
    CREATE: 'room_created',
    JOIN: 'room_joined',
    LEAVE: 'room_left',
    DELETE: 'room_deleted',
    DURATION: 'room_duration'
  },
  MUSIC: {
    SELECT: 'music_selected'
  },
  METRONOME: {
    START: 'metronome_started',
    STOP: 'metronome_stopped',
  },
  SETTINGS: {
    CHANGE: 'settings_changed',
    BPM_CHANGE: 'bpm_changed'
  },
  GROUP: {
    SESSION: 'group_session',
    METRONOME_START: 'group_metronome_start',
    METRONOME_STOP: 'group_metronome_stop'
  },
  USER: {
    LOGIN: 'login',
    RETENTION: 'user_retention',
    GOOGLE_LOGIN_SUCCESS: 'google_login_success',
    GOOGLE_LOGIN_ERROR: 'google_login_error'
  },
  PROGRESS: {
    BEAT: 'beat_progress',
    MEASURE: 'measure_change'
  },
  ALBUM: {
    CLICK: 'album_click',
    VIEW_DURATION: 'album_view_duration'
  }
};
// 중복 이벤트 방지를 위한 쓰로틀링 관리
const throttleMap = new Map();
const THROTTLE_DURATION = 1000; // 1초

const shouldThrottle = (key) => {
  const now = Date.now();
  const lastTime = throttleMap.get(key);

  if (lastTime && now - lastTime < THROTTLE_DURATION) {
    return true;
  }

  throttleMap.set(key, now);
  return false;
};

// 한국 시간 포맷팅을 위한 유틸리티 함수 추가
const formatKoreanTimestamp = () => {
  const now = new Date();
  const koreaTime = new Date(now.getTime() + (9 * 60 * 60 * 1000)); // UTC+9

  const year = koreaTime.getUTCFullYear();
  const month = String(koreaTime.getUTCMonth() + 1).padStart(2, '0');
  const day = String(koreaTime.getUTCDate()).padStart(2, '0');
  const hours = String(koreaTime.getUTCHours()).padStart(2, '0');
  const minutes = String(koreaTime.getUTCMinutes()).padStart(2, '0');
  const seconds = String(koreaTime.getUTCSeconds()).padStart(2, '0');

  return {
    timestamp: koreaTime.getTime(),
    formatted: `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`,
    date: `${year}-${month}-${day}`,
    time: `${hours}:${minutes}:${seconds}`
  };
};

// 기본 트래킹 함수
const trackEvent = (eventName, eventParams = {}) => {
  try {
    // 유효성 검사 추가
    if (!eventName) {
      throw new Error('Event name is required');
    }

    if (typeof eventParams !== 'object') {
      throw new Error('Event parameters must be an object');
    }

    const koreanTime = formatKoreanTimestamp();

    const commonParams = {
      [CORE_PARAMETERS.STANDARD.TIMESTAMP]: koreanTime.timestamp,
      korean_time: koreanTime.formatted,
      korean_date: koreanTime.date,
      korean_time_only: koreanTime.time,
      [CORE_PARAMETERS.STANDARD.PLATFORM]: 'web'
    };

    // analytics 객체 유효성 검사
    if (!analytics) {
      throw new Error('Analytics instance not initialized');
    }

    // 이벤트 파라미터 정제
    const cleanParams = Object.entries(eventParams).reduce((acc, [key, value]) => {
      // null, undefined 값 필터링
      if (value != null) {
        acc[key] = value;
      }
      return acc;
    }, {});

    // GA4 이벤트 로깅
    logEvent(analytics, eventName, {
      ...commonParams,
      ...cleanParams,
      error_status: 'success'
    });

    if (process.env.NODE_ENV === 'development') {
      console.log('Event tracked successfully:', {
        eventName,
        params: { ...commonParams, ...cleanParams }
      });
    }

  } catch (error) {
    // 에러 로깅
    try {
      const errorEventName = 'tracking_error';
      const errorParams = {
        error_type: error.name,
        error_message: error.message,
        original_event_name: eventName,
        error_status: 'failed',
        [CORE_PARAMETERS.STANDARD.TIMESTAMP]: Date.now(),
        [CORE_PARAMETERS.STANDARD.PLATFORM]: 'web'
      };

      logEvent(analytics, errorEventName, errorParams);

      console.error('Event tracking failed:', {
        eventName,
        originalParams: eventParams,
        error: {
          name: error.name,
          message: error.message,
          stack: error.stack
        }
      });
    } catch (secondaryError) {
      // 에러 로깅마저 실패한 경우
      console.error('Critical: Failed to log tracking error:', {
        originalError: error,
        secondaryError
      });
    }
  }
};

const extractAlbumInfo = (albumData) => {
  if (!albumData) return { id: '', name: '', artist: '' };

  // 반응형 객체나 일반 객체 모두 처리
  const data = albumData.__v_raw || albumData;

  return {
    id: data.id || '',
    artist: data.artist || '',
    name: data.title || ''  // 앨범 이름은 title 필드에 있음
  };
};
let Uid = ''

export const setUid = (uid) => {
  if (uid) {
    Uid = uid;
    console.log('Analytics UID set:', uid);
  } else {
    Uid = '';
    console.log('Analytics UID cleared');
  }
};

// 사용자 이벤트
export const trackLogin = (userId) => {
  Uid = userId;
  const currentTime = new Date().toISOString();
  const timestamp = serverTimestamp();
  const userClickRef = doc(db, `users/${Uid}/logs/${currentTime}_loggedIn`);
  setDoc(userClickRef, {
    timestamp: timestamp,
  });
  trackEvent(CORE_EVENTS.USER.LOGIN, {
    [CORE_PARAMETERS.STANDARD.USER_ID]: userId
  });
};

export const trackUserRetention = async () => {
  const lastRetentionDate = localStorage.getItem('lastRetentionDate');
  const today = new Date().toDateString();
  const currentTime = new Date().toISOString();
  const timestamp = serverTimestamp();

  try {
    // 먼저 loggedIn 로그 기록
    const loggedInRef = doc(db, `users/${Uid}/logs/${currentTime}_loggedIn`);
    await setDoc(loggedInRef, {
      timestamp: timestamp
    });

    // 최근 1초 이내의 exit 로그 찾기
    const fiveSecondsAgo = Date.now() - 1000
    const logsRef = collection(db, `users/${Uid}/logs`);
    const q = query(
      logsRef,
      where('for_judge_refresh', '>=', fiveSecondsAgo),
      orderBy('for_judge_refresh', 'desc'),
      limit(1)
    );

    const querySnapshot = await getDocs(q);
    const exitLog = querySnapshot.docs.find(doc => doc.id.includes('_page_exit'));

    if (exitLog) {
      const exitTime = exitLog.data().for_judge_refresh;
      const timeDiff = Date.now() - exitTime;
      if (timeDiff <= 1000) {
        // 2초 이내면 exit 로그와 loggedIn 로그를 삭제하고 refresh 로그 생성
        await deleteDoc(doc(db, `users/${Uid}/logs/${exitLog.id}`));
        await deleteDoc(loggedInRef);

        const refreshLogRef = doc(db, `users/${Uid}/logs/${currentTime}_page_refresh`);
        await setDoc(refreshLogRef, {
          ...exitLog.data(),
          timestamp: timestamp
        });

        // GA4 refresh 이벤트 기록
        trackEvent('page_refresh', {
          [CORE_PARAMETERS.STANDARD.USER_ID]: Uid,
          [CORE_PARAMETERS.CUSTOM.ALBUM_ID]: exitLog.data().albumId || null,
          duration: exitLog.data().duration || 0,
          page_name: exitLog.data().pageName || null,
          navigation_time: exitLog.data().navigation_time || 0
        });
      }
    }

    // 일일 retention 체크
    if (lastRetentionDate !== today && lastRetentionDate) {
      const daysDifference = Math.floor(
        (new Date(today) - new Date(lastRetentionDate)) / (1000 * 60 * 60 * 24)
      );

      trackEvent(CORE_EVENTS.USER.RETENTION, {
        [CORE_PARAMETERS.STANDARD.USER_ID]: Uid ?? 'null',
        [CORE_PARAMETERS.CUSTOM.DURATION]: daysDifference
      });
    }
    localStorage.setItem('lastRetentionDate', today);

  } catch (error) {
    console.error('Error in trackUserRetention:', error);
  }
};

// 방 관련 이벤트
let RoomId = ''
export const trackCreateRoom = (roomId, data) => {
  RoomId = roomId;
  trackEvent(CORE_EVENTS.ROOM.CREATE, {
    [CORE_PARAMETERS.CUSTOM.ROOM_ID]: roomId,
    [CORE_PARAMETERS.CUSTOM.IS_PERSONAL]: data.isPersonal,
    [CORE_PARAMETERS.STANDARD.USER_ID]: Uid
  });
  const currentTime = new Date().toISOString();
  const timestamp = serverTimestamp();
  const createRoomRef = doc(db, `users/${Uid}/logs/${currentTime}_createroom`);
  setDoc(createRoomRef, {
    roomId: roomId,
    timestamp: timestamp
  });
};

export const trackJoinRoom = async (roomId, data) => {
  RoomId = roomId;
  try {
    // RTDB에서 방 정보 가져오기
    const roomRef = ref(rtdb, `rooms/${roomId}`);
    const snapshot = await get(roomRef);

    if (!snapshot.exists()) {
      console.warn(`Room ${roomId} does not exist in RTDB`);
      return;
    }

    const roomData = snapshot.val();
    const createdBy = roomData.createdBy;

    // 방 생성자와 현재 유저가 다른 경우에만 로그 기록
    if (createdBy !== Uid) {
      const joinTime = Date.now();

      // GA4 이벤트 로깅
      trackEvent(CORE_EVENTS.ROOM.JOIN, {
        [CORE_PARAMETERS.CUSTOM.ROOM_ID]: roomId,
        [CORE_PARAMETERS.CUSTOM.PARTICIPANT_COUNT]: data.participantCount ?? 1,
        [CORE_PARAMETERS.STANDARD.USER_ID]: Uid
      });

      localStorage.setItem(`room_join_time_${roomId}`, joinTime);

      const currentTime = new Date().toISOString();
      const timestamp = serverTimestamp();

      const joinRoomRef = doc(db, `users/${Uid}/logs/${currentTime}_joinroom`);
      await setDoc(joinRoomRef, {
        roomId: roomId,
        timestamp: timestamp,
        createdBy: createdBy // 방 생성자 정보도 함께 저장
      });
    }
  } catch (error) {
    console.error('Error in trackJoinRoom:', error);

    // 에러 발생 시 GA4에 에러 이벤트 로깅
    trackEvent('join_room_error', {
      [CORE_PARAMETERS.STANDARD.USER_ID]: Uid,
      [CORE_PARAMETERS.CUSTOM.ROOM_ID]: roomId,
      error_message: error.message
    });
  }
};

export const trackLeaveRoom = (roomId, data = {}) => {
  // 기본값을 포함한 안전한 데이터 구조
  const safeData = {

    participantCount: 1,
    ...data
  };
  let playCount = parseInt(localStorage.getItem(`play_${roomId}`)) || 0;
  let Count = parseInt(localStorage.getItem(`part_change`))
  Count -= 1;

  trackEvent(CORE_EVENTS.ROOM.LEAVE, {
    [CORE_PARAMETERS.STANDARD.USER_ID]: Uid,
    [CORE_PARAMETERS.CUSTOM.ROOM_ID]: roomId ?? "null",
    [CORE_PARAMETERS.CUSTOM.PLAY_COUNT]: playCount,
    [CORE_PARAMETERS.CUSTOM.PARTICIPANT_COUNT]: safeData.participantCount,
    [CORE_PARAMETERS.CUSTOM.PART_COUNT]: Count
  });
  trackRoomDuration(roomId);
  localStorage.removeItem(`play_${roomId}`);
  localStorage.removeItem(`part_change`)
};

export const trackPartChange = async (roomId, albumId, partName = '선택된 파트 없음') => {
  try {
    let Count = parseInt(localStorage.getItem(`part_change`)) || 0;
    Count += 1;
    localStorage.setItem(`part_change`, Count);

    // albumId가 객체로 전달되는 경우, 필요한 정보만 추출
    const albumInfo = extractAlbumInfo(albumId);

    trackEvent('part_change', {
      [CORE_PARAMETERS.CUSTOM.ROOM_ID]: roomId,
      [CORE_PARAMETERS.CUSTOM.ALBUM_ID]: albumInfo.id,
      album_name: albumInfo.name,
      artist_name: albumInfo.artist,
      part_name: partName
    });
  } catch (error) {
    console.error('Error in trackPartChange:', error);
    // 에러 발생 시 기본 트래킹
    const albumInfo = extractAlbumInfo(albumId);
    trackEvent('part_change', {
      [CORE_PARAMETERS.CUSTOM.ROOM_ID]: roomId,
      [CORE_PARAMETERS.CUSTOM.ALBUM_ID]: albumInfo.id,
      part_name: partName,
      artist_name: albumInfo.artist,
      error_status: 'failed'
    });
  }
};

// 재생 관련 이벤트
export const trackPlay = (roomId, data = {}) => {


  const koreanTime = formatKoreanTimestamp();

  const safeData = {
    bpm: 120,
    timeSignature: '4/4',
    soundType: 'sine',
    totalParticipants: 1,
    isAlbumPlaying: false,
    measure: 1,
    ...data
  };
  let Count = parseInt(localStorage.getItem(`album_click_count`)) || 0;

  // 시작 시간, 횟수 저장
  const playTime = Date.now();
  localStorage.setItem(`play_start_time_${roomId}`, playTime);
  let playCount = parseInt(localStorage.getItem(`play_${roomId}`)) || 0;
  // 1을 증가
  playCount += 1;
  // 증가된 값을 다시 저장
  localStorage.setItem(`play_${roomId}`, playCount);

  const currentTime = new Date().toISOString();
  const timestamp = serverTimestamp();


  const userClickRef = doc(db, `users/${Uid}/logs/${currentTime}_playAlbum`);
  setDoc(userClickRef, {
    timestamp: timestamp,
    albumid: album_Id
  });

  trackEvent(CORE_EVENTS.METRONOME.START, {
    [CORE_PARAMETERS.STANDARD.USER_ID]: Uid,
    [CORE_PARAMETERS.CUSTOM.ROOM_ID]: roomId,
    [CORE_PARAMETERS.CUSTOM.BPM]: safeData.bpm,
    [CORE_PARAMETERS.CUSTOM.PARTICIPANT_COUNT]: safeData.totalParticipants,
    [CORE_PARAMETERS.CUSTOM.MEASURE]: safeData.measure,
    album_click_count: Count,
    start_time: koreanTime.formatted
  });
};

export const trackStop = (roomId, data = {}) => {
  const koreanTime = formatKoreanTimestamp();

  // 시작 시간 가져와서 duration 계산
  const playStartTime = localStorage.getItem(`play_start_time_${roomId}`);
  let duration = 0;

  if (playStartTime) {
    duration = Math.floor((Date.now() - parseInt(playStartTime)) / 1000);
    // 계산 후 시작 시간 삭제
    localStorage.removeItem(`play_start_time_${roomId}`);
  }

  const eventData = {
    [CORE_PARAMETERS.STANDARD.USER_ID]: Uid,
    [CORE_PARAMETERS.CUSTOM.ROOM_ID]: roomId,
    [CORE_PARAMETERS.CUSTOM.DURATION]: duration,
    [CORE_PARAMETERS.CUSTOM.MEASURE]: data.measure,
    stop_time: koreanTime.formatted
  };

  console.log('Tracking stop event:', {
    roomId,
    startTime: new Date(parseInt(playStartTime)).toLocaleString(),
    stopTime: koreanTime.formatted,
    durationSeconds: duration,
    ...eventData
  });
  if (album_Id) {

    const currentTime = new Date().toISOString();
    const timestamp = serverTimestamp();


    const userClickRef = doc(db, `users/${Uid}/logs/${currentTime}_stopAlbum`);
    setDoc(userClickRef, {
      timestamp: timestamp,
      albumid: album_Id,
      duration: duration
    });
  }
  trackEvent(CORE_EVENTS.METRONOME.STOP, eventData);

};

// 앨범 관련 이벤트

// 앨범 변경 이벤트 최적화
export const trackAlbum = (roomId, data) => {
  const changeKey = `${roomId}_${data.newAlbumId}`;

  if (shouldThrottle(changeKey)) {
    return;
  }

  if (data.previousAlbumId !== data.newAlbumId) {
    trackEvent('music_selected', {
      [CORE_PARAMETERS.STANDARD.USER_ID]: Uid,
      [CORE_PARAMETERS.CUSTOM.ROOM_ID]: roomId,
      [CORE_PARAMETERS.CUSTOM.ALBUM_ID]: data.newAlbumId,
      previous_album_id: data.previousAlbumId
    });
  }
};


//총비트수 에러많음
// export const trackBeatProgress = (roomId, data) => {
//   trackEvent(CORE_EVENTS.PROGRESS.BEAT, {
//     [CORE_PARAMETERS.STANDARD.USER_ID]: Uid,
//     [CORE_PARAMETERS.CUSTOM.ROOM_ID]: roomId,
//     [CORE_PARAMETERS.CUSTOM.CURRENT_BEAT]: data.currentBeat,
//     [CORE_PARAMETERS.CUSTOM.BPM]: data.bpm
//   });
// };
// 지속시간 측정
export const trackRoomDuration = (roomId) => {
  const joinTime = localStorage.getItem(`room_join_time_${roomId}`);
  if (joinTime) {
    const duration = Math.floor((Date.now() - parseInt(joinTime)) / 1000);
    trackEvent(CORE_EVENTS.ROOM.DURATION, {
      [CORE_PARAMETERS.STANDARD.USER_ID]: Uid,
      [CORE_PARAMETERS.CUSTOM.ROOM_ID]: roomId,
      [CORE_PARAMETERS.CUSTOM.DURATION]: duration
    });
    localStorage.removeItem(`room_join_time_${roomId}`);
  }
};

// 그룹 세션 이벤트
export const trackGroupSession = (roomId, data) => {
  trackEvent(CORE_EVENTS.GROUP.SESSION, {
    [CORE_PARAMETERS.STANDARD.USER_ID]: Uid,
    [CORE_PARAMETERS.CUSTOM.ROOM_ID]: roomId,
    [CORE_PARAMETERS.CUSTOM.PARTICIPANT_COUNT]: data.currentCount,
    [CORE_PARAMETERS.CUSTOM.IS_PLAYING]: data.isPlaying
  });
};
let album_Id = 0;

export const trackAlbumFetch = async (albumId) => {
  // 이전 앨범 ID와 같거나 albumId가 0인 경우 기록하지 않음
  if (albumId === album_Id || albumId === 0) return;

  // 새로운 앨범 ID 저장
  album_Id = albumId;

  // uid가 없으면 함수 종료
  if (!Uid) return;

  const currentTime = new Date().toISOString();
  const timestamp = serverTimestamp();

  try {
    // 1. users/{uid}/clicks/{albumId} 에 timestamp 기록
    const userClickRef = doc(db, `users/${Uid}/logs/${currentTime}_fetchAlbum`);
    await setDoc(userClickRef, {
      timestamp: timestamp,
      albumId: albumId
    });

    // 2. tracks/{albumId}/clicks/{uid} 에 timestamp 기록
    const albumClickRef = doc(db, `tracks/${albumId}/logs/${currentTime}_fetchAlbum`);
    await setDoc(albumClickRef, {
      timestamp: timestamp,
      uid: Uid,
      albumId: albumId,
      roomId: RoomId
    });

    // GA4 이벤트 로깅 - uid가 있을 때만 실행
    let Count = parseInt(localStorage.getItem(`album_click_count`)) || 0;
    Count += 1;
    localStorage.setItem(`album_click_count`, Count);

    if (Count > 9) {
      let purchaseCount = parseInt(localStorage.getItem(`purchase_count`)) || 0;
      purchaseCount += 1;
      localStorage.setItem(`purchase_count`, purchaseCount);
      // const userPurchaseRef = doc(db, `users/${Uid}/logs/${currentTime}_10 clicks`);
      // await setDoc(userPurchaseRef, {
      //   purchaseCount: purchaseCount,
      //   timestamp: timestamp
      // });
      // trackEvent('purchase', {
      //   [CORE_PARAMETERS.STANDARD.USER_ID]: Uid,
      //   album_click_count: Count
      // });
      // localStorage.removeItem(`album_click_count`);
    }

    trackEvent('album_click', {
      [CORE_PARAMETERS.STANDARD.USER_ID]: Uid,
      [CORE_PARAMETERS.CUSTOM.ALBUM_ID]: albumId
    });

    localStorage.setItem(`album_view_start_${albumId}`, Date.now());

  } catch (error) {
    console.error('Error tracking album click:', error);
    // 에러 발생 시에도 uid가 있는 경우만 로깅
    trackEvent('album_click_error', {
      [CORE_PARAMETERS.STANDARD.USER_ID]: Uid,
      [CORE_PARAMETERS.CUSTOM.ALBUM_ID]: albumId,
      error_message: error.message
    });
  }
};


// 페이지 로드시 beforeunload 이벤트 설정
// Vue 컴포넌트에서 사용할 설정 함수
let navigationStartTime = 0;

export const setupPageTracking = () => {
  if (typeof window === 'undefined') return;
  navigationStartTime = Date.now();
  window.addEventListener('beforeunload', () => {
  });
};

// trackExit 함수는 이전과 동일하게 유지하되 isRefreshing, isUnloading 관련 코드만 제거
export const trackExit = async (pageName) => {
  const startTime = localStorage.getItem(`album_view_start_${album_Id}`);
  const timestamp = serverTimestamp();
  const currentTime = new Date().toISOString();
  let duration = 0;

  //앨범 클릭수를 위한 로깅 
  let Count = parseInt(localStorage.getItem(`album_click_count`)) || 0;

  if (startTime) {
    duration = Math.floor((Date.now() - parseInt(startTime)) / 1000);
  }

  if (!Uid) return;

  try {
    const logDocId = `${currentTime}_page_exit`;
    const logRef = doc(db, `users/${Uid}/logs/${logDocId}`);

    const logData = {
      albumId: album_Id,
      timestamp: timestamp,
      pageName: pageName,
      duration: duration,
      album_clicks: Count,
      for_judge_refresh: Date.now()
    };

    await setDoc(logRef, logData);

    trackEvent('page_exit', {
      [CORE_PARAMETERS.STANDARD.USER_ID]: Uid,
      [CORE_PARAMETERS.CUSTOM.ALBUM_ID]: album_Id,
      [CORE_PARAMETERS.CUSTOM.DURATION]: duration,
      page_name: pageName,
      navigation_time: Date.now() - navigationStartTime
    });

    if (startTime) {
      trackEvent('album_view_duration', {
        [CORE_PARAMETERS.STANDARD.USER_ID]: Uid,
        [CORE_PARAMETERS.CUSTOM.ALBUM_ID]: album_Id,
        [CORE_PARAMETERS.CUSTOM.DURATION]: duration,
        event_type: 'exit'
      });
    }
  } catch (error) {
    console.error('Error logging page exit:', error);
    trackEvent('page_exit_error', {
      [CORE_PARAMETERS.STANDARD.USER_ID]: Uid,
      error_message: error.message
    });
  } finally {
    if (startTime) {
      localStorage.removeItem(`album_view_start_${album_Id}`);
    }
  }
};

export const trackAlbumViewDuration = () => {
  const startTime = localStorage.getItem(`album_view_start_${album_Id}`);
  if (startTime) {
    const duration = Math.floor((Date.now() - parseInt(startTime)) / 1000);

    // GA4 이벤트 로깅
    trackEvent('album_view_duration', {
      [CORE_PARAMETERS.STANDARD.USER_ID]: Uid,
      [CORE_PARAMETERS.CUSTOM.ALBUM_ID]: album_Id,
      [CORE_PARAMETERS.CUSTOM.DURATION]: duration
    });

    // Firestore에 기록 (uid가 있는 경우에만)
    if (Uid) {
      try {
        const timestamp = serverTimestamp();
        const currentTime = new Date().toISOString();

        // 1. users/{uid}/logs에 view duration 기록
        const userLogRef = doc(db, `users/${Uid}/logs/${currentTime}_albumExit`);
        setDoc(userLogRef, {
          albumId: album_Id,
          duration: duration,
          timestamp: timestamp,
        });

        // 2. tracks/{albumId}/logs에 view duration 기록
        // const albumClickRef = doc(db, `tracks/${album_Id}/logs/${currentTime}_fetchAlbum`);
        // updateDoc(albumClickRef, {
        //   duration: duration
        // });


      } catch (error) {
        console.error('Error logging album exit to Firestore:', error);
        // Firestore 기록에 실패하더라도 GA4 이벤트는 이미 기록됨
      }
    }

    localStorage.removeItem(`album_view_start_${album_Id}`);
  }
};
// 로그인 관련 추가 함수
export const trackGoogleLoginSuccess = (userId) => {
  Uid = userId
  trackEvent(CORE_EVENTS.USER.GOOGLE_LOGIN_SUCCESS, {
    [CORE_PARAMETERS.STANDARD.USER_ID]: userId
  });
};

export const trackGoogleLoginError = (error) => {
  trackEvent(CORE_EVENTS.USER.GOOGLE_LOGIN_ERROR, {
    error_code: error.code,
    error_message: error.message
  });
};

// 그룹 메트로놈 관련 추가 함수
export const trackGroupMetronomeStart = (roomId, data) => {

  trackEvent(CORE_EVENTS.GROUP.METRONOME_START, {
    [CORE_PARAMETERS.STANDARD.USER_ID]: Uid,
    [CORE_PARAMETERS.CUSTOM.ROOM_ID]: roomId,
    [CORE_PARAMETERS.CUSTOM.PARTICIPANT_COUNT]: data.participantCount,

  });
};

// 방 관련 추가 함수
export const trackRemoveRoom = (roomId) => {
  let playCount = parseInt(localStorage.getItem(`play_${roomId}`)) || 0;
  let Count = parseInt(localStorage.getItem(`part_change`))
  Count -= 1;
  trackEvent(CORE_EVENTS.ROOM.DELETE, {
    [CORE_PARAMETERS.CUSTOM.ROOM_ID]: roomId,
    [CORE_PARAMETERS.CUSTOM.PLAY_COUNT]: playCount,
    [CORE_PARAMETERS.CUSTOM.PART_COUNT]: Count,
  });
  trackRoomDuration(roomId);
  localStorage.removeItem(`play_${roomId}`);
  localStorage.removeItem(`part_change`)
};

// BPM 변경 추적
export const trackBPMChange = (roomId, data) => {
  const changeKey = `${roomId}_bpm_${data.newBpm}`;

  if (shouldThrottle(changeKey)) {
    return;
  }

  trackEvent('bpm_changed', {
    [CORE_PARAMETERS.CUSTOM.ROOM_ID]: roomId,
    [CORE_PARAMETERS.CUSTOM.SETTING_VALUE]: data.newBpm,
    previous_value: data.previousBpm
  });
};

// 설정 변경 추적
// export const trackRoomSettingChange = (roomId, data) => {
//   trackEvent(CORE_EVENTS.SETTINGS.CHANGE, {
//     [CORE_PARAMETERS.CUSTOM.ROOM_ID]: roomId,
//     [CORE_PARAMETERS.CUSTOM.SETTING_TYPE]: data.settingType,
//     [CORE_PARAMETERS.CUSTOM.SETTING_VALUE]: data.newValue,
//     [CORE_PARAMETERS.CUSTOM.IS_PLAYING]: data.isPlaying
//   });
// };

// 마디 변경 추적
export const trackMeasureChange = (roomId, data) => {
  trackEvent(CORE_EVENTS.PROGRESS.MEASURE, {
    [CORE_PARAMETERS.CUSTOM.ROOM_ID]: roomId,
    [CORE_PARAMETERS.CUSTOM.MEASURE]: data.currentMeasure,
    [CORE_PARAMETERS.CUSTOM.TIME_SIGNATURE]: data.timeSignature,
    [CORE_PARAMETERS.CUSTOM.TOTAL_BEATS]: data.totalBeats,
    [CORE_PARAMETERS.CUSTOM.CURRENT_BEAT]: data.currentBeat
  });
};

export const SEOtrackAlbum = (albumId) => {
  const koreanTime = formatKoreanTimestamp();
  const currentTime = new Date().toISOString();
  const timestamp = serverTimestamp();

  const playTime = Date.now();
  localStorage.setItem(`album_click_time`, playTime);
  const albumClickRef = doc(db, `tracks/${albumId}/logs/${currentTime}_sampleFetch`);
  setDoc(albumClickRef, {
    timestamp: timestamp
  });

  trackEvent('SEO_album_click', {
    [CORE_PARAMETERS.CUSTOM.ALBUM_ID]: albumId,
    start_time: koreanTime.formatted
  });
};

export const SEOtrackPlay = (data = {}) => {
  const koreanTime = formatKoreanTimestamp();

  const safeData = {
    measure: 1,
    ...data
  };
  const albumInfo = extractAlbumInfo(data.albumId);

  // 시작 시간 저장
  const playTime = Date.now();
  localStorage.setItem(`play_start_time`, playTime);

  trackEvent('SEO_album_start', {
    [CORE_PARAMETERS.CUSTOM.ALBUM_ID]: albumInfo.id,
    [CORE_PARAMETERS.CUSTOM.MEASURE]: safeData.measure,
    start_time: koreanTime.formatted
  });
};

export const SEOtrackStop = (data = {}) => {
  const koreanTime = formatKoreanTimestamp();

  // 시작 시간 가져와서 duration 계산
  const playStartTime = localStorage.getItem(`play_start_time`);
  let duration = 0;

  if (playStartTime) {
    duration = Math.floor((Date.now() - parseInt(playStartTime)) / 1000);
    // 계산 후 시작 시간 삭제
    localStorage.removeItem(`play_start_time`);
  }
  const albumInfo = extractAlbumInfo(data.albumId);

  const eventData = {
    [CORE_PARAMETERS.CUSTOM.DURATION]: duration,
    [CORE_PARAMETERS.CUSTOM.MEASURE]: data.measure,
    [CORE_PARAMETERS.CUSTOM.ALBUM_ID]: albumInfo.id,
    stop_time: koreanTime.formatted
  };

  console.log('Tracking stop event:', {
    startTime: new Date(parseInt(playStartTime)).toLocaleString(),
    stopTime: koreanTime.formatted,
    durationSeconds: duration,
    ...eventData
  });

  trackEvent("SEO_album_stop", eventData);
};

export const SEOtrackComplete = (albumId) => {
  const albumInfo = extractAlbumInfo(albumId);
  trackEvent('SEO_complete', {
    [CORE_PARAMETERS.CUSTOM.ALBUM_ID]: albumInfo.id,
  });
};

export const SEOpartChange = (albumId) => {
  let Count = parseInt(localStorage.getItem(`part_change`)) || 0;
  Count += 1;
  localStorage.setItem(`part_change`, Count);
  const albumInfo = extractAlbumInfo(albumId);
  trackEvent('SEO_part_change', {
    [CORE_PARAMETERS.CUSTOM.ALBUM_ID]: albumInfo.id,

  });
  localStorage.removeItem(`part_change`);
};


export const SEOtrackGoToLogin = (albumId) => {
  const currentTime = new Date().toISOString();
  const timestamp = serverTimestamp();
  const albumClickRef = doc(db, `tracks/${albumId}/logs/${currentTime}_sampleTologin`);
  setDoc(albumClickRef, {
    timestamp: timestamp
  });

  // 시작 시간 가져와서 duration 계산
  const playStartTime = localStorage.getItem(`play_start_time`);
  let duration = 0;
  const albumInfo = extractAlbumInfo(albumId);

  if (playStartTime) {
    duration = Math.floor((Date.now() - parseInt(playStartTime)) / 1000);
    // 계산 후 시작 시간 삭제
    localStorage.removeItem(`play_start_time`);
  }
  let Count = parseInt(localStorage.getItem(`part_change`)) || 0;
  trackEvent('SEO_to_login', {
    [CORE_PARAMETERS.CUSTOM.PART_COUNT]: Count,
    [CORE_PARAMETERS.CUSTOM.ALBUM_ID]: albumInfo.id,
    [CORE_PARAMETERS.CUSTOM.DURATION]: duration,
  });
  localStorage.removeItem(`part_change}`)
};

export const SEOtrackNoLogin = (albumId) => {

  // 시작 시간 가져와서 duration 계산
  const playStartTime = localStorage.getItem(`play_start_time`);
  let duration = 0;
  const albumInfo = extractAlbumInfo(albumId);

  if (playStartTime) {
    duration = Math.floor((Date.now() - parseInt(playStartTime)) / 1000);
    // 계산 후 시작 시간 삭제
    localStorage.removeItem(`play_start_time`);
  }
  let Count = parseInt(localStorage.getItem(`part_change`)) || 0;
  Count -= 1;
  trackEvent('SEO_NO_login', {
    [CORE_PARAMETERS.CUSTOM.DURATION]: duration,
    [CORE_PARAMETERS.CUSTOM.PART_COUNT]: Count,
    [CORE_PARAMETERS.CUSTOM.ALBUM_ID]: albumInfo.id,
  });
  localStorage.removeItem(`part_change}`)
};

export const SEOtrackGoBack = (albumId) => {

  // 시작 시간 가져와서 duration 계산
  const playStartTime = localStorage.getItem(`play_start_time`);
  let duration = 0;

  if (playStartTime) {
    duration = Math.floor((Date.now() - parseInt(playStartTime)) / 1000);
    // 계산 후 시작 시간 삭제
    localStorage.removeItem(`play_start_time`);
  }
  let Count = parseInt(localStorage.getItem(`part_change`)) || 0;
  Count -= 1;

  const albumInfo = extractAlbumInfo(albumId);

  trackEvent('SEO_album_remove', {
    [CORE_PARAMETERS.CUSTOM.DURATION]: duration,
    [CORE_PARAMETERS.CUSTOM.ALBUM_ID]: albumInfo.id,
    [CORE_PARAMETERS.CUSTOM.PART_COUNT]: Count,
  });
  localStorage.removeItem(`part_change}`)
};
