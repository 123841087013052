<template>
    <div v-if="show" class="overlay">
      <div class="overlay-content survey-modal">
        <div class="scroll-wrap">
          <h3>피드백을 들려주세요!</h3>
          <p>서비스 개선을 위한 짧은 설문에 참여해주세요.<br>
            저희 개발자들은 더 좋은 서비스 개발을 위해 노력하고 있습니다!</p>
    
          <div class="title">FEAT에서의 경험에 어느 정도로 만족하시나요?</div>
          <div class="survey-questions">
            <div class="rating">
              <button 
                v-for="n in 5" 
                :key="n"
                :class="{ 'selected': satisfaction === n }"
                @click="satisfaction = n"
              >
                {{ n }}
              </button>
            </div>
          </div>
    
          <div class="title">FEAT의 좋은 점과 불편한 점을 알려 주세요.</div>
          <div class="survey-questions">
            <table class="feature-table">
              <thead>
                <tr>
                  <th>기능</th>
                  <th>좋음</th>
                  <th>불편함</th>
                  <th>사용하지 않음</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(feature, index) in defaultFeatures" :key="'default-' + index">
                  <td>{{ feature.name }}</td>
                  <td>
                    <label class="radio-label">
                      <input 
                        type="radio" 
                        :name="'feature-' + index"
                        value="good"
                        v-model="feature.rating"
                      >
                      <span class="custom-radio good"></span>
                    </label>
                  </td>
                  <td>
                    <label class="radio-label">
                      <input 
                        type="radio" 
                        :name="'feature-' + index"
                        value="bad"
                        v-model="feature.rating"
                      >
                      <span class="custom-radio bad"></span>
                    </label>
                  </td>
                  <td>
                    <label class="radio-label">
                      <input 
                        type="radio" 
                        :name="'feature-' + index"
                        value="unused"
                        v-model="feature.rating"
                      >
                      <span class="custom-radio unused"></span>
                    </label>
                  </td>
                </tr>

                <tr v-for="(feature, index) in customFeatures" :key="'custom-' + index">
                  <td>
                    <input 
                      type="text" 
                      v-model="feature.name" 
                      placeholder="기능을 입력하세요"
                      class="custom-feature-input"
                    >
                  </td>
                  <td>
                    <label class="radio-label">
                      <input 
                        type="radio" 
                        :name="'custom-feature-' + index"
                        value="good"
                        v-model="feature.rating"
                      >
                      <span class="custom-radio good"></span>
                    </label>
                  </td>
                  <td>
                    <label class="radio-label">
                      <input 
                        type="radio" 
                        :name="'custom-feature-' + index"
                        value="bad"
                        v-model="feature.rating"
                      >
                      <span class="custom-radio bad"></span>
                    </label>
                  </td>
                  <td>
                    <label class="radio-label">
                      <input 
                        type="radio" 
                        :name="'custom-feature-' + index"
                        value="unused"
                        v-model="feature.rating"
                      >
                      <span class="custom-radio unused"></span>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
            
            <button @click="addCustomFeature" class="add-feature-btn">
              + 직접 입력 추가
            </button>
          </div>

          <div class="textarea">
            <textarea 
              v-model="feedback"
              placeholder="구체적으로 개선되기를 바라는 사항이 있으십니까?"
              rows="3"
            ></textarea>
          </div>
    
          <div class="survey-buttons">
            <button 
              @click="submitSurvey" 
              :disabled="!isSubmitEnabled || isSubmitting"
              class="submit-button"
            >
              {{ isSubmitting ? '제출 중...' : '제출하기' }}
            </button>
            <button @click="closeSurvey" class="secondary">다음에 하기</button>
          </div>

          <div v-if="error" class="error-message">
            {{ error }}
          </div>
        </div>
      </div>
    </div>
</template>
  
<script>
import { ref, computed } from 'vue';
import { db } from '@/firebase';
import { 
  doc, 
  setDoc, 
  updateDoc, 
  arrayUnion, 
  increment, 
  serverTimestamp,
  getDoc 
} from 'firebase/firestore';

export default {
  name: 'SurveyModal',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    userId: {
      type: String,
      required: true
    }
  },
  emits: ['close'],
  
  setup(props, { emit }) {
    const satisfaction = ref(0);
    const feedback = ref('');
    const isSubmitting = ref(false);
    const error = ref('');
    
    const defaultFeatures = ref([
      { name: '무료 및 인터랙티브 악보', rating: '' },
      { name: '합주 기능', rating: '' },
      { name: '개인 연습 기능', rating: '' },
      { name: '메트로놈 기능', rating: '' },
      { name: '공지사항 및 곡 신청, 버그 제보 기능', rating: '' }
    ]);
    const customFeatures = ref([]);

    const addCustomFeature = () => {
      customFeatures.value.push({ name: '', rating: '' });
    };

    const isSubmitEnabled = computed(() => {
      if (!satisfaction.value) return false;
      
      const defaultFeaturesValid = defaultFeatures.value.every(feature => feature.rating !== '');
      const customFeaturesValid = customFeatures.value.every(feature => 
        !feature.name.trim() || (feature.name.trim() && feature.rating !== '')
      );
      
      return defaultFeaturesValid && customFeaturesValid;
    });

    const submitSurvey = async () => {
      try {
        isSubmitting.value = true;
        error.value = '';

        const surveyRef = doc(db, 'survey', 'survey1 (Ver.0.1.6 OB)');
        const userSurveyRef = doc(db, 'users', props.userId, 'survey', 'survey1');

        // 현재 문서 가져오기
        const surveyDoc = await getDoc(surveyRef);
        
        // 업데이트할 필드들 준비
        const updates = {};

        if (!surveyDoc.exists()) {
          // 문서가 없을 경우에만 초기 구조 생성
          const initialSurveyData = {
            features: {
              custom_features: []
            },
            satisfaction: {
              rating_1: 0,
              rating_2: 0,
              rating_3: 0,
              rating_4: 0,
              rating_5: 0
            },
            totalResponses: 0,
            totalCancels: 0,
            userComments: []
          };

          // defaultFeatures에 대한 초기 구조 생성
          defaultFeatures.value.forEach(feature => {
            const featureName = feature.name.replace(/\s+/g, '_');
            initialSurveyData.features[featureName] = {
              good: 0,
              bad: 0,
              unused: 0
            };
          });

          await setDoc(surveyRef, initialSurveyData);
        }

        // 기본 기능 업데이트
        defaultFeatures.value.forEach(feature => {
          const featurePath = `features.${feature.name.replace(/\s+/g, '_')}.${feature.rating}`;
          updates[featurePath] = increment(1);
        });

        // 만족도 점수 업데이트
        updates[`satisfaction.rating_${satisfaction.value}`] = increment(1);
        updates.totalResponses = increment(1);

        // 모든 업데이트를 한번에 적용
        await updateDoc(surveyRef, updates);

        // 커스텀 기능 업데이트 (값이 있는 경우만)
        const validCustomFeatures = customFeatures.value.filter(f => f.name.trim());
        if (validCustomFeatures.length > 0) {
          await updateDoc(surveyRef, {
            'features.custom_features': arrayUnion(...validCustomFeatures.map(f => ({
              name: f.name,
              rating: f.rating,
              timestamp: new Date().toISOString(),
              userId: props.userId  // 사용자 식별을 위해 userId 추가
            })))
          });
        }

        // 피드백 업데이트 (값이 있는 경우만)
        if (feedback.value.trim()) {
          await updateDoc(surveyRef, {
            userComments: arrayUnion({
              comment: feedback.value,
              timestamp: new Date().toISOString(),
              userId: props.userId  // 사용자 식별을 위해 userId 추가
            })
          });
        }

        // 사용자 설문 완료 상태 저장
        await setDoc(userSurveyRef, {
          completed: true,
          type: 'submitted',
          timestamp: serverTimestamp(),
          satisfaction: satisfaction.value,
          features: defaultFeatures.value.reduce((acc, feature) => {
            acc[feature.name.replace(/\s+/g, '_')] = feature.rating;
            return acc;
          }, {}),
          customFeatures: validCustomFeatures,
          feedback: feedback.value.trim() || null
        });

        emit('close');
      } catch (err) {
        console.error('Survey submission error:', err);
        error.value = '설문 제출 중 오류가 발생했습니다. 다시 시도해 주세요.';
      } finally {
        isSubmitting.value = false;
      }
    };

    const closeSurvey = async () => {
  try {
    const surveyRef = doc(db, 'survey', 'survey1 (Ver.0.1.6 OB)');
    const userSurveyRef = doc(db, 'users', props.userId, 'survey', 'survey1');
    
    await updateDoc(surveyRef, {
      totalCancels: increment(1)
    });
    
    await setDoc(userSurveyRef, {
      completed: true,
      type: 'cancelled',
      timestamp: serverTimestamp()
    });
  } catch (err) {
    console.error('Survey close error:', err);
    error.value = '설문을 닫는 중 오류가 발생했습니다.';
  } finally {
    emit('close');
  }
};

    return {
      satisfaction,
      feedback,
      defaultFeatures,
      customFeatures,
      isSubmitEnabled,
      isSubmitting,
      error,
      submitSurvey,
      closeSurvey,
      addCustomFeature
    };
  }
};
</script>
  
<style scoped>
.error-message {
  color: var(--red-warning);
  font-size: 14px;
  margin-top: 15px;
  text-align: center;
}

.submit-button {
  width: 100%;
  position: relative;
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.feature-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 15px;
  word-break: keep-all;
}

.feature-table th {
  padding: 12px;
  text-align: left;
  font-size: 14px;
  font-weight: normal;
  color: var(--text-color);
  opacity: 0.7;
  white-space: nowrap;
}

.feature-table td {
  padding: 12px;
  text-align: left;
  font-size: 14px;
}

.feature-table th {
  font-weight: normal;
  color: var(--text-color);
  opacity: 0.7;
}

.feature-table td:not(:first-child) {
  text-align: center;
}

.custom-feature-input {
  width: 100%;
  padding: 8px;
  margin: 0 !important;
  border: 1px solid var(--5-11);
  background-color: var(--4-13);
  color: var(--text-color);
  font-size: 14px;
}

.custom-feature-input:focus {
  outline: none;
  border-color: var(--highlight-color);
}

.add-feature-btn {
  width: 100%;
  background-color: var(--4-13);
  color: var(--text-color);
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.add-feature-btn:hover {
  background-color: var(--highlight-color);
}

.radio-label {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.radio-label input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-radio {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid var(--5-11);
  border-radius: 50%;
}

.radio-label input[type="radio"]:checked + .custom-radio.good {
  background-color: var(--green);
  border-color: var(--green);
}

.radio-label input[type="radio"]:checked + .custom-radio.bad {
  background-color: var(--red-warning);
  border-color: var(--red-warning);
}

.radio-label input[type="radio"]:checked + .custom-radio.unused {
  background-color: var(--highlight-color);
  border-color: var(--highlight-color);
}

.survey-modal {
  height: auto;
  max-width: 500px;
  max-height: 90%;
  width: 100%;
  margin: 20%;
  width: 90%;
  padding: 30px 0;
  overflow-y: hidden;
}

.scroll-wrap {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    padding: 0 30px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.survey-modal h3 {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 800;
}

.survey-modal p {
  font-size: 14px;
  opacity: 0.7;
  margin-bottom: 30px;
}

.survey-questions {
  width: 100%;
  margin: 0;
  background-color: var(--3-12);
  padding: 10px;
  border-radius: 25px;
  margin-bottom: 25px;
}

.title {
  width: 100%;
  text-align: start;
  font-size: 14px;
  margin-bottom: 15px;
  margin-right: 15px;
}

.rating {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 0 !important;
}

.rating button {
  width: 40px;
  height: 40px;
  margin: 0 !important;
  margin-bottom: 0 !important;
  border-radius: 20px;
  background-color: var(--4-13);
  color: var(--text-color);
}

.rating button.selected {
  background-color: var(--highlight-color);
  color: white;
}

.textarea {
  width: 100%;
  padding: 0px;
  padding-right: 5px;
  padding-bottom: 2px;
  border-radius: 15px;
  background-color: var(--4-13);
  border: 1px solid var(--4-12);
  color: var(--text-color);
  font-size: 14px;
  margin-bottom: 20px;
}

.textarea textarea {
    background-color: transparent;
    padding: 12px;
    min-height: 100px;
    width: 100%;
    height: 100%;
    color: var(--text-color);
    resize: vertical;
}

.survey-buttons {
    width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}

.survey-buttons button {
  width: 100%;
  margin: 0 !important;
}

.survey-buttons .secondary {
  background-color: transparent;
  height: 20px;
  width: fit-content !important;
  font-size: 13px;
  color: var(--highlight-color);
}

@media (max-width: 600px) {
  .survey-modal {
    margin: 15px;
    padding: 15px;
  }
}
</style>