// store/seoscore.js
import { defineStore } from 'pinia'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '@/firebase'
import { markRaw } from 'vue'
import { SEOtrackAlbum } from '@/analytics'

export const organizationInfo = markRaw({
    "@type": "Organization",
    "name": "KAMO",
    "alternateName": "카모",
    "description": "음악인들을 위한 툴을 제작합니다.",
    "url": process.env.VUE_APP_SERVICE_URL || "https://feat.band",
    "logo": `${process.env.VUE_APP_SERVICE_URL || "https://feat.band"}/kamo_logo.png`,
    "sameAs": [
        "https://www.youtube.com/@kamo_official_kr"
    ]
})

export const useSeoScoreStore = defineStore('seoScore', {
    state: () => ({
        currentScore: null,
        error: null,
        loading: false,
        cachedMetadata: null
    }),

    getters: {
        scoreMetadata: (state) => {
            if (!state.currentScore) return null
            if (state.cachedMetadata) return state.cachedMetadata

            const metadata = markRaw({
                title: state.currentScore.title || '',
                artist: state.currentScore.artist || '',
                imageUrl: state.currentScore.imageUrl || '',
                genre: state.currentScore.genre ? markRaw({ ...state.currentScore.genre }) : {},
                id: state.currentScore.id || '',
                updatedAt: state.currentScore.updatedAt || null
            })

            state.cachedMetadata = metadata
            return metadata
        },

        isLoading: (state) => state.loading,
        hasError: (state) => !!state.error,

        structuredData: (state) => {
            if (!state.currentScore) return null

            const currentUrl = process.env.VUE_APP_SERVICE_URL || "https://feat.band"
            const score = state.currentScore

            const structured = markRaw({
                '@context': 'https://schema.org',
                '@type': 'MusicComposition',
                'name': score.title || '',
                'composer': {
                    '@type': 'Person',
                    'name': score.artist || ''
                },
                'publisher': organizationInfo,
                'inLanguage': 'ko',
                'isAccessibleForFree': true,
                'image': score.imageUrl || `${currentUrl}/public/assets/meta_image_main.jpg`,

                'musicArrangement': {
                    '@type': 'SheetMusic',
                    'encodingFormat': 'text/html',
                    'url': `${currentUrl}/scores/${score.id}`
                },

                'workExample': {
                    '@type': 'AudioObject',
                    'name': `${score.title} - 연주`,
                    'encodingFormat': 'audio/midi',
                    'interactionType': 'InteractAction'
                },

                'educationalUse': [
                    'Practice',
                    'Performance'
                ],
                'learningResourceType': 'Interactive Score',
                'interactivityType': 'Active'
            })

            if (score.updatedAt) {
                structured.dateModified = new Date(score.updatedAt.seconds * 1000).toISOString()
            }

            if (score.id) {
                structured.mainEntityOfPage = {
                    '@type': 'WebPage',
                    '@id': `${currentUrl}/scores/${score.id}`
                }
            }

            if (score.genre && Object.keys(score.genre).length > 0) {
                structured.genre = Object.keys(score.genre)
                    .filter(key => score.genre[key])
                    .join(', ')
            }

            return structured
        }
    },

    actions: {
        async fetchScoreData(scoreId) {
            this.loading = true
            this.error = null
            this.cachedMetadata = null

            try {
                const cached = sessionStorage.getItem(`score-${scoreId}`)
                if (cached) {
                    this.currentScore = markRaw(JSON.parse(cached))
                    return
                }

                const docRef = doc(db, 'tracks', scoreId)
                const docSnap = await getDoc(docRef)

                if (docSnap.exists()) {
                    const scoreData = markRaw({
                        id: docSnap.id,
                        ...docSnap.data()
                    })

                    this.currentScore = scoreData
                    sessionStorage.setItem(`score-${scoreId}`, JSON.stringify(scoreData))
                    SEOtrackAlbum(scoreId)
                } else {
                    throw new Error('악보를 찾을 수 없습니다.')
                }
            } catch (error) {
                this.error = error.message
                console.error('Error fetching score:', error)
                throw error
            } finally {
                this.loading = false
            }
        },

        clearScoreData() {
            this.currentScore = null
            this.error = null
            this.loading = false
            this.cachedMetadata = null
        },

        clearCache() {
            this.cachedMetadata = null
        },

        updateMetadata(metadata) {
            this.cachedMetadata = markRaw(metadata)
        }
    }
})
